import saveAs from "file-saver";
import { applySnapshot, types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { getSiteAddress } from "modules/common/services/communication/http";
import { apiUrls } from "modules/common/services/communication/urls";
import { base64ToBlob, convertTextToImage, printImage } from "modules/common/services/files";
import { texts } from "modules/common/texts";
import {
  WorkTypeDictionary,
  initialState as emptyWorkTypes,
} from "modules/dictionaries/work-types/models/work-type-dictionary";
import { buildAccessTree, makeColumns } from "modules/session/access/models/access-store";
import {
  OrderPostStore,
  OrderPostType,
  OutsourcerSnapshotType,
  OutsourcerType,
  initialState as emptyOutsourcer,
  initialPostStoreState,
} from "./outsourcer";
import { Outsourcer, OutsourcerList, initialState as emptyList } from "./outsourcer-list";

import {
  AgentsCategoriesDictionary,
  initialState as categoriesInite,
} from "modules/dictionaries/agents-categories/models/agents-categories-dictionary";
import { OrderDictionary, initialState as ordersInite } from "modules/orders-manage/models/order-dictionary";

export const LegalTableName = "OutsourcersListLegal";
export const PhysicalTableName = "OutsourcersListPhysical";
export const OutsourcerTableName = OutsourcerList.name;

export const OutsourcersStore = types
  .compose(
    Transport,
    Notificator,
    types.model({
      workTypes: WorkTypeDictionary,
      categories: AgentsCategoriesDictionary,
      orderDictionary: OrderDictionary,
      legalEntities: OutsourcerList.named(LegalTableName),
      physicalEntities: OutsourcerList.named(PhysicalTableName),
      list: OutsourcerList,
      sendEmailsList: OrderPostStore,
      requestsList: OutsourcerList,
      details: Outsourcer,
      requestDetails: Outsourcer,
      authorization: types.map(types.string),
      loading: types.boolean,
    })
  )
  .actions((self) => ({
    load: flow(function* () {
      try {
        const data: OutsourcerSnapshotType[] = yield self.transport.get<OutsourcerSnapshotType[]>(
          apiUrls.outsourcers.list()
        );

        applySnapshot(self.list.outsourcers, data);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    loadSendEmails: flow(function* (orderId?: string) {
      try {
        const data: OrderPostType[] = yield self.transport.post<OrderPostType[]>(
          apiUrls.outsourcers.getRequestEmailSend(),
          { orderId }
        );

        applySnapshot(self.sendEmailsList, data);
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    readMailPost: flow(function* () {
      try {
        yield self.transport.get<any>(apiUrls.outsourcers.readMailPost());

        self.notify.success("Данные успешно синхронизированы!");
        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    loadRequests: flow(function* () {
      try {
        const data: OutsourcerSnapshotType[] = yield self.transport.get<OutsourcerSnapshotType[]>(
          apiUrls.outsourcers.requestsList()
        );

        applySnapshot(self.requestsList.outsourcers, data);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    loadNoRules: flow(function* () {
      try {
        const data: OutsourcerSnapshotType[] = yield self.transport.get<OutsourcerSnapshotType[]>(
          apiUrls.suppliers.listNoRules()
        );
        applySnapshot(self.list.outsourcers, data);

        return true;
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
  }))
  .actions((self) => ({
    // loadLists: flow(function* () {
    //     try {

    //         const data: OutsourcerSnapshotType[] = yield self.transport.get<OutsourcerSnapshotType[]>(
    //             apiUrls.outsourcers.list()
    //         );

    //         const legal = data.filter((o) => isLegalEntity(o));
    //         const physical = data.filter((o) => !isLegalEntity(o));

    //         applySnapshot(self.legalEntities.outsourcers, legal);
    //         applySnapshot(self.physicalEntities.outsourcers, physical);

    //         return true;
    //     } catch (er) {
    //         self.notify.error(er);
    //         return false;
    //     }
    // }),
    saveSendEmail: flow(function* (
      selectedAgents: SelectItem[],
      selectedOrder: null | SelectItem,
      uploadedFiles: FileBase[],
      subject: string,
      message: string
    ) {
      try {
        const order = selectedOrder ? { id: selectedOrder.id, name: selectedOrder.label } : null;
        const documentIds = uploadedFiles.map((file) => file.fileId);
        const agentsIds = selectedAgents.map((a) => a.id);
        const agents = self.list.data.filter((a) => a.isAgent && agentsIds.includes(a.id));
        const recipients = agents.map((a) => ({ id: a.id, name: a.name, email: a.email }));
        const body = { order, documentIds, subject, message, recipients, isRequest: true };

        const snapshot = yield self.transport.post<any>(apiUrls.outsourcers.requestEmailSave(), body);
        if (snapshot) {
          self.notify.success(texts.messages.saved);
        }
        yield self.loadSendEmails(order?.id);
        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
    saveSendEmailOrder: flow(function* (
      messageId: null | string,
      selectedOrder: null | SelectItem,
      changeDate: boolean
    ) {
      if (!selectedOrder) {
        self.notify.error("Выберите договор!");
        return false;
      }
      try {
        const order = selectedOrder ? { id: selectedOrder.id, name: selectedOrder.label } : null;

        const body = { order, messageId, changeDate };

        const snapshot = yield self.transport.post<any>(apiUrls.outsourcers.requestEmailSave(), body);
        if (snapshot) {
          self.notify.success(texts.messages.saved);
        }
        yield self.loadSendEmails();
        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
    init: flow(function* (outsourcerId: string) {
      yield self.details.load(outsourcerId);
      self.loading = true;
      yield self.workTypes.load();
      yield self.categories.load();
      yield self.load();
      yield self.loadRequests();
      self.loading = false;
    }),
    printCredentials: flow(function* (user: OutsourcerType) {
      const credentials = yield user.getCredentials();

      if (credentials) {
        const dataURL = convertTextToImage([
          `Логин : ${credentials.login}`,
          `Пароль: ${credentials.password}`,
          `Ссылка: ${getSiteAddress()}`,
        ]);

        printImage(dataURL);
      }
    }),
    printDocCredentials: flow(function* (user: OutsourcerType, order: SelectItem | null) {
      if (self.details.isNewlyCreated) {
        return;
      }
      try {
        if (user) {
          if (order) {
            const file: FileDescription = yield self.transport.post<any>(apiUrls.clients.print(user.id), {
              orderId: order.id,
            });

            const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
            saveAs(blob, file.name);
            return true;
          } else {
            self.notify.error("Необходимо указать договор с заказчиком!");
            return false;
          }
        } else {
          self.notify.error("Необходимо указать раздел!");
          return false;
        }
      } catch (er) {
        self.notify.error(er);
        return false;
      }
    }),
    confirmRequest: flow(function* (id: string) {
      try {
        const snapshot = yield self.transport.post<any>(apiUrls.outsourcers.confirmRequest(id), {});
        if (snapshot) {
          self.notify.success(texts.messages.saved);
        }

        return true;
      } catch (er) {
        self.notify.error(er);

        return false;
      }
    }),
  }))
  .views((self) => ({
    get accessTree() {
      return buildAccessTree(self.authorization.entries());
    },
  }))
  .views((self) => ({
    get accessColumns() {
      return makeColumns(self.accessTree, 4);
    },
  }))
  .named("OutsourcersStore");

export const initialState = (): typeof OutsourcersStore.SnapshotType => ({
  legalEntities: emptyList(LegalTableName),
  physicalEntities: emptyList(PhysicalTableName),
  list: emptyList(OutsourcerTableName),
  sendEmailsList: initialPostStoreState(),
  requestsList: emptyList(OutsourcerTableName),
  requestDetails: emptyOutsourcer(true),
  orderDictionary: ordersInite(),
  details: emptyOutsourcer(true),
  workTypes: emptyWorkTypes(),
  authorization: {},
  loading: false,
  categories: categoriesInite(),
});

export type OutsourcersStoreType = typeof OutsourcersStore.Type;
