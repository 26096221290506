import React from "react";
import ReactDOM from "react-dom";
import { IpdTypeDictionaryType } from "modules/dictionaries/ipd-types/models/ipd-type-dictionary";
import {
    OrderSummaryType,
    OrderSummary,
    initialState as emptyOrder,
    fields,
    OrderIpdType,
} from "modules/orders-manage/models/order";
import { IpdFactory, IpdsDetails } from "./IpdDetails";
import { observer } from "mobx-react";
import styles from "./Ipd.module.scss";
import { Field, FieldProps, Form, Formik } from "formik";
import { IpdRow } from "./IpdRow";
import { SaveOrderIpd, mapIpd } from "modules/orders-manage/models/order-ipd";
import { Location } from "history";
import { IpdHeader } from "./IpdHeader";

import { DragDropContext, DropResult, Droppable, Draggable } from "react-beautiful-dnd";
import { move } from "modules/common/services/array";
import portal from "../portal";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { ProjectAssigner } from "modules/common/components/orders/ProjectAssigner";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { getEnv } from "mobx-state-tree";

import { PlanrButton } from "modules/common/components/planr/button/Button";
import { cloneDeep, difference, findLast } from "lodash";
import { Buttons } from "modules/common/components/form";
import { Classes, Dialog, Spinner } from "@blueprintjs/core";
import {
    DefaultSelectedOption,
    filterItemPredicate,
    renderSingleOption,
    SimpleSelect,
} from "modules/common/services/form/select";
import { texts } from "modules/common/texts";
import DenyNavigation from "modules/common/components/routing/DenyNavigation";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { eat } from "modules/common/services/typescript";
import { routes } from "modules/common/routes";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { OrdersStoreType } from "modules/orders-manage/models/orders-store";
import { buildPatch } from "modules/common/services/form/values";
import { OrderStageRowType } from "modules/orders-manage/models/order-stages-store";

class Ipd extends React.Component<IpdProps, IpdState> {
    private fieldProps: FieldProps | null = null;

    constructor(props: IpdProps) {
        super(props);

        this.state = {
            copyVisible: false,
            detailsVisible: false,
            activeRow: null,
            proxyLoading: false,
            preparingPrint: false,
            showPrint: false,
            content: {
                id: "",
                label: "",
            },
            stages: null,
        };
    }
    async componentDidMount() {
        const { orderId, store } = this.props;
        if (orderId !== EMPTY_OBJECT_ID) {
            await store.details.loadStages(orderId);
        }
    }

    render() {
        const {
            name,
            readOnly,
            baseUrl,
            factory,
            ipdTypes,
            orderDictionary,
            store,
            onPrint,
            canClientEdit,
            schema,
            orderId,
        } = this.props;
        const { activeRow, detailsVisible, copyVisible, proxyLoading, showPrint, content, preparingPrint } = this.state;

        const initial = getFormValues(store.details);

        const units: Select[] = [];
        store.details.objects.forEach((object) => {
            object.content.forEach((content) => {
                units.push({ id: content.guid, label: content.name });
            });
        });

        return (
            <Formik
                initialValues={initial}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let patch: any;

                    if (store.details.isNewlyCreated) {
                        patch = cloneDeep(values);
                    } else {
                        patch = buildPatch(initial, values);
                    }

                    const orderSnapshot = await store.saveOrderIpds(patch);

                    setSubmitting(false);

                    if (!!orderSnapshot) {
                        store.details.refreshIpds(orderSnapshot);
                        resetForm();
                        // this.onOrderSaved();
                    }

                    if (orderSnapshot === null) {
                        resetForm();
                    }
                }}
                onReset={eat}
            >
                {(formProps) => {
                    if (orderId !== EMPTY_OBJECT_ID) {
                        return (
                            <Form autoComplete="off">
                                <DenyNavigation
                                    when={formProps.dirty}
                                    message="На странице остались несохраненные изменения"
                                    skip={this.navigationPromt}
                                />
                                <div className={styles.ipd}>
                                    <div className="planr-tools">
                                        <PlanrButton
                                            type="graybtn"
                                            icon="general-print"
                                            round={true}
                                            title={texts.print}
                                            onClick={this.toggleShowPrint}
                                        />
                                    </div>
                                    <Dialog
                                        title={`Формирование тома ИРД`}
                                        isOpen={showPrint}
                                        onClose={this.toggleShowPrint}
                                        backdropClassName="standard"
                                        canEscapeKeyClose={false}
                                        canOutsideClickClose={false}
                                        className={`${styles.FilesDialog} figma-dialog`}
                                    >
                                        <div className={`${Classes.DIALOG_BODY} `}>
                                            <div className={styles.label}>Выберите раздел</div>
                                            <div>
                                                <MentorSelectRenderer
                                                    item={content}
                                                    items={units}
                                                    onSelect={(item: SelectItem) => {
                                                        this.setState({
                                                            content: {
                                                                id: item.id,
                                                                label: item.label,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={Classes.DIALOG_FOOTER}>
                                            <Buttons
                                                left={
                                                    <div style={{ display: "flex" }}>
                                                        <PlanrButton
                                                            size="small"
                                                            type="greenish"
                                                            style={{ width: "152px" }}
                                                            onClick={async () => {
                                                                this.setState({ preparingPrint: true });
                                                                const result = await onPrint(content.id);
                                                                if (result) {
                                                                    this.setState({ preparingPrint: false });
                                                                    this.toggleShowPrint();
                                                                } else {
                                                                    this.setState({ preparingPrint: false });
                                                                }
                                                            }}
                                                        >
                                                            {!preparingPrint ? (
                                                                "Сформировать"
                                                            ) : (
                                                                <Spinner intent="primary" size={16} />
                                                            )}
                                                        </PlanrButton>
                                                        <PlanrButton
                                                            size="small"
                                                            type="graybtn"
                                                            onClick={this.toggleShowPrint}
                                                        >
                                                            Отмена
                                                        </PlanrButton>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </Dialog>

                                    <Field name={name}>
                                        {(fieldProps: FieldProps) => {
                                            this.fieldProps = fieldProps;
                                            const ipds: SaveOrderIpd[] = fieldProps.field.value;

                                            return (
                                                <div className={styles.contents}>
                                                    {/* dont ask confirmation if there are nothing to overwrite */}
                                                    {copyVisible && ipds.length === 0 && (
                                                        <ProjectAssigner
                                                            orders={orderDictionary}
                                                            onClose={this.closeOrders}
                                                            excludeId={store.details.id}
                                                            onAssign={this.copyIpd}
                                                            loading={proxyLoading}
                                                        />
                                                    )}

                                                    {copyVisible && ipds.length > 0 && (
                                                        <RemoveConfirmation<string>
                                                            what={() => "ИРД"}
                                                            actionName="перезаписать"
                                                            onConfirmed={this.copyIpd}
                                                            render={({ confirmRemoving }) => (
                                                                <ProjectAssigner
                                                                    orders={orderDictionary}
                                                                    onClose={this.closeOrders}
                                                                    excludeId={store.details.id}
                                                                    onAssign={confirmRemoving}
                                                                    loading={proxyLoading}
                                                                />
                                                            )}
                                                        />
                                                    )}

                                                    {detailsVisible && (
                                                        <IpdsDetails
                                                            factory={factory}
                                                            ipdTypes={ipdTypes}
                                                            units={ipds}
                                                            onSave={this.saveDetails}
                                                            onClose={this.closeDetails}
                                                        />
                                                    )}

                                                    {ipds.length > 0 && <IpdHeader active={activeRow} />}

                                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                                        <Droppable droppableId="ipd">
                                                            {(provided) => (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {ipds.map((ipd, index) => {
                                                                        return (
                                                                            <Draggable
                                                                                key={ipd.guid}
                                                                                draggableId={ipd.guid}
                                                                                index={index}
                                                                                isDragDisabled={!!activeRow}
                                                                            >
                                                                                {(draggable, snapshot) => {
                                                                                    const child = (
                                                                                        <IpdRow
                                                                                            key={ipd.guid}
                                                                                            ipd={ipd}
                                                                                            active={activeRow}
                                                                                            onActivate={
                                                                                                this.onActivateRow
                                                                                            }
                                                                                            baseUrl={baseUrl}
                                                                                            onChange={this.onChange}
                                                                                            onRemove={this.onRemoveRow}
                                                                                            readOnly={readOnly}
                                                                                            onSave={this.onSaveRow}
                                                                                            onUpload={this.onUpload}
                                                                                            draggable={draggable}
                                                                                            isDragging={
                                                                                                snapshot.isDragging
                                                                                            }
                                                                                            canClientEdit={
                                                                                                canClientEdit
                                                                                            }
                                                                                            stages={
                                                                                                store.details.stages
                                                                                            }
                                                                                        />
                                                                                    );

                                                                                    return snapshot.isDragging
                                                                                        ? ReactDOM.createPortal(
                                                                                              child,
                                                                                              portal
                                                                                          )
                                                                                        : child;
                                                                                }}
                                                                            </Draggable>
                                                                        );
                                                                    })}

                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div>
                                            );
                                        }}
                                    </Field>

                                    {!readOnly && (
                                        <div
                                            className="collapse-add"
                                            style={{ margin: "16px auto", marginBottom: "80px" }}
                                        >
                                            <PlanrButton
                                                type="dashed"
                                                icon="general-plus-big"
                                                className="collapse-add-rows-small"
                                                onClick={async () => {
                                                    const unit = await factory.emptyIpd();
                                                    this.onAddRow(unit);
                                                }}
                                            >
                                                Добавить
                                            </PlanrButton>

                                            <PlanrButton
                                                type="dashed"
                                                icon="general-plus-big"
                                                className="collapse-add-rows-small"
                                                onClick={this.showDetails}
                                            >
                                                Добавить из справочника
                                            </PlanrButton>
                                            <PlanrButton
                                                type="dashed"
                                                icon="general-plus-big"
                                                className="collapse-add-rows-small"
                                                onClick={this.showOrders}
                                            >
                                                Добавить из проекта
                                            </PlanrButton>
                                        </div>
                                    )}
                                </div>
                                {(!readOnly || canClientEdit) && (
                                    <div className={styles.buttons}>
                                        <div className={styles.buttonsLeft}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={""}
                                                isNewlyCreated={true}
                                                submitOnDirty={true}
                                                onRemove={eat}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Form>
                        );
                    } else {
                        return <h2>Сначала необходимо сохранить основные данные договора</h2>;
                    }
                }}
            </Formik>
        );
    }
    toggleShowPrint = () => {
        this.setState({ showPrint: !this.state.showPrint });
    };
    copyIpd = async (orderId: string) => {
        const { orderDictionary, store } = this.props;
        const source = orderDictionary.asMap[orderId];

        if (source && this.fieldProps) {
            this.setState({ proxyLoading: true });

            try {
                const proxy = OrderSummary.create(emptyOrder(false), getEnv(store.details));
                const loaded = await proxy.load(source.id, true);

                if (loaded) {
                    const newValue = proxy.ipds.map((i) => ({
                        ...mapIpd(i),
                        number: "",
                    }));

                    // копируем файлы
                    const fileIds = newValue
                        .map((ipd) => {
                            let filesRequest: string[] = [];
                            if (ipd.requestFile) {
                                ipd.requestFile.forEach((f) => {
                                    filesRequest.push(f.fileId);
                                });
                            }
                            let filesDocument: string[] = [];
                            if (ipd.documentFile) {
                                ipd.documentFile.forEach((f) => {
                                    filesDocument.push(f.fileId);
                                });
                            }
                            return [...filesRequest, ...filesDocument];
                        })
                        .flat()
                        .filter((id) => !!id);
                    const newArr = JSON.parse(JSON.stringify(newValue));
                    if (fileIds.length) {
                        const uploads = await proxy.copyFiles(fileIds);

                        if (!uploads) {
                            return;
                        }
                        newArr.map((ipd: OrderIpdType) => {
                            if (ipd.requestFile) {
                                ipd.requestFile.map((file) => {
                                    file.fileId = uploads[file.fileId] || "";
                                    return file;
                                });
                            }

                            if (ipd.documentFile) {
                                ipd.documentFile.map((file) => {
                                    file.fileId = uploads[file.fileId] || "";
                                    return file;
                                });
                            }
                            return ipd;
                        });
                    }

                    this.fieldProps.form.setFieldValue(this.fieldProps.field.name, newArr);
                    this.fieldProps.form.setFieldTouched(this.fieldProps.field.name, true);
                }
            } finally {
                this.setState({ proxyLoading: false });
            }
        }

        this.closeOrders();
    };

    onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination || !this.fieldProps) {
            return;
        }

        // initial index
        const from = result.source.index;
        // new index
        const to = result.destination.index;

        const ipds: SaveOrderIpd[] = this.fieldProps.field.value;
        const newValue = move(ipds, from, to);
        this.fieldProps.form.setFieldValue(this.props.name, newValue);
        this.fieldProps.form.setFieldTouched(this.props.name, true);
    };

    onChange = (changes: Array<{ field: string; value: any }>) => {
        if (this.state.activeRow) {
            const apply: any = {};
            changes.forEach((change) => (apply[change.field] = change.value));
            this.setState({ activeRow: { ...this.state.activeRow, ...apply } });
        }
    };

    onAddRow = (ipd: SaveOrderIpd) => {
        if (this.fieldProps) {
            const ipds: SaveOrderIpd[] = this.fieldProps.field.value;
            const newValue = [...ipds, ipd];
            this.fieldProps.form.setFieldValue(this.props.name, newValue);
            this.fieldProps.form.setFieldTouched(this.props.name, true);
            this.onActivateRow({ ...ipd });
        }
    };

    onRemoveRow = (ipd: SaveOrderIpd) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;

            const ipds: SaveOrderIpd[] = fieldProps.field.value;
            const newValue = ipds.filter((i) => i.guid !== ipd.guid);
            fieldProps.form.setFieldValue(this.props.name, newValue);
            fieldProps.form.setFieldTouched(this.props.name, true);

            setTimeout(() => fieldProps.form.validateField(this.props.name));
        }
    };

    onSaveRow = (ipd: SaveOrderIpd) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;

            const ipds: SaveOrderIpd[] = fieldProps.field.value;
            const newValue = ipds.map((i) => (i.guid === ipd.guid ? { ...ipd } : i));

            fieldProps.form.setFieldValue(this.props.name, newValue);
            fieldProps.form.setFieldTouched(this.props.name, true);
            this.onActivateRow(null);

            setTimeout(() => fieldProps.form.validateField(this.props.name));
        }
    };

    onActivateRow = (row: SaveOrderIpd | null) => this.setState({ activeRow: row });

    showDetails = () => this.setState({ detailsVisible: true });

    closeDetails = () => this.setState({ detailsVisible: false });

    saveDetails = (ipds: SaveOrderIpd[]) => {
        if (this.fieldProps) {
            const fieldProps = this.fieldProps;

            const value: SaveOrderIpd[] = fieldProps.field.value;
            const currentItems = value.map((i) => i.guid).sort();
            const newItems = ipds.map((i) => i.guid).sort();

            fieldProps.form.setFieldValue(this.props.name, ipds);
            fieldProps.form.setFieldTouched(this.props.name, true);
            this.closeDetails();

            // activate new single row
            setTimeout(() => {
                const diff = difference(newItems, currentItems);
                if (diff.length > 0 && this.fieldProps) {
                    const lastSelected = findLast(ipds, (ipd) => diff.includes(ipd.guid));
                    if (lastSelected) {
                        this.onActivateRow(lastSelected);
                    }
                }
            });
        }
    };

    showOrders = () => this.setState({ copyVisible: true });

    closeOrders = () => this.setState({ copyVisible: false });

    onUpload = (file: File) => this.props.store.details.uploadFile(file);

    getOrderId = () => this.props.store.details.id || EMPTY_OBJECT_ID;

    navigationPromt = (location: Location) => {
        // just tabs switching
        return location.pathname.startsWith(routes.orders.ipdTab(this.getOrderId())) ? true : false;
    };
}

export const OrderIpd = observer(Ipd);

interface IpdProps {
    name: string;
    orderDictionary: OrderDictionaryType;
    ipdTypes: IpdTypeDictionaryType;
    readOnly: boolean;
    factory: IpdFactory;
    baseUrl: string;
    onPrint: (content: string) => Promise<boolean | undefined>;
    canClientEdit?: boolean;
    store: OrdersStoreType;
    schema: any;
    orderId: string;
}
interface Select {
    id: string;
    label: string;
}
interface IpdState {
    copyVisible: boolean;
    showPrint: boolean;
    proxyLoading: boolean;
    preparingPrint: boolean;
    detailsVisible: boolean;
    activeRow: SaveOrderIpd | null;
    content: Select;
    stages: OrderStageRowType[] | undefined | null;
}

interface SelectRendererProps {
    item: Select;
    items: any[];
    onSelect: (item: SelectItem) => void;
}

const MentorSelectRenderer = ({ item, items, onSelect }: SelectRendererProps) => {
    const style: React.CSSProperties = {
        fontWeight: 400,
    };

    if (!item || !item.id) {
        style.color = "#ea561e";
    }

    return (
        <SimpleSelect
            className={styles.mentorSelect}
            activeItem={item}
            filterable={true}
            itemPredicate={filterItemPredicate}
            popoverProps={{
                boundary: "viewport",
                usePortal: true,
                popoverClassName: "order-content_mentor-select-dropdown",
            }}
            inputProps={{
                placeholder: "Раздел",
            }}
            items={items}
            itemRenderer={renderSingleOption}
            onItemSelect={onSelect}
            matchTargetWidth={false}
        >
            <DefaultSelectedOption option={item} style={style} />
        </SimpleSelect>
    );
};

function getFormValues(order: OrderSummaryType) {
    const result = {
        [fields.orderIpds]: order.ipds.map(mapIpd),
    };

    return result;
}
