import { Classes, Dialog } from "@blueprintjs/core";
import { History, Location, UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import func from "modules/agents/functionalities";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Search } from "modules/common/components/form/Search";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { Block } from "modules/common/components/page/Block";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { isNewlyCreated } from "modules/common/models/entity";
import { routes } from "modules/common/routes";
import { texts } from "modules/common/texts";
import { RoleDetails } from "modules/session/access/components/roles/RoleDetails";
import { can } from "modules/session/auth/access";
import sessionFunc from "modules/session/functionalities";
import React from "react";
import { Route, matchPath } from "react-router";
import { OutsourcersStoreType } from "../models/outsourcers-store";
import styles from "./OutsourcersPage.module.scss";
import { OutsourcerDetails } from "./details/OutsourcerDetails";
import { OutsourcersList } from "./list/OutsourcersList";
import OutsourcerRequests from "./requests/OutsourcerRequests";
import RequestEmail from "./request-email/RequestEmail";

const localTabName = "AgentsFilterTab";
class OutsourcersPage extends React.Component<OutsourcersPageProps, OutsourcersPageState> {
  private unregister: UnregisterCallback | null = null;

  constructor(props: OutsourcersPageProps) {
    super(props);

    this.state = {
      outsourcerId: getSelectedOutsourcerId(props.location.pathname),
      showAccess: false,
      load: false,
      showRequestsAgents: false,
      showOrderEmail: false,
    };
  }

  async componentDidMount() {
    const { history, store } = this.props;
    document.title = "Контрагенты";

    store.init(this.state.outsourcerId);
    let local = localStorage.getItem(localTabName);
    try {
      if (local) {
        store.list.tabSelect(JSON.parse(local));
      }
    } catch (e) {
      /*Ignore*/
    }
    this.unregister = history.listen(async (location) => {
      const outsourcerId = getSelectedOutsourcerId(location.pathname);
      if (this.state.outsourcerId !== outsourcerId) {
        this.setState({ outsourcerId, load: true });
        await this.loadDetails(outsourcerId);
        this.setState({ load: true });
      }
    });
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
  }

  title = (header: string) => {
    const showButtons = !isNewlyCreated(this.state.outsourcerId);
    const canAccess = showButtons && can(sessionFunc.ACCESS_EDIT, this.props.access);

    return (
      <div className={styles.title}>
        {header}
        {canAccess && (
          <PlanrButton type="secondary" size="small" onClick={this.showAccess} className={styles.showAccess}>
            Права доступа
          </PlanrButton>
        )}
      </div>
    );
  };

  render() {
    const { store, access, baseUrl } = this.props;
    const { loading } = store;
    const { showAccess, showRequestsAgents, showOrderEmail } = this.state;
    const { tabIndex, outsourceIndex } = store.list;
    const canChange = can(func.OUTSOURCER_EDIT, access);
    const canConfirm = can(func.OUTSOURCER_CONFIRMATION, access);
    const canSupplierOrder = can(func.AGENTS_SUPPLIER_ORDER, access);

    const path = routes.agents.outsourcers.details.outsourcers();
    const title = "Контрагент";
    const { outsourcers } = store.requestsList;
    return (
      <Block className={styles.page}>
        {canChange && (
          <PlanrButton
            type="secondary"
            icon="general-plus-big"
            onClick={this.onOutsourcerAdd}
            title={texts.add}
            className={styles.addButton}
            size="small"
          >
            Добавить
          </PlanrButton>
        )}
        <PlanrButton
          type="lightblueish"
          icon="general-doc-check"
          onClick={this.toggleShowRequestEmail}
          title={texts.add}
          className={styles.orderRequestButton}
          size="small"
        >
          Запросы
        </PlanrButton>
        {canConfirm && (
          <div>
            <PlanrButton
              type="lightgreenish"
              icon="general-add-user"
              onClick={this.toggleShowRequests}
              title={"Заявки"}
              className={styles.requestsButton}
              size="small"
            >
              Заявки
            </PlanrButton>
            {outsourcers && outsourcers.length > 0 && (
              <div className={styles.requestCount}>{outsourcers.length >= 100 ? "99+" : outsourcers.length}</div>
            )}
          </div>
        )}
        {showRequestsAgents && (
          <Dialog
            isOpen={showRequestsAgents}
            title={"Заявки"}
            backdropClassName="standard"
            onClose={this.toggleShowRequests}
            className={`${styles.FilesDialog} figma-dialog`}
          >
            <OutsourcerRequests {...this.props} />
          </Dialog>
        )}
        {showOrderEmail && (
          <DetailsPopup title={"Запросы"} onCancel={this.toggleShowRequestEmail} width={1454}>
            <RequestEmail
              agentsList={store.list.data}
              categories={store.categories}
              orderDictionary={store.orderDictionary}
              baseUrl={baseUrl}
              store={store.sendEmailsList}
              toggleClose={this.toggleShowRequestEmail}
            />
          </DetailsPopup>
        )}
        <div className={styles.tabButtons}>
          <PlanrButton
            style={{ width: "120px" }}
            type={tabIndex === "" ? "blueish" : "lightblueish"}
            size="small"
            onClick={() => {
              localStorage.setItem(localTabName, JSON.stringify(""));

              store.list.tabSelect("");
              store.list.tabOutsourceSelect("");
            }}
          >
            Все
          </PlanrButton>
          <PlanrButton
            style={{ width: "120px" }}
            type={tabIndex === "outsourcer" ? "blueish" : "lightblueish"}
            size="small"
            onClick={() => {
              localStorage.setItem(localTabName, JSON.stringify("outsourcer"));

              store.list.tabSelect("outsourcer");
            }}
          >
            Аутсорсеры
          </PlanrButton>
          <PlanrButton
            style={{ width: "120px" }}
            type={tabIndex === "client" ? "blueish" : "lightblueish"}
            size="small"
            onClick={() => {
              localStorage.setItem(localTabName, JSON.stringify("client"));

              store.list.tabSelect("client");
              store.list.tabOutsourceSelect("");
            }}
          >
            Заказчики
          </PlanrButton>
          <PlanrButton
            style={{ width: "120px" }}
            type={tabIndex === "supplier" ? "blueish" : "lightblueish"}
            size="small"
            onClick={() => {
              localStorage.setItem(localTabName, JSON.stringify("supplier"));
              store.list.tabSelect("supplier");
              store.list.tabOutsourceSelect("");
            }}
          >
            Поставщики
          </PlanrButton>
          <PlanrButton
            style={{ width: "120px" }}
            type={tabIndex === "agent" ? "blueish" : "lightblueish"}
            size="small"
            onClick={() => {
              localStorage.setItem(localTabName, JSON.stringify("agent"));
              store.list.tabSelect("agent");
              store.list.tabOutsourceSelect("");
            }}
          >
            Агенты
          </PlanrButton>
        </div>
        {tabIndex === "outsourcer" && (
          <div className={styles.tabButtons2}>
            <PlanrButton
              style={{ width: "120px" }}
              type={outsourceIndex === "" ? "blueish" : "lightblueish"}
              size="small"
              onClick={() => {
                store.list.tabOutsourceSelect("");
              }}
            >
              Все
            </PlanrButton>

            <PlanrButton
              style={{ width: "120px" }}
              type={outsourceIndex === "legalOutsourcer" ? "blueish" : "lightblueish"}
              size="small"
              onClick={() => {
                store.list.tabOutsourceSelect("legalOutsourcer");
              }}
            >
              Юр.лица
            </PlanrButton>
            <PlanrButton
              style={{ width: "120px" }}
              type={outsourceIndex === "physOutsourcer" ? "blueish" : "lightblueish"}
              size="small"
              onClick={() => {
                store.list.tabOutsourceSelect("physOutsourcer");
              }}
            >
              Физ.лица
            </PlanrButton>
          </div>
        )}
        <div className={styles.actions}>
          <div className={styles.right}>
            <PlanrButton
              type="graybtn"
              icon="general-export"
              title={texts.export}
              // onClick={this.export}
              round={true}
            />
            <PlanrButton
              type="graybtn"
              icon="general-import"
              // onClick={this.print}
              title={texts.export}
              round={true}
            />
            <PlanrButton
              type="graybtn"
              icon="general-print"
              // onClick={this.print}
              title={texts.export}
              round={true}
            />
            <Search query={store.list.query} onSearch={this.props.store.list.setQuery} />
          </div>
        </div>
        <Route path={path}>
          <DetailsPopup title={this.title(title)} onCancel={this.onOutsourcerSelected} width={1754}>
            <OutsourcerDetails
              baseUrl={baseUrl}
              access={access}
              canChange={canChange}
              canRemove={canChange}
              canConfirm={canConfirm}
              canSupplierOrder={canSupplierOrder}
              workTypes={store.workTypes}
              outsourcer={store.details}
              onSaved={this.onOutsourcerSaved}
              onRemoved={this.onOutsourcerRemoved}
              onPrintCredentials={store.printCredentials}
              onPrintDocCredentials={this.props.store.printDocCredentials}
              confirmRequest={this.props.store.confirmRequest}
              showSensitiveData={true}
              categories={store.categories}
            />
          </DetailsPopup>

          <Dialog
            icon="info-sign"
            title="Права доступа"
            canEscapeKeyClose={true}
            canOutsideClickClose={false}
            isOpen={showAccess}
            onClose={this.hideAccess}
            style={{ width: `1180px` }}
            backdropClassName="standard"
          >
            <div className={`${Classes.DIALOG_BODY} ${styles.userAccess}`}>
              <RoleDetails role={store.details.role} columns={store.accessColumns} onToggle={this.setFunctionality} />
            </div>
          </Dialog>
        </Route>
        {loading ? (
          <LocalLoader active zIndex={0} />
        ) : (
          <OutsourcersList
            store={store.list}
            onOutsourcerSelect={this.onOutsourcerSelected}
            onOutsourcerRemoved={this.onOutsourcerRemoved}
          />
        )}
      </Block>
    );
  }

  onOutsourcerSelected = (outsourcerId: string = "") => {
    const { history } = this.props;
    const listUrl = routes.agents.outsourcers.outsourcers;

    const detailsUrl = routes.agents.outsourcers.details.outsourcers(outsourcerId);

    history.push(outsourcerId ? detailsUrl : listUrl);
  };

  onOutsourcerSaved = () => {
    this.onOutsourcerSelected("");
    this.loadList();
  };

  onOutsourcerRemoved = () => {
    const { history } = this.props;

    this.loadList();

    const url = routes.agents.outsourcers.outsourcers;
    history.push(url);
  };

  onOutsourcerAdd = () => {
    this.onOutsourcerSelected(EMPTY_OBJECT_ID);
  };

  loadList = () => this.props.store.load();

  loadDetails = async (id: string) => {
    const { store } = this.props;

    // prefill form with cached values before loading
    const outsourcer = store.list.asMap[id];

    if (outsourcer) {
      applySnapshot(store.details, getSnapshot(outsourcer));
    }

    await store.details.load(id);
  };

  showAccess = () => this.setState({ showAccess: true });
  hideAccess = () => this.setState({ showAccess: false });

  toggleShowRequests = () => this.setState({ showRequestsAgents: !this.state.showRequestsAgents });
  toggleShowRequestEmail = () => this.setState({ showOrderEmail: !this.state.showOrderEmail });

  setFunctionality = (roleId: string, functionality: string, state: boolean) =>
    this.props.store.details.setFunctionality(functionality, state);
}

export default observer(OutsourcersPage);

function getSelectedOutsourcerId(pathname: string) {
  const detailsUrl = routes.agents.outsourcers.details.outsourcers();

  const match = matchPath<OutsourcersId>(pathname, detailsUrl);

  return match && match.params ? match.params.outsourcerId : "";
}

interface OutsourcersPageProps {
  store: OutsourcersStoreType;
  isLegalEntities: boolean;
  access: string[];
  history: History;
  location: Location;
  baseUrl: string;
}

interface OutsourcersId {
  outsourcerId: string;
}

interface OutsourcersPageState extends OutsourcersId {
  showAccess: boolean;
  showRequestsAgents: boolean;
  showOrderEmail: boolean;
  load: boolean;
}
