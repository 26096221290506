import React from "react";
import { ScheduleStoreType } from "../models/schedule-store";
import { observer } from "mobx-react";
import styles from "./SchedulePage.module.scss";
import { DepartmentBlock } from "./DepartmentBlock";
import { PeriodSlider } from "modules/common/components/period/PeriodSlider";
import { ScheduleUnitType } from "../models/schedule-unit";
import { Menu, MenuItem, ContextMenu } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { NoData } from "modules/common/components/table/NoData";
import { TimesheetStoreType } from "modules/spending/timesheet/models/timesheet-store";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { TotalLine } from "./TotalLine";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { SessionType } from "modules/session/auth/models/session";
import { can } from "modules/session/auth/access";
import func from "modules/spending/functionalities";
import { WorkDays } from "modules/spending/components/WorkDays";
import { Zoomer } from "modules/common/components/zoomer/Zoomer";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { Search } from "modules/common/components/form/Search";
import { ScheduleTableHeader } from "./ScheduleTableHeader";
import { WorkCalendar } from "./WorkCalendar";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const menu = menuItems();

class Page extends React.Component<SchedulePageProps, SchedulePageStore> {
    constructor(props: any) {
        super(props);

        this.state = { currentRows: [] };
    }

    componentDidMount() {
        document.title = menu.spending.nested.schedule.label;

        const { store } = this.props;
        store.load();
    }

    render() {
        const { store, setUserFilter } = this.props;
        const { departmentMap, year, month, workDaysCount, monthsWorkHours, totals, query } = store;
        const { employeeOptions, selectedId, isEmpty, isLoading } = store;
        const { currentRows } = this.state;
        const [plan, actual] = totals;
        const canEdit = this.canEdit();
        const canExport = this.canExport();

        return (
            <div className={styles.page}>
                <LocalLoader active={isLoading} />

                <div className={styles.filters}>
                    <div className={styles.periodSlider}>
                        <div>
                            <PeriodSlider year={year} month={month} onChange={this.gotoMonth} />
                            <div className={styles.center}>
                                <WorkDays days={workDaysCount} hours={monthsWorkHours} users={store.rows.length} />
                            </div>
                        </div>

                        <div className={styles.actions}>
                            <div className={styles.right}>
                                {!selectedId && (
                                    <>
                                        {canEdit && (
                                            <>
                                                <PlanrButton
                                                    type="graybtn"
                                                    icon="general-dollar"
                                                    title="Пересчитать"
                                                    onClick={this.recalculate}
                                                    round={true}
                                                />
                                                <WorkCalendar onUpload={store.uploadCalendar} />
                                            </>
                                        )}

                                        {canExport && (
                                            <>
                                                <PlanrButton
                                                    type="graybtn"
                                                    icon="general-export"
                                                    title={texts.export}
                                                    onClick={this.export}
                                                    round={true}
                                                />
                                                <PlanrButton
                                                    type="graybtn"
                                                    icon="general-print"
                                                    onClick={this.print}
                                                    title={texts.print}
                                                    round={true}
                                                />
                                            </>
                                        )}

                                        <Search query={query} onSearch={setUserFilter} />
                                    </>
                                )}
                            </div>
                            <Zoomer zoom={store.zoom} min={50} max={100} step={5} onChange={store.setZoom} />
                            <CollapseExpandAll onChange={store.collapser.setAll} />
                        </div>
                    </div>

                    <div className={styles.tools}>
                        {canEdit && (
                            <PlanrButton
                                type="secondary"
                                icon="general-plus-big"
                                onClick={this.addUnit}
                                size="small"
                                title=""
                                className="action-icon add-button"
                            >
                                Добавить сотрудника
                            </PlanrButton>
                        )}

                        <div className={styles.left}>
                            {canEdit && (
                                <div className={styles.override}>
                                    <RemoveConfirmation
                                        onConfirmed={store.fromPrevious}
                                        what={this.overrideLabel}
                                        actionName="перезаписать"
                                        render={({ confirmRemoving }) => (
                                            <PlanrButton
                                                type="graybtn"
                                                icon="general-calend-prev"
                                                title="Из предыдущего"
                                                onClick={confirmRemoving}
                                                round={true}
                                            />
                                        )}
                                    />
                                    <RemoveConfirmation
                                        onConfirmed={this.props.store.fromNext}
                                        what={this.overrideLabel}
                                        actionName="перезаписать"
                                        render={({ confirmRemoving }) => (
                                            <PlanrButton
                                                type="graybtn"
                                                icon="general-calend-next"
                                                title="Из следующего"
                                                onClick={confirmRemoving}
                                                round={true}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`print-area ${styles.zoomArea} ${styles.showZoomArea}`}
                        style={{ transform: `scale(${store.transform})`, marginTop: "-45px" }}
                    >
                        <ScheduleTableHeader store={store} />
                        <div className={styles.totalLine}>
                            <div style={{ position: "relative", minHeight: "1rem" }} className={styles.actualTotal}>
                                <TotalLine store={actual} bonusTaxeHidden={store.bonusTaxeHidden}>
                                    <span className={styles.totalTitle}>Итоги</span>
                                </TotalLine>
                            </div>
                            <div style={{ position: "relative", minHeight: "1rem" }} className={styles.planTotal}>
                                <TotalLine store={plan} plan={true} bonusTaxeHidden={store.bonusTaxeHidden} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`print-area ${styles.zoomArea} `} style={{ transform: `scale(${store.transform})` }}>
                    <div className={`${styles.hiddenZoomArea}`}>
                        <ScheduleTableHeader store={store} />
                        <div className={styles.totalLine}>
                            <div style={{ position: "relative", minHeight: "1rem" }} className={styles.actualTotal}>
                                <TotalLine store={actual} bonusTaxeHidden={store.bonusTaxeHidden}>
                                    <span className={styles.totalTitle}>Итоги</span>
                                </TotalLine>
                            </div>
                            <div style={{ position: "relative", minHeight: "1rem" }} className={styles.planTotal}>
                                <TotalLine store={plan} plan={true} bonusTaxeHidden={store.bonusTaxeHidden} />
                            </div>
                        </div>
                    </div>

                    {Object.keys(departmentMap).map((department) => (
                        <DepartmentBlock
                            department={department}
                            store={store}
                            onDoubleClick={this.selectUnit}
                            onClick={this.highlightUnit}
                            currentRows={currentRows}
                            key={department}
                            editableUnitId={selectedId}
                            onSaved={this.onUnitSaved}
                            onSave={this.saveUnit}
                            onRemove={this.removeUnit}
                            employee={employeeOptions}
                            onContextMenu={this.onBlockContextMenu}
                            collapsed={!store.collapser.opened.get(department)}
                            toggleCollapse={store.collapser.toggle}
                            readonly={!canEdit}
                        />
                    ))}
                </div>

                {isEmpty && !isLoading && <NoData className={styles.nodata} />}
            </div>
        );
    }

    renderBlockContextMenu = (unit: ScheduleUnitType) => {
        return (
            <Menu>
                <MenuItem onClick={() => this.removeUnit(unit)} text={texts.remove} />
                {this.state.currentRows.length > 0 && (
                    <MenuItem onClick={() => this.removeAllUnits()} text={`${texts.remove} выделенное`} />
                )}
            </Menu>
        );
    };

    onBlockContextMenu = (e: React.MouseEvent, unit: ScheduleUnitType) => {
        const items = this.renderBlockContextMenu(unit);

        e.preventDefault();
        ContextMenu.show(items, { left: e.clientX, top: e.clientY });
    };

    overrideLabel = () => "расписание";

    gotoMonth = (year: number, month: number) => {
        this.props.store.load(year, month);
        this.props.timesheet.setPeriod(year, month);
    };

    saveUnit = (id: string, values: any) => {
        const { store } = this.props;

        return store.save(id, values);
    };

    onUnitSaved = () => this.selectUnit(null);

    removeUnit = async (unit: ScheduleUnitType) => {
        const success = await this.props.store.remove([unit.id]);

        success && this.selectUnit(null);
        success && this.clearHighlighting();

        return success;
    };

    removeAllUnits = async () => {
        if (this.state.currentRows.length) {
            const success = await this.props.store.remove(this.state.currentRows);

            success && this.selectUnit(null);
            success && this.clearHighlighting();
        }
    };

    addUnit = () => {
        this.clearHighlighting();
        this.props.store.addUnit();
    };

    selectUnit = (selected: ScheduleUnitType | null = null) => {
        const { store } = this.props;

        this.canEdit() && store.selectUnit(selected);
        selected && this.setState({ currentRows: [selected.id] });
    };

    highlightAllUnuts = () => {
        const { rows } = this.props.store;
        this.setState({ currentRows: rows.map((row) => row.plan.id) });
    };

    highlightUnit = (selected: ScheduleUnitType) => {
        const { store } = this.props;

        if (store.selectedId) {
            return;
        }

        const { currentRows } = this.state;

        // multiple selection
        if (this.canEdit()) {
            const newValue = currentRows.includes(selected.id)
                ? currentRows.filter((id) => id !== selected.id)
                : [...currentRows, selected.id];

            this.setState({ currentRows: newValue });
        } else {
            // single selection
            const newValue = currentRows.includes(selected.id) ? [] : [selected.id];

            this.setState({ currentRows: newValue });
        }
    };

    clearHighlighting = () => this.setState({ currentRows: [] });

    canEdit = () => can(func.SCHEDULE_EDIT, this.props.session.access);

    canExport = () => can(func.SCHEDULE_EXPORT, this.props.session.access);

    print = async () => this.props.store.export(true);

    export = () => this.props.store.export(false);

    recalculate = () => this.props.store.recalculate();
}

export const SchedulePage = observer(Page);

interface SchedulePageProps {
    session: SessionType;
    store: ScheduleStoreType;
    timesheet: TimesheetStoreType;
    setUserFilter: (value: string) => void;
}

interface SchedulePageStore {
    currentRows: string[];
}
