import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Classes, Dialog } from "@blueprintjs/core";
import { Money } from "modules/common/components/money/Money";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { routes } from "modules/common/routes";
import { prettyRound } from "modules/common/services/formatting/numbers";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { isNotNull, nameof } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { EngineerDatasetOrderItemType } from "modules/main/models/engineers-loading-chart";
import { orderTitle } from "modules/orders-manage/models/order";
import { agGridLocale } from "modules/root/services/locale";
import React from "react";
import { RenderOrderStatus } from "../../table/columns";
import styles from "./OrdersList.module.scss";

const columns = () => {
    const result: ColDef[] = [
        {
            headerName: texts.name,
            field: nameof((a: EngineerDatasetOrderItemType) => a.name) as string,
            width: 350,
            sort: "desc",
            valueGetter: ({ data }) => orderTitle(data),
            cellRendererFramework: ({ value, data }: ICellRendererParams) => {
                const src = routes.orders.details(getRowNodeId(data));
                return (
                    <a href={src} target="_blank" rel="noopener noreferrer">
                        {value}
                    </a>
                );
            },
        },

        {
            headerName: "Статус",
            field: nameof((a: EngineerDatasetOrderItemType) => a.status) as string,
            width: 140,

            cellRendererFramework: ({ value, data }: ICellRendererParams) => {
                const row: EngineerDatasetOrderItemType = data;

                return RenderOrderStatus(row.status, row.color);
            },
        },
        {
            headerName: "Стоимость",
            field: nameof((a: EngineerDatasetOrderItemType) => a.sum) as string,
            width: 150,

            cellRendererFramework: ({ value }: ICellRendererParams) => {
                return <Money amount={value} />;
            },
        },
        {
            headerName: "Рент, %",
            field: nameof((a: EngineerDatasetOrderItemType) => a.profitabilityPercents) as string,
            width: 80,
            cellRendererFramework: ({ value }: ICellRendererParams) => {
                const profitabilityPercents = +value;
                const style: React.CSSProperties = {
                    color: `${
                        profitabilityPercents > 0 ? "#1dd278" : profitabilityPercents < 0 ? "#E31818" : "#00273D"
                    }`,
                };

                return <div style={style}>{prettyRound(value)}%</div>;
            },
        },
        {
            headerName: "Рентабельность",
            field: nameof((a: EngineerDatasetOrderItemType) => a.profitability) as string,
            width: 150,

            cellRendererFramework: ({ value }: ICellRendererParams) => {
                const profitability = +value;
                const style: React.CSSProperties = {
                    color: `${profitability > 0 ? "#1dd278" : profitability < 0 ? "#E31818" : "#00273D"}`,
                };

                return <Money style={style} amount={value} />;
            },
        },
        {
            headerName: "Премия ГИП",
            field: nameof((a: EngineerDatasetOrderItemType) => a.gipPremiumSum) as string,
            width: 150,

            cellRendererFramework: ({ value }: ICellRendererParams) => {
                return <Money amount={value} />;
            },
        },
    ];

    return result.filter(isNotNull);
};

export class OrdersList extends React.PureComponent<OrdersListtProps> {
    private options = DefaultAgGridOptions(true);
    private columnDefs = columns();

    render() {
        const { orders, onClose, title } = this.props;

        return (
            <Dialog
                isCloseButtonShown={true}
                isOpen={true}
                className={`${styles.list} figma-dialog`}
                onClose={onClose}
                title={title}
                backdropClassName="standard"
                canOutsideClickClose={false}
                canEscapeKeyClose={false}
            >
                <div className={Classes.DIALOG_BODY}>
                    <GridTheme bordered={true} className={"designed rounded"}>
                        <AgGridReact
                            rowStyle={{
                                borderBottom: "1 px solid #EDF0F2",
                                fontFamily: "GothamPro, Arial, sans-serif",
                                color: "#00273D",
                                fontSize: "12px",

                                // backgroundColor: "#fff",
                            }}
                            suppressRowClickSelection={true}
                            suppressCellSelection={true}
                            enableCellTextSelection={true}
                            columnDefs={this.columnDefs}
                            rowData={orders}
                            getRowNodeId={getRowNodeId}
                            localeText={agGridLocale}
                            gridOptions={this.options}
                            context={this.props}
                            immutableData={true}
                            modules={defaultModules}
                        />
                    </GridTheme>
                </div>
            </Dialog>
        );
    }
}

const getRowNodeId = (node: EngineerDatasetOrderItemType) => node.id;

interface OrdersListtProps {
    orders: EngineerDatasetOrderItemType[];
    title: string;
    onClose: () => void;
}
