import { Constants } from "modules/root/models/constants";

export const IncomingTypeOptions = () => [
    { id: Constants.bankInvoiceRowTypeIncoming, label: Constants.bankInvoiceRowTypeIncoming },
    { id: Constants.bankInvoiceRowTypeIncomingCorrection, label: Constants.bankInvoiceRowTypeIncomingCorrection },
    { id: Constants.bankInvoiceRowTypeCredit, label: Constants.bankInvoiceRowTypeCredit },
    { id: Constants.bankInvoiceRowTypeAdditionalIncome, label: Constants.bankInvoiceRowTypeAdditionalIncome },
];

export const SpendingTypeOptions = () => [
    { id: Constants.bankInvoiceRowTypeNonProduction, label: Constants.bankInvoiceRowTypeNonProduction },
    { id: Constants.bankInvoiceRowTypeProduction, label: Constants.bankInvoiceRowTypeProduction },
    { id: Constants.bankInvoiceRowTypeNdfl, label: Constants.bankInvoiceRowTypeNdfl },
    { id: Constants.bankInvoiceRowTypeDividends, label: Constants.bankInvoiceRowTypeDividends },
    { id: Constants.bankInvoiceRowTypeCreditRefund, label: Constants.bankInvoiceRowTypeCreditRefund },
];
