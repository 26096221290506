import React from "react";
import { ColDef, ICellRendererParams, ValueFormatterParams, RowNode } from "@ag-grid-community/core";
import { gridMoneyFormatter, formatMoney } from "modules/common/components/money/Money";
import { isNotNull } from "modules/common/services/typescript";
import {
  ImportedBankRowType,
  fields,
  ImportedBankRowSnapshotType,
  FinanceRequestStatisticDictionaryDictionaryItemType,
  OrderStatisticDictionaryItemType,
  StatisticDictionaryItemType,
} from "../models/bank-row";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import { Classes, MenuItem, Button } from "@blueprintjs/core";
import { ImportSpendingsStoreType } from "../models/import-store";
import {
  filterItemPredicate,
  SelectFactory,
  renderSingleOption,
  prevent,
  DefaultSelectedOption,
} from "modules/common/services/form/select";
import { EMPTY_INN } from "modules/common/constants";
import { Constants } from "modules/root/models/constants";
import { filterProjectItemPredicate as filterProject, optionLabel } from "modules/common/components/form/ProjectSelect";
import { ItemRenderer, ItemPredicate } from "@blueprintjs/select";
import { texts } from "modules/common/texts";
import styles from "./Table.module.scss";
import { IncomingTypeOptions } from "modules/expenses/common";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OutsourcedOrderSpendingType } from "modules/orders-manage/models/order-spending";
import { OtherOrderSpendingType } from "../../../orders-manage/models/order-spending";
import { ActualPaymentType } from "modules/agents/outsourcers/models/outsourcer-payment";
import { SpendingPayments } from "modules/expenses/summary/components/SpendingForm";
import { Loader } from "modules/common/components/loader/Loader";

type Fin = FinanceRequestStatisticDictionaryDictionaryItemType;
export const FinanceRequestSelect = SelectFactory<any>(false);
const SimpleSelect = SelectFactory<SelectItem>(false);

const CommonSelect = SelectFactory<StatisticDictionaryItemType>(false);
const ProjectSelect = SelectFactory<OrderStatisticDictionaryItemType>(false);

interface RowActionState {
  type: string;
  payload: string;
  tempPayload: string;
  spending: string;
  otherSpendings: OtherOrderSpendingType[];
  outsourcedSpendings: OutsourcedOrderSpendingType[];
  loading: boolean;
}

class RowAction extends React.PureComponent<ICellRendererParams, RowActionState> {
  private typeOptions: SelectItem[];
  private incomingOptions: SelectItem[];
  private payloadOptionRenderer: any;
  private nextPayloadOptionRenderer: any;
  constructor(props: ICellRendererParams) {
    super(props);

    const { store } = props.context as ContextActions;
    const row = props.data as ImportedBankRowSnapshotType;

    this.typeOptions = Constants.bankInvoiceRowTypes.map((rowType) => ({
      id: rowType,
      label: rowType,
    }));

    this.incomingOptions = IncomingTypeOptions();

    const type = store.getTypeValue(props.node.id ?? "");
    const outsourcedSpendings = store.getOutsourcedSpendings(props.node.id ?? "");
    const otherSpendings = store.getOtherSpendings(props.node.id ?? "");
    const loading = store.getLoad(props.node.id ?? "");
    const spending = store.getSpending(props.node.id ?? "");
    let payload = store.getPayloadValue(props.node.id ?? "");
    let tempPayload = store.getTempPayloadValue(props.node.id ?? "");
    this.payloadOptionRenderer = row.dictionaries[type]
      ? payloadOptionRendererFactory(row.dictionaries[type].key, row, this.tempPayloadClear)
      : null;

    this.nextPayloadOptionRenderer = row.dictionaries[`${type}_Next`]
      ? payloadOptionRendererFactory(row.dictionaries[`${type}_Next`].key, row, this.payloadClear)
      : null;

    this.state = { type, payload, tempPayload, outsourcedSpendings, otherSpendings, spending, loading };
  }
  getSpendings = async (id: string) => {
    const { store } = this.props.context as ContextActions;
    this.setState({ loading: true });
    const result = await store.loadSpendings(id);
    return result;
  };

  render() {
    const { store } = this.props.context as ContextActions;
    const row: ImportedBankRowSnapshotType = this.props.data;

    if (row.error) {
      if (row.error === "Реквизиты не найдены") {
        return (
          <div style={{ color: "red" }}>
            <p>Реквизиты не найдены</p>
            <p>Добавьте их в [Справочники/Реквизиты]</p>
          </div>
        );
      }
      return row.error;
    }

    if (store.isExcluded(row.id) || row.isIncoimng === null) {
      return "";
    }

    const typeSelect = this.renderTypeSelect();
    let payloadSelect = null;
    let spendingSelect = null;
    let nextPayloadSelect = null;

    let type = this.state.type;

    const dictionary = row.dictionaries[type] ? row.dictionaries[type].value : null;
    const nextDictionary = row.dictionaries[`${type}_Next`] ? row.dictionaries[`${type}_Next`].value : null;

    if (this.payloadOptionRenderer != null && dictionary != null) {
      const dictionaryType = row.dictionaries[type].key;
      const payloadOption = dictionary.find((o) => o.id === this.state.payload || o.id === this.state.tempPayload);

      if (dictionaryType === "Договор") {
        payloadSelect = this.renderProjectSelect(dictionary, payloadOption, this.tempPayloadHandler);
      } else if (dictionaryType === "Платеж") {
        payloadSelect = this.renderFinanceRequestSelect(
          dictionary as any[],
          payloadOption,
          this.payloadOptionRenderer,
          this.tempPayloadHandler
        );
      } else if (dictionaryType === "Проект") {
        payloadSelect = this.renderProjectSelect(dictionary, payloadOption, this.tempPayloadHandler);
        spendingSelect = this.renderOrderSpendingSelect(this.spendingHandler);
      } else {
        payloadSelect = this.renderCommonSelect(dictionary, payloadOption, this.tempPayloadHandler, dictionaryType);
      }
    }
    if (this.nextPayloadOptionRenderer != null && nextDictionary != null && this.state.tempPayload) {
      const dictionaryType = row.dictionaries[`${type}_Next`].key;
      const fileteredDictionary = nextDictionary.filter((d) => d.parent === this.state.tempPayload);
      const payloadOption = fileteredDictionary.find((o) => o.id === this.state.payload);

      if (dictionaryType === "Запрос") {
        nextPayloadSelect = this.renderFinanceRequestSelect(
          fileteredDictionary as any[],
          payloadOption,
          this.nextPayloadOptionRenderer,
          this.payloadHandler,
          dictionaryType
        );
      }
    }
    return (
      <>
        <div style={{ marginTop: "8px", marginBottom: "8px", width: "100%" }}>{typeSelect}</div>
        <div style={{ marginBottom: "8px", width: "100%" }}>{payloadSelect}</div>
        {spendingSelect && this.state.loading && <Loader active />}
        {spendingSelect && !this.state.loading && (
          <div style={{ marginBottom: "8px", width: "100%" }}>{spendingSelect}</div>
        )}
        {nextPayloadSelect && <div style={{ marginBottom: "8px", width: "100%" }}>{nextPayloadSelect}</div>}
      </>
    );
  }

  typeHandler = (item: SelectItem) => {
    const actions = this.props.context as ContextActions;
    const row: ImportedBankRowSnapshotType = this.props.data;

    this.payloadOptionRenderer = row.dictionaries[item.id]
      ? payloadOptionRendererFactory(row.dictionaries[item.id].key, row)
      : null;

    this.nextPayloadOptionRenderer = row.dictionaries[`${item.id}_Next`]
      ? payloadOptionRendererFactory(row.dictionaries[`${item.id}_Next`].key, row, this.payloadClear)
      : null;

    this.setState({ type: item.id, payload: "", tempPayload: "" });

    actions.changeType(this.props.node, item.id);
    actions.changePayload(this.props.node, "");
    actions.changeTempPayload(this.props.node, "");
  };

  payloadHandler = (item: SelectItem) => {
    const actions = this.props.context as ContextActions;
    this.setState({ payload: item.id });
    actions.changePayload(this.props.node, item.id);
  };

  payloadClear = () => {
    const actions = this.props.context as ContextActions;
    this.setState({ payload: this.state.tempPayload });
    actions.changePayload(this.props.node, this.state.tempPayload);
  };

  spendingHandler = (item: SelectItem) => {
    const actions = this.props.context as ContextActions;
    this.setState({ spending: item.id });
    actions.changeSpendingMap(this.props.node, item.id);
  };
  tempPayloadHandler = async (item: SelectItem) => {
    const actions = this.props.context as ContextActions;
    this.setState({ tempPayload: item.id, payload: item.id });
    if (this.state.type === Constants.bankInvoiceRowTypeIncomingCorrection) {
      const result = await this.getSpendings(item.id);
      if (result) {
        this.setState({
          loading: false,
          otherSpendings: result.otherOrderSpendings,
          outsourcedSpendings: result.outsourcedOrderSpendins,
        });

        actions.changeOutsourcedMap(this.props.node, result.outsourcedOrderSpendins);
        actions.changeOtherMap(this.props.node, result.otherOrderSpendings);
        actions.changeLoadMap(this.props.node, false);
      }
    }
    actions.changePayload(this.props.node, item.id);
    actions.changeTempPayload(this.props.node, item.id);
  };

  tempPayloadClear = () => {
    const actions = this.props.context as ContextActions;
    this.setState({ tempPayload: "", payload: "" });
    actions.changePayload(this.props.node, "");
    actions.changeTempPayload(this.props.node, "");
  };

  renderTypeSelect = () => {
    const row: ImportedBankRowSnapshotType = this.props.data;

    const outcomingOption = this.typeOptions.find((o) => o.id === this.state.type);
    const incomingOption = this.incomingOptions.find((o) => o.id === this.state.type);
    const typeSelect = !row.isIncoimng ? (
      <SimpleSelect
        className={`full-width-select ${Classes.FILL}`}
        activeItem={outcomingOption}
        items={this.typeOptions}
        itemRenderer={renderSingleOption}
        onItemSelect={this.typeHandler}
        popoverProps={{
          boundary: "viewport",
          popoverClassName: "import-spendings-table__select import-spendings-table__select_action",
        }}
        inputProps={{
          placeholder: "Расход",
        }}
      >
        <DefaultSelectedOption option={outcomingOption} />
      </SimpleSelect>
    ) : (
      <SimpleSelect
        className={`full-width-select ${Classes.FILL}`}
        activeItem={incomingOption}
        items={this.incomingOptions}
        itemRenderer={renderSingleOption}
        onItemSelect={this.typeHandler}
        popoverProps={{
          boundary: "viewport",
          popoverClassName: "import-spendings-table__select import-spendings-table__select_action",
        }}
        inputProps={{
          placeholder: "Расход",
        }}
      >
        <DefaultSelectedOption option={incomingOption} />
      </SimpleSelect>
    );

    return typeSelect;
  };

  renderProjectSelect = (dictionary: any[], option: any, handler: (item: SelectItem) => void) => {
    if (!this.payloadOptionRenderer) {
      return null;
    }

    return (
      <ProjectSelect
        className={`full-width-select ${Classes.FILL}`}
        filterable={true}
        itemPredicate={filterProject}
        activeItem={option}
        items={dictionary}
        itemRenderer={renderProjectOption}
        onItemSelect={handler}
        disabled={!dictionary.length}
        resetOnClose={true}
        popoverProps={{
          boundary: "viewport",
          popoverClassName: "import-spendings-table__select import-spendings-table__select_action",
        }}
        inputProps={{
          placeholder: "Проект",
        }}
      >
        {this.payloadOptionRenderer(option, !dictionary.length)}
      </ProjectSelect>
    );
  };

  renderCommonSelect = (dictionary: any[], option: any, handler: (item: SelectItem) => void, placeholder: string) => {
    return (
      <CommonSelect
        className={`full-width-select ${Classes.FILL}`}
        filterable={true}
        itemPredicate={filterItemPredicate}
        activeItem={option}
        items={dictionary}
        itemRenderer={renderCommonOption}
        onItemSelect={handler}
        disabled={!dictionary.length}
        popoverProps={{
          boundary: "viewport",
          popoverClassName: "import-spendings-table__select import-spendings-table__select_action",
        }}
        inputProps={{
          placeholder,
        }}
      >
        {this.payloadOptionRenderer(option, !dictionary.length)}
      </CommonSelect>
    );
  };

  renderFinanceRequestSelect(
    dictionary: FinanceRequestStatisticDictionaryDictionaryItemType[],
    option: any,
    renderer: any,
    handler: (item: SelectItem) => void,
    placeholder = "Платеж"
  ) {
    if (!renderer) {
      return null;
    }

    return (
      <FinanceRequestSelect
        className={`full-width-select ${Classes.FILL}`}
        filterable={true}
        itemPredicate={filterFinanceRequestItemPredicate}
        activeItem={option}
        items={dictionary}
        itemRenderer={renderFinanceRequestOption}
        onItemSelect={handler}
        disabled={!dictionary.length}
        resetOnClose={true}
        popoverProps={{
          boundary: "viewport",
          popoverClassName: "import-spendings-table__select import-spendings-table__select_action finance-requests",
        }}
        inputProps={{
          placeholder,
        }}
      >
        {renderer(option, !dictionary.length)}
      </FinanceRequestSelect>
    );
  }

  renderOrderSpendingSelect(handler: (item: SelectItem) => void) {
    const items = [...this.state.otherSpendings, ...this.state.outsourcedSpendings];

    let payments: SpendingPayments[] = [];
    items.forEach((item) => {
      if (item.actualPayments) {
        item.actualPayments.forEach((payment: ActualPaymentType) => {
          const correctionSum = payment.correctionPayments.reduce((accumulator, object) => {
            return accumulator + object.sum;
          }, 0);
          payments.push({
            id: `${item.id} ${payment.guid}`,
            label: `${formatMoney(payment.sum)} ${payment.date} ${
              item.outsourcer ? item.outsourcer.name.slice(0, 26) : ""
            }`,
            spendingId: item.id,
            paymentGuid: payment.guid,
            outsourcer: item.outsourcer ? item.outsourcer.name : "",
            sum: formatMoney(payment.sum),
            spentSum: formatMoney(payment.sum - correctionSum),
            date: payment.date ? payment.date : "",
          });
        });
      }
    });
    const item = payments.find((p) => p.id === this.state.spending);

    return (
      <SimpleSelect
        className={`full-width-select ${Classes.FILL}`}
        activeItem={item}
        items={payments}
        itemRenderer={renderOrderSpendingsOption}
        onItemSelect={handler}
        popoverProps={{
          boundary: "viewport",
          popoverClassName: "import-spendings-table__select",
        }}
        inputProps={{
          placeholder: "Расход",
        }}
        filterable={true}
        itemPredicate={filterItemPredicate}
      >
        <DefaultSelectedOption option={item} />
      </SimpleSelect>
    );
  }
}

const payloadOptionRendererFactory = (type: string, row: ImportedBankRowSnapshotType, onClear?: () => void) => {
  const noData = ["Платеж", "Запрос"].includes(type) ? `Нет запросов на ${formatMoney(row.sum)}` : undefined;

  return (option: StatisticDictionaryItemType | null | undefined, empty = false) => {
    const percents = option && option.percents > 0 ? <span className={styles.percents}>{option.percents}%</span> : null;
    const disabled = empty;
    const text = empty ? noData : option ? option.label || type : type;
    const icon =
      onClear && !empty ? (
        <>
          <GeneralIcon type="general-cross-small" onClick={onClear} style={{ color: "#E31818" }} />
          <GeneralIcon type="general-chevron-down" />
        </>
      ) : (
        <GeneralIcon type="general-chevron-down" />
      );

    return (
      <Button
        onClick={empty ? prevent : undefined}
        disabled={disabled}
        fill={true}
        rightIcon={icon}
        className="selected-option"
        text={text}
      >
        {percents}
      </Button>
    );
  };
};

export const renderCommonOption: ItemRenderer<StatisticDictionaryItemType> = (item, opts) => {
  const { handleClick, modifiers } = opts;
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const text = item.label || texts.undefined;
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.id}
      onClick={handleClick}
      text={text}
      label={item.percents > 0 ? `${item.percents}%` : ""}
    />
  );
};

export const renderProjectOption: ItemRenderer<OrderStatisticDictionaryItemType> = (
  item,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.id}
      onClick={handleClick}
      text={optionLabel(item)}
      label={item.percents > 0 ? `${item.percents}%` : ""}
    />
  );
};

export const renderFinanceRequestOptionFactory = (money = false): ItemRenderer<Fin> => {
  return (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const text = (
      <div className={styles.requestItem}>
        <div className={styles.requestRow}>
          <div className={styles.project}>{item.label}</div>
          <div className={styles.workType}>{item.workType}</div>
        </div>
        <div className={styles.requestRow}>
          <div className={styles.outsourcerName}>{item.outsourcerName}</div>
          <div className={styles.date}>{item.date}</div>
        </div>
      </div>
    );

    const label = money ? formatMoney(item.percents) : item.percents > 0 ? `${item.percents}%` : "";

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        text={text}
        label={label}
      />
    );
  };
};

export const renderOrderSpendingsFactory = (): ItemRenderer<any> => {
  return (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    const text = (
      <div className={styles.requestItem}>
        <div className={styles.requestSpendingRow}>
          <div className={styles.projectSpending}>Платеж: {item.sum}</div>
          <div className={styles.projectSpending}>Факт: {item.spentSum}</div>
        </div>
        <div className={styles.requestSpendingRow}>
          <div className={styles.projectSpending}>{item.date}</div>
        </div>
        <div className={styles.requestSpendingRow}>
          <div className={styles.projectSpending}>{item.outsourcer}</div>
        </div>
      </div>
    );

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        text={text}
        label={""}
      />
    );
  };
};
const renderOrderSpendingsOption = renderOrderSpendingsFactory();
export const filterFinanceRequestItemPredicate: ItemPredicate<Fin> = (query, item, _index, exactMatch) => {
  const text = `${item.label} ${item.workType} ${item.outsourcerName} ${item.date} ${item.percents}`;
  const normalizedTitle = text.toLowerCase();
  const normalizedQuery = query.toLowerCase().trim();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return normalizedTitle.indexOf(normalizedQuery) >= 0;
  }
};

const renderFinanceRequestOption = renderFinanceRequestOptionFactory(false);

class Exclude extends React.PureComponent<ICellRendererParams, { excluded: boolean }> {
  constructor(props: ICellRendererParams) {
    super(props);

    const { store } = props.context as ContextActions;
    const excluded = store.isExcluded(props.node.id ?? "");
    this.state = { excluded };
  }

  render() {
    const { excluded } = this.state;
    const row: ImportedBankRowSnapshotType = this.props.data;

    return row.isIncoimng === null ? null : (
      <GeneralIcon
        type={excluded ? "general-undo" : "general-cross-small"}
        onClick={this.handler}
        style={{ color: "#E31818", cursor: "pointer" }}
      />
      // <Icon
      //     className={`compact-action ${excluded ? "success-text" : "error-text"}`}
      //     icon={excluded ? "undo" : "cross"}
      //     onClick={this.handler}
      //     htmlTitle={excluded ? "Включить" : "Исключить"}
      // />
    );
  }

  handler = () => {
    const { excluded } = this.state;
    const actions = this.props.context as ContextActions;

    this.setState({ excluded: !excluded });
    actions.changeExclude(this.props.node, !excluded);
  };
}

class RowButton extends React.PureComponent<ICellRendererParams> {
  render() {
    const { store } = this.props.context as ContextActions;
    const valid = store.isValid(this.props.node.id ?? "");

    return (
      <PlanrButton type="greenish" disabled={!valid} size="small" onClick={this.handler}>
        {texts.save}
      </PlanrButton>
      // <Button intent={valid ? "success" : "none"} text={texts.save} disabled={!valid} onClick={this.handler} />
    );
  }

  handler = () => {
    const { save } = this.props.context as ContextActions;
    save(this.props.node.id ?? "");
  };
}

export const columnsBuilder = (): ColDef[] => {
  const result: (ColDef | null)[] = [
    {
      suppressMovable: true,
      headerName: "Дата",
      field: fields.dateAsDate,
      width: 85,
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) => params.data[fields.date],
    },
    {
      suppressMovable: true,
      headerName: "Сумма",
      field: fields.sum,
      width: 120,
      sortable: true,
      valueFormatter: gridMoneyFormatter,

      headerClass: "centered",
    },
    {
      suppressMovable: true,
      headerName: "Плательщик",
      field: fields.payer,
      width: 210,
      sortable: true,

      headerClass: "centered",
      cellRendererFramework: (params: ICellRendererParams) => {
        const row: ImportedBankRowType = params.data;
        return row.payerBankDetails.inn === EMPTY_INN ? (
          row.cleanPayer
        ) : (
          <EmployerName
            showTitle={row.cleanPayer.length > 30}
            name={row.cleanPayer}
            position={`РС: ${row.payerBankDetails.account}`}
          />
        );
      },
    },
    {
      suppressMovable: true,
      headerName: "Получатель",
      field: fields.recipient,
      width: 210,
      sortable: true,

      headerClass: "centered",
      cellRendererFramework: (params: ICellRendererParams) => {
        const row: ImportedBankRowType = params.data;
        return row.recipientBankDetails.inn === EMPTY_INN ? (
          row.cleanRecipient
        ) : (
          <EmployerName
            showTitle={row.cleanRecipient.length > 30}
            name={row.cleanRecipient}
            position={`РС: ${row.recipientBankDetails.account}`}
          />
        );
      },
    },
    {
      suppressMovable: true,
      headerName: "Назначение",
      field: fields.description,
      flex: 1,
      width: 400,
      sortable: false,
      headerClass: "centered",
      cellClass: "description",
    },
    {
      suppressMovable: true,
      width: 80,
      headerName: "Доход",
      field: fields.isIncoimng,
      sortable: false,
      headerClass: "centered",
      cellClass: "centered",
      cellRendererFramework: ({ data }: ICellRendererParams) => {
        const row: ImportedBankRowType = data;

        // incoming
        if (row.isIncoimng === true) {
          return <GeneralIcon type="general-check" style={{ color: "#1DD278" }} />;
        }

        // cross-account
        if (row.isIncoimng === null && !row.error) {
          return (
            // <Icon
            //     className="compact-action neuntral-text"
            //     icon="arrows-horizontal"
            //     htmlTitle="Перевод между счетами"
            // />
            <GeneralIcon type="general-shuffle" />
          );
        }

        return null;
      },
    },
    {
      suppressMovable: true,
      width: 360,
      headerName: "",
      field: fields.payload,
      sortable: false,
      headerClass: "centered",
      cellClass: "payload-selector centered",
      cellRendererFramework: RowAction,
    },
    {
      suppressMovable: true,
      width: 30,
      headerName: "",
      field: "*",
      sortable: false,
      headerClass: "centered",
      cellClass: "centered",
      cellRendererFramework: Exclude,
    },
    {
      suppressMovable: true,
      width: 150,
      headerName: "",
      field: "*",
      sortable: false,
      headerClass: "centered",
      cellClass: "centered",
      cellRendererFramework: RowButton,
    },
  ];

  return result.filter(isNotNull);
};

export interface ContextActions {
  changeType: (node: RowNode, value: string) => void;
  changePayload: (node: RowNode, value: string) => void;
  changeTempPayload: (node: RowNode, value: string) => void;
  changeExclude: (node: RowNode, value: boolean) => void;
  changeOutsourcedMap: (node: RowNode, value: OutsourcedOrderSpendingType[]) => void;
  changeOtherMap: (node: RowNode, value: OtherOrderSpendingType[]) => void;
  changeLoadMap: (node: RowNode, value: boolean) => void;
  changeSpendingMap: (node: RowNode, value: string) => void;
  save: (id: string) => void;
  store: ImportSpendingsStoreType;
}
