import React, { useState } from "react";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import styles from "./Block.module.scss";
import { AcceptUnitFile, WorkTypeLinkSnapshotType } from "modules/orders-manage/models/order";
import { DraggableProvided } from "react-beautiful-dnd";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { OutsourcerDictionaryType } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import classNames from "classnames";
import { getDragItemStyle } from "modules/common/services/drag";
import { InputGroup, Popover, Spinner } from "@blueprintjs/core";
import { onlyDigitsInputKeyPress, preventSubmitKeyDown } from "modules/common/services/form/values";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { FileLink } from "modules/common/components/files/FileLink";
import { IpdFileSnapshotType } from "modules/orders-manage/models/order-file";
import { eat } from "modules/common/services/typescript";
import { formatDate } from "modules/common/services/formatting/date";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { texts } from "modules/common/texts";
import { toString } from "modules/common/services/strings";
import { MentorSelectRenderer } from "./Block";

type TUnit = WorkTypeLinkSnapshotType;
const digits = onlyDigitsInputKeyPress();
const RenderRow = (props: RenderRowProps) => {
    const {
        active,
        deactivate,
        onSave,
        toggleOutsource,
        activateHandler,
        saveTempMentor,
        onUpload,
        clearWorkMentor,
        onInput,
        readOnly,
        onRemove,
        employers,
        agents,
        baseUrl,
        work,
        isDragging,
        download,
        draggable,
    } = props;
    const [loading, setLoading] = useState(false);
    const currentEmployer = work && work.mentorId && employers.employee.find((em) => em.id === work.mentorId);
    const currentAgent = work && work.mentorId && agents.outsourcers.find((em) => em.id === work.mentorId);
    const rowOnEdit = active !== null && active.guid === work.guid;
    const savedOutsourcer = work && work.defaultMentor;

    const dragProps = !draggable
        ? {}
        : {
              ...draggable.draggableProps,
              ...draggable.dragHandleProps,
          };

    let savedMentor = currentEmployer
        ? { id: currentEmployer.id, label: currentEmployer.name }
        : {
              id: "",
              label: "Не назначен",
          };

    return (
        <div
            key={work.guid}
            ref={draggable ? draggable.innerRef : undefined}
            {...dragProps}
            style={draggable ? getDragItemStyle(isDragging, draggable.draggableProps.style) : undefined}
            className={classNames({
                [styles.row]: !rowOnEdit,
                [styles.rowedit]: rowOnEdit && active,
            })}
        >
            <div className={styles.rowFields}>
                <div className={styles.iconCellEmpl}>
                    {!!active && rowOnEdit && (
                        <PlanrButton
                            type="neutral"
                            icon="general-undo"
                            size="small"
                            onClick={deactivate}
                            style={{ color: "#e31818" }}
                            title="Отменить изменения"
                        />
                    )}

                    {!rowOnEdit && (
                        <PlanrButton
                            type="neutral"
                            icon={work.outsourced ? "general-checkbox" : "general-zero-checkbox"}
                            size="small"
                            onClick={readOnly ? undefined : toggleOutsource}
                            title="Аутсорс"
                            disabled={readOnly}
                            data-work-guid={work.guid}
                        />
                    )}
                </div>

                {!rowOnEdit && !readOnly && (
                    <div className={styles.iconCell}>
                        <PlanrButton
                            type="neutral"
                            icon="general-edit"
                            size="small"
                            onClick={activateHandler}
                            title="Изменить"
                            data-work-guid={work.guid}
                        />
                    </div>
                )}

                {rowOnEdit && active && !readOnly && (
                    <div className={styles.iconCell}>
                        <PlanrButton
                            type="neutral"
                            icon="general-redo"
                            size="small"
                            onClick={onSave}
                            style={{ color: "#1dd278" }}
                            title="Сохранить"
                        />
                    </div>
                )}

                {!readOnly && (
                    <div className={styles.orderNumber}>
                        {rowOnEdit && active && (
                            <InputGroup
                                type="text"
                                small={true}
                                autoComplete="off"
                                className="planr-default-input"
                                data-lpignore="true"
                                value={active.partNumber}
                                required={true}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    onInput({ ...active, partNumber: e.currentTarget.value });
                                }}
                                onKeyDown={preventSubmitKeyDown}
                            />
                        )}
                        {!rowOnEdit && work.partNumber}
                    </div>
                )}

                <div className={styles.workAndInventory}>
                    {rowOnEdit && active && (
                        <InputGroup
                            type="text"
                            className="planr-default-input"
                            small={true}
                            autoComplete="off"
                            data-lpignore="true"
                            value={active.name}
                            required={true}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                onInput({ ...active, name: e.currentTarget.value });
                            }}
                            onKeyDown={preventSubmitKeyDown}
                        />
                    )}
                    <span className={styles.workName}>{!rowOnEdit && work.name}</span>
                </div>

                <div className={styles.description}>
                    {rowOnEdit && active && (
                        <InputGroup
                            type="text"
                            className="planr-default-input"
                            small={true}
                            autoComplete="off"
                            data-lpignore="true"
                            value={active.description}
                            required={true}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                onInput({ ...active, description: e.currentTarget.value });
                            }}
                            onKeyDown={preventSubmitKeyDown}
                        />
                    )}
                    {!rowOnEdit && work.description}
                </div>

                {rowOnEdit && active && active.stampFile === null && (
                    <div className={styles.markCell}>
                        <PlanRDndUploader
                            onFileSelected={async (file: File) => {
                                const meta = await onUpload(file);
                                if (meta) {
                                    onInput({ ...active, stampFile: meta });
                                }
                            }}
                            accept={AcceptUnitFile}
                            oneRow={true}
                            width={150}
                            noType={true}
                        />
                    </div>
                )}

                {rowOnEdit && active && active.stampFile && active.stampFile.fileId && (
                    <div className={styles.markCell}>
                        <FileLink<IpdFileSnapshotType>
                            baseUrl={baseUrl}
                            file={active.stampFile}
                            onRemove={() => {
                                onInput({ ...active, stampFile: null });
                            }}
                            readOnly={readOnly || !rowOnEdit}
                            style={{ width: "150px", minWidth: "150px" }}
                            textWidth={"100px"}
                        />
                    </div>
                )}

                {!rowOnEdit && (
                    <div className={styles.markCell}>
                        {work.stampFile && !loading && (
                            <Popover
                                usePortal={true}
                                enforceFocus={true}
                                interactionKind="hover"
                                content={
                                    <div className={styles.popup}>
                                        <div>
                                            <FileLink<IpdFileSnapshotType>
                                                baseUrl={baseUrl}
                                                file={work.stampFile}
                                                readOnly={true}
                                                onRemove={eat}
                                                children={
                                                    <PlanrButton type={"secondary"} size="small">
                                                        Просмотр
                                                    </PlanrButton>
                                                }
                                            />
                                        </div>
                                        <div style={{ marginLeft: "7px" }}>
                                            <PlanrButton
                                                size="small"
                                                type={"greenish"}
                                                onClick={async () => {
                                                    if (work.stampFile) {
                                                        setLoading(true);
                                                        await download(work.stampFile?.fileId);
                                                        setLoading(false);
                                                    }
                                                }}
                                            >
                                                Скачать
                                            </PlanrButton>
                                        </div>
                                    </div>
                                }
                            >
                                {/* <GeneralIcon
                                    type="general-document"
                                    style={{ cursor: "pointer", color: "#369CD7", marginLeft: "10px" }}
                                /> */}
                                <PlanrButton
                                    type="neutral"
                                    icon="general-document"
                                    size="small"
                                    style={{ color: "#369CD7" }}
                                />
                            </Popover>
                        )}
                        {work.stampFile && loading && (
                            <div style={{ marginLeft: "20px" }}>
                                <Spinner intent="primary" size={16} />
                            </div>
                        )}
                    </div>
                )}

                {!rowOnEdit && (
                    <div className={styles.selectEmployer}>
                        {!work.outsourced && !readOnly && (
                            <MentorSelectRenderer
                                item={savedMentor}
                                items={employers.employee.filter((e) => !e.fired)}
                                onSelect={(item: SelectItem) => {
                                    saveTempMentor(item, work.guid);
                                }}
                                onClear={() => {
                                    clearWorkMentor(work.guid);
                                }}
                            />
                        )}
                        {!work.outsourced && readOnly && currentEmployer && (
                            <div className={styles.mentorName}>{currentEmployer.name}</div>
                        )}
                        {!work.outsourced && readOnly && !currentEmployer && (
                            <div className={`${styles.mentorLabel} ${styles.mentorName}`}>Не назначен</div>
                        )}
                        {work.outsourced && !currentAgent && savedOutsourcer && (
                            <div className={styles.mentorName}>{savedOutsourcer.name}</div>
                        )}
                        {work.outsourced && !currentAgent && !savedOutsourcer && (
                            <div className={`${styles.mentorLabel} ${styles.mentorName}`}>Не назначен</div>
                        )}
                        {work.outsourced && currentAgent && (
                            <div className={styles.mentorName}>{currentAgent.label}</div>
                        )}
                    </div>
                )}

                <div className={styles.progress}>
                    {rowOnEdit && active && (
                        <InputGroup
                            className="planr-default-input"
                            style={{ fontSize: "14px" }}
                            value={active.progress > 0 ? toString(active.progress) : ""}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                const text = e.currentTarget.value;
                                const progress = text ? parseInt(e.currentTarget.value, 10) : 0;

                                if (!isNaN(progress) && progress >= 0 && progress <= 100) {
                                    onInput({ ...active, progress, lastModified: formatDate(new Date()) });
                                }
                            }}
                            small={true}
                            autoComplete="off"
                            data-lpignore="true"
                            onKeyDown={preventSubmitKeyDown}
                            onKeyPress={digits}
                        />
                    )}
                    {!rowOnEdit && (
                        <span
                            title={work.lastModified ? `Обновлено: ${work.lastModified}` : ""}
                            className={
                                work.progress === 0
                                    ? "percents color-red"
                                    : work.progress < 50
                                    ? "percents color-orange"
                                    : work.progress >= 50 && work.progress < 75
                                    ? "percents color-blue"
                                    : work.progress >= 75 && work.progress < 100
                                    ? "percents color-purple"
                                    : work.progress === 100
                                    ? "percents color-green"
                                    : "progress"
                            }
                        >
                            {work.progress} %
                        </span>
                    )}
                </div>

                {!readOnly && (
                    <div className={styles.iconCell}>
                        <RemoveConfirmation
                            onConfirmed={onRemove}
                            what={(w) => w.name}
                            render={({ confirmRemoving }) => {
                                return (
                                    <PlanrButton
                                        type="neutral"
                                        icon="general-trash"
                                        size="small"
                                        onClick={() => confirmRemoving(work)}
                                        title={texts.remove}
                                    />
                                );
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default RenderRow;

interface RenderRowProps {
    active: TUnit | null;
    deactivate: () => void;
    onSave: () => void;
    onInput: (active: TUnit) => void;
    toggleOutsource: (e: React.MouseEvent<HTMLButtonElement>) => void;
    activateHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
    saveTempMentor: (item: SelectItem, guid: string) => void;
    clearWorkMentor: (guid: string) => void;
    onUpload: (file: File) => Promise<IpdFileSnapshotType | null>;
    work: TUnit;
    isDragging: boolean;
    download: (file: string) => Promise<boolean | undefined>;
    draggable?: DraggableProvided;
    readOnly?: boolean;
    onRemove: (unit: TUnit) => void;
    employers: EmployerDictionaryType;
    agents: OutsourcerDictionaryType;
    baseUrl: string;
}
