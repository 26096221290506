import { Classes, Dialog, InputGroup, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { getFieldLabel } from "modules/common/services/form/fields";
import React from "react";
import { Schema } from "yup";
import { BonusSalaryPercentType, SettingsStoreType, fields } from "../models/settings";
import styles from "./SystemSettings.module.scss";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { v4 } from "uuid";
import { PercentInput } from "modules/common/components/form/PercentInput";
import { texts } from "modules/common/texts";
import { Buttons } from "modules/common/components/form";
import {
  DefaultSelectedOption,
  SimpleSelect,
  filterItemPredicate,
  renderSingleOption,
} from "modules/common/services/form/select";
import { DictionaryLinkType } from "modules/common/models/dictionary-link";

const digits = onlyDigitsInputKeyPress();

class SystemBase extends React.PureComponent<SystemSettingsProps, SystemState> {
  constructor(props: any) {
    super(props);

    this.state = { showdialog: false, showDanger: false, bonusSalaryPercent: [], employersExclude: [] };
  }

  componentDidMount() {
    const { store } = this.props;
    this.setState({ employersExclude: store.model.excludeEmployersBonus });
  }

  render() {
    const {
      schema,
      store,
      showDialog,
      toggleShowDialog,
      sendMailTest,
      topic,
      text,
      emailTo,
      setMail,
      setTopic,
      setMessage,
    } = this.props;
    const { showdialog, showDanger, bonusSalaryPercent, employersExclude } = this.state;
    const items = store.employers.employee;
    const selectedEmployer = null;
    return (
      <div className="columns">
        <div className={styles.columns}>
          <div className={styles.column}>
            <StandardFormInput
              name={fields.administratorPositions}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
              label={
                <span className="planr-input-label-help">
                  {getFieldLabel(fields.administratorPositions, schema, null)} (или несколько через запятую)&nbsp;&nbsp;
                  <Tooltip
                    content="Используется для рассылки уведомлений системным администраторам"
                    position={Position.RIGHT}
                  >
                    <GeneralIcon type="general-question" />
                  </Tooltip>
                </span>
              }
            >
              {({ field }) => (
                <InputGroup
                  {...field}
                  id={field.name}
                  className="planr-default-input"
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.sendEmail}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailPassword}
              schema={schema}
              inline={false}
              label={
                <span className="planr-input-label-help">
                  {getFieldLabel(fields.emailPassword, schema, null)} &nbsp;&nbsp;
                  <Tooltip
                    content={
                      <div>
                        <p>{`Перейдите в настройки почтового аккаунта -> Безопасность.`}</p>
                        <p>Создайте "Пароль для внешних приложений".</p>
                      </div>
                    }
                    position={Position.RIGHT}
                  >
                    <GeneralIcon type="general-question" />
                  </Tooltip>
                </span>
              }
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailServer}
              schema={schema}
              inline={false}
              label={
                <span className="planr-input-label-help">
                  {getFieldLabel(fields.emailServer, schema, null)} &nbsp;&nbsp;
                  <Tooltip
                    content={
                      <div>
                        <p>{`Укажите имя smtp сервера от почтового сервиса (пример: smtp.yandex.ru).`}</p>
                      </div>
                    }
                    position={Position.RIGHT}
                  >
                    <GeneralIcon type="general-question" />
                  </Tooltip>
                </span>
              }
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailPort}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  onKeyPress={digits}
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailReadServer}
              schema={schema}
              inline={false}
              label={
                <span className="planr-input-label-help">
                  {getFieldLabel(fields.emailReadServer, schema, null)} &nbsp;&nbsp;
                  <Tooltip
                    content={
                      <div>
                        <p>{`Укажите имя imap сервера от почтового сервиса (пример: imap.yandex.ru).`}</p>
                      </div>
                    }
                    position={Position.RIGHT}
                  >
                    <GeneralIcon type="general-question" />
                  </Tooltip>
                </span>
              }
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.emailReadPort}
              schema={schema}
              inline={false}
              small={true}
              className="planr-form-input"
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="text"
                  autoComplete="off"
                  onKeyPress={digits}
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.isSsl}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
            >
              {({ field, form }) => (
                <PlanrButton
                  type="neutral"
                  icon={field.value ? "general-checkbox" : "general-zero-checkbox"}
                  onClick={() => {
                    form.setFieldValue(field.name, !field.value);
                    form.setFieldTouched(field.name, true);
                  }}
                  style={{ marginTop: "-5px" }}
                />
              )}
            </StandardFormInput>
            <PlanrButton type="dashed" onClick={toggleShowDialog} size="small" style={{ marginTop: "10px" }}>
              Тест отправки email
            </PlanrButton>

            <Dialog
              title={"Тест отправки электронных писем"}
              isOpen={showDialog}
              isCloseButtonShown={true}
              style={{ width: "600px" }}
              onClose={toggleShowDialog}
              backdropClassName="standard"
            >
              <div className={`${Classes.DIALOG_BODY} ${styles.dialogBody}`}>
                <div className={styles.label}>Email получателя</div>
                <InputGroup
                  className="planr-default-input"
                  value={emailTo}
                  onChange={(e: any) => {
                    setMail(e.currentTarget.value);
                  }}
                  autoComplete="off"
                  data-lpignore="true"
                />
                <div className={styles.label}>Тема письма</div>
                <InputGroup
                  className="planr-default-input"
                  value={topic}
                  onChange={(e: any) => {
                    setTopic(e.currentTarget.value);
                  }}
                  autoComplete="off"
                  data-lpignore="true"
                />
                <div className={styles.label}>Текст письма</div>
                <InputGroup
                  className="planr-default-input"
                  value={text}
                  onChange={(e: any) => {
                    setMessage(e.currentTarget.value);
                  }}
                  autoComplete="off"
                  data-lpignore="true"
                />
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <PlanrButton
                    type="greenish"
                    onClick={async () => {
                      const res = await sendMailTest();
                      if (res) {
                        toggleShowDialog();
                      }
                    }}
                  >
                    Отправить
                  </PlanrButton>
                </div>
              </div>
            </Dialog>
          </div>

          <div className={styles.column} style={{ marginTop: "5px" }}>
            <StandardFormInput
              name={fields.bonusSalaryPercents}
              schema={schema}
              small={true}
              inline={false}
              className={styles.full}
            >
              {({ field, form }) => (
                <>
                  <PlanrButton
                    type="secondary"
                    onClick={() => {
                      this.setState({ bonusSalaryPercent: field.value });
                      this.toggleDialog();
                    }}
                    size="small"
                    style={{ marginBottom: "10px" }}
                  >
                    Доплата за стаж в организации
                  </PlanrButton>
                  <Dialog
                    title={`Доплата за стаж в организации`}
                    isOpen={showdialog}
                    onClose={this.toggleDialog}
                    backdropClassName="standard"
                    style={{ width: "930px" }}
                    className={`${styles.FilesDialog} figma-dialog`}
                  >
                    <div className={`${Classes.DIALOG_BODY} `}>
                      <div className={styles.stageDialog}>
                        <div className={styles.stageDialogColumn}>
                          {bonusSalaryPercent.length > 0 && (
                            <div style={{ display: "flex" }}>
                              <div className={styles.itemRowHeader}>Стаж в годах</div>
                              <div className={styles.itemRowHeader}>Процент от ставки</div>
                            </div>
                          )}
                          {bonusSalaryPercent &&
                            bonusSalaryPercent.map((res) => {
                              return (
                                <div key={res.guid} style={{ display: "flex", marginBottom: "10px" }}>
                                  <div className={styles.itemRow}>
                                    <InputGroup
                                      type="text"
                                      autoComplete="off"
                                      className="planr-default-input"
                                      data-lpignore="true"
                                      value={res.yearsCount.toString()}
                                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        this.setBonuses("yearsCount", e.currentTarget.value, res.guid);
                                      }}
                                    />
                                  </div>
                                  <div className={styles.itemRow}>
                                    <PercentInput
                                      value={res.percentValue.toString()}
                                      className="planr-default-input"
                                      autoComplete="off"
                                      data-lpignore="true"
                                      onKeyPress={digits}
                                      style={{
                                        marginLeft: "10px",
                                      }}
                                      onPercentChange={(p) => {
                                        this.setBonuses(
                                          "percentValue",
                                          p.floatValue ? p.floatValue.toString() : "",
                                          res.guid
                                        );
                                      }}
                                    />
                                  </div>
                                  <PlanrButton
                                    type="neutral"
                                    icon="general-trash"
                                    onClick={() => {
                                      this.removeBonuses(res.guid);
                                    }}
                                    size="small"
                                    style={{ marginLeft: "10px" }}
                                    title={texts.remove}
                                  />
                                </div>
                              );
                            })}
                          <div style={{ marginTop: "6px" }}>
                            <PlanrButton
                              size="small"
                              type="secondary"
                              onClick={this.addBonuses}
                              icon="general-plus-big"
                            >
                              Добавить
                            </PlanrButton>
                          </div>
                          {showDanger && (
                            <div style={{ color: "red", marginTop: "10px", fontSize: "12px" }}>
                              Значения во всех полях должны быть больше 0!
                            </div>
                          )}
                        </div>
                        <div className={styles.stageDialogColumn}>
                          <div className={styles.labelHeader}>
                            Список сотрудников, которым не начислять доплату за стаж
                          </div>
                          <SimpleSelect
                            className={`full-width-select ${Classes.FILL}`}
                            activeItem={selectedEmployer}
                            items={items}
                            itemRenderer={renderSingleOption}
                            onItemSelect={(i: SelectItem) => {
                              if (!employersExclude.find((e) => e.id === i.id)) {
                                let newEmployers = [...employersExclude];
                                newEmployers.push({ id: i.id, name: i.label });
                                this.setState({ employersExclude: newEmployers });
                              }
                            }}
                            popoverProps={{
                              boundary: "viewport",
                              popoverClassName: "import-spendings-table__select",
                            }}
                            inputProps={{
                              placeholder: "Поиск",
                            }}
                            filterable={true}
                            itemPredicate={filterItemPredicate}
                          >
                            <DefaultSelectedOption option={selectedEmployer} />
                          </SimpleSelect>
                          {employersExclude.length > 0 && (
                            <div className={styles.employersList}>
                              {employersExclude.map((employer) => (
                                <div className={styles.employersListItem}>
                                  <div key={employer.id} className={styles.employersListItemName}>
                                    {employer.name}
                                  </div>
                                  <PlanrButton
                                    type="neutral"
                                    icon="general-trash"
                                    size="small"
                                    onClick={() => {
                                      let newEmployers = [...employersExclude];
                                      const index = newEmployers.findIndex((e) => e.id === employer.id);
                                      if (index > -1) {
                                        newEmployers.splice(index, 1);
                                        this.setState({ employersExclude: newEmployers });
                                      }
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                      <Buttons
                        left={
                          <div style={{ display: "flex" }}>
                            <PlanrButton
                              size="small"
                              type="greenish"
                              onClick={() => {
                                let hasError = false;
                                if (bonusSalaryPercent.length > 0) {
                                  bonusSalaryPercent.forEach((element: any) => {
                                    if (element.yearsCount <= 0 || element.percentValue <= 0) {
                                      hasError = true;
                                      this.setState({ showDanger: true });
                                    }
                                  });
                                }
                                if (!hasError) {
                                  form.setFieldValue(field.name, bonusSalaryPercent);
                                  form.setFieldTouched(field.name, true);
                                  form.setFieldValue(fields.excludeEmployersBonus, employersExclude);
                                  form.setFieldTouched(fields.excludeEmployersBonus, true);
                                  this.toggleDialog();
                                }
                              }}
                            >
                              Сохранить
                            </PlanrButton>
                            <PlanrButton size="small" type="graybtn" onClick={this.toggleDialog}>
                              Отмена
                            </PlanrButton>
                          </div>
                        }
                      />
                    </div>
                  </Dialog>
                </>
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.showMinutes}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
            >
              {({ field, form }) => (
                <PlanrButton
                  type="neutral"
                  icon={field.value ? "general-checkbox" : "general-zero-checkbox"}
                  onClick={() => {
                    form.setFieldValue(field.name, !field.value);
                    form.setFieldTouched(field.name, true);
                  }}
                  style={{ marginTop: "-5px" }}
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.prevDaysCount}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
            <StandardFormInput
              name={fields.futureDaysCount}
              schema={schema}
              small={true}
              className="planr-form-input"
              inline={false}
            >
              {({ field }) => (
                <InputGroup
                  id={field.name}
                  className="planr-default-input"
                  {...field}
                  type="number"
                  autoComplete="off"
                  data-lpignore="true"
                />
              )}
            </StandardFormInput>
          </div>
        </div>
      </div>
    );
  }
  toggleDialog = () => {
    this.setState({ showdialog: !this.state.showdialog, showDanger: false });
  };

  setBonuses = (field: string, value: string, guid: string) => {
    const bonuses = [...this.state.bonusSalaryPercent];
    const index = bonuses.findIndex((e) => e.guid === guid);

    if (index > -1) {
      const item = bonuses[index];
      bonuses.splice(index, 1, { ...item, [field]: !isNaN(+value) ? +value : "" });
      this.setState({ bonusSalaryPercent: bonuses });
    }
  };

  removeBonuses = (guid: string) => {
    const bonuses = [...this.state.bonusSalaryPercent];
    const index = bonuses.findIndex((e) => e.guid === guid);
    if (index > -1) {
      bonuses.splice(index, 1);
      this.setState({ bonusSalaryPercent: bonuses });
    }
  };

  addBonuses = () => {
    const bonuses = [...this.state.bonusSalaryPercent];
    bonuses.push({ guid: v4(), yearsCount: 0, percentValue: 0 });
    this.setState({ bonusSalaryPercent: bonuses });
  };
}

export const SystemSettings = observer(SystemBase);
interface SystemState {
  showdialog: boolean;
  showDanger: boolean;
  bonusSalaryPercent: BonusSalaryPercentType[];
  employersExclude: DictionaryLinkType[];
}
interface SystemSettingsProps {
  store: SettingsStoreType;
  schema: Schema<any>;
  showDialog: boolean;
  sendMailTest: () => Promise<boolean>;
  topic: string;
  text: string;
  emailTo: string;
  toggleShowDialog: () => void;
  setMail: (val: string) => void;
  setTopic: (val: string) => void;
  setMessage: (val: string) => void;
}
