import { applySnapshot, getParent, types } from "mobx-state-tree";
import {
  Outsourcer,
  OutsourcerSnapshotType,
  initialState as emptyOutsourcer,
} from "modules/agents/outsourcers/models/outsourcer";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import { MimeTypes } from "modules/common/services/files";
import { formatDate } from "modules/common/services/formatting/date";
import {
  AgentsCategoriesDictionary,
  initialState as emptyCategories,
} from "modules/dictionaries/agents-categories/models/agents-categories-dictionary";
import {
  WorkTypeDictionary,
  initialState as emptyWorkTypes,
} from "modules/dictionaries/work-types/models/work-type-dictionary";
import { EmployerPremiums, initialState as emptyPremiums } from "modules/spending/employee/models/employer-premiums";
export const ACCEPT = [MimeTypes.jpeg, MimeTypes.png, MimeTypes.pdf].join(",");

const SelfOutsourcer = Outsourcer.named("SelfOutsourcer").actions((self) => ({
  load: flow(function* () {
    try {
      const snapshot = yield self.transport.get<OutsourcerSnapshotType>(apiUrls.outsourcers.self());

      applySnapshot(self, snapshot);

      return true;
    } catch (er) {
      self.notify.error(er);

      return false;
    }
  }),

  createUrl() {
    return apiUrls.outsourcers.self();
  },

  updateUrl() {
    return apiUrls.outsourcers.self();
  },
}));
export type SelfOutsourcerType = typeof SelfOutsourcer.Type;

const ExternalUser = types
  .compose(
    Transport,
    Notificator,
    types.model({
      self: SelfOutsourcer,
      workTypes: WorkTypeDictionary,
      categories: AgentsCategoriesDictionary,
      allCountryRegions: types.array(types.string),
    })
  )
  .actions((self) => ({
    load: flow(function* () {
      try {
        yield self.self.load();
        yield self.workTypes.load();
        return true;
      } catch (er) {
        self.notify.error((er as Error).message);
        return false;
      }
    }),
    loadAgentAddInfo: flow(function* () {
      try {
        yield self.categories.load();
        const data: string[] = yield self.transport.get<any>(apiUrls.noAuth.regions);
        applySnapshot(self.allCountryRegions, data);
        return true;
      } catch (er) {
        self.notify.error((er as Error).message);
        return false;
      }
    }),
  }));

export type ExternalUserSnapshotType = typeof ExternalUser.SnapshotType;
export type ExternalUserType = typeof ExternalUser.Type;

export const Session = types
  .compose(
    Transport,
    Notificator,
    types.model({
      userName: types.string,
      userLogin: types.string,
      userAvatar: types.maybeNull(types.string),
      userId: types.string,
      access: types.array(types.string),
      loggedIn: types.boolean,
      manualFile: types.maybeNull(
        types.model({
          fileId: types.string,
          fileName: types.string,
        })
      ),
      agentRequestMenuCount: types.string,
      tgBotLink: types.string,
      authPopupVisible: types.boolean,
      premiums: EmployerPremiums,
      outsourcer: types.maybeNull(ExternalUser),
      agent: types.maybeNull(ExternalUser),
      credentials: types.model({
        login: types.string,
        password: types.string,
      }),
    })
  )
  .views((self) => ({
    get fileLink() {
      if (self.manualFile) {
        return self.baseUrl + apiUrls.application.files.content(self.manualFile.fileId, self.manualFile.fileName);
      } else {
        return "";
      }
    },
  }))
  .actions((self) => ({
    showConfirmationWarning() {
      self.notify.success("Ожидается подтверждение устройства администратором.");
    },

    setAccess(acces: string[]) {
      applySnapshot(self.access, acces);
    },

    loadPremiums() {
      return self.premiums.load(self.userId);
    },

    setTgBotLink(link: string) {
      self.tgBotLink = link;
    },
  }))
  .actions((self) => ({
    newAgent() {
      self.agent = ExternalUser.create({
        self: emptyOutsourcer(true),
        workTypes: emptyWorkTypes(),
        categories: emptyCategories(),
      });
    },
    logIn: flow(function* (model: any) {
      try {
        const meta: AppMetadata = yield self.transport.post<AppMetadata>(apiUrls.auth.login, model);
        const parent = getParent(self);
        if (parent && typeof parent.userLoggedIn === "function") {
          parent.userLoggedIn(meta);
        }

        if (self.authPopupVisible) {
          self.authPopupVisible = false;
        }

        if (!meta.waitConfirmation) {
          self.loggedIn = true;
        } else {
          self.showConfirmationWarning();
        }
        self.agentRequestMenuCount = meta.agentRequestMenuCount;
        self.userName = meta.userName;
        self.userLogin = meta.userLogin;
        self.userId = meta.userId;
        self.userAvatar = meta.userAvatar;
        self.manualFile = meta.manualFile;
        self.setAccess(meta.access);
        self.setTgBotLink(meta.tgBotLink);

        if (meta.isLegalEntity !== null && !self.outsourcer) {
          self.outsourcer = ExternalUser.create({
            self: emptyOutsourcer(meta.isLegalEntity, meta.userId),
            workTypes: emptyWorkTypes(),
            categories: emptyCategories(),
          });
        }

        if (meta.isAgent === true && !self.agent) {
          self.agent = ExternalUser.create({
            self: emptyOutsourcer(meta.isLegalEntity ? meta.isLegalEntity : false, meta.userId),
            workTypes: emptyWorkTypes(),
            categories: emptyCategories(),
          });
        }

        return true;
      } catch (er) {
        if (self.loggedIn) {
          self.authPopupVisible = true;
        }

        self.notify.error((er as Error).message);
        return false;
      }
    }),
    // loadShortSettings: flow(function* () {
    //   try {
    //     const snapshot = yield self.transport.get<any>(apiUrls.settings.shortSettings);

    //     return true;
    //   } catch (er) {
    //     self.notify.error(er);
    //     return false;
    //   }
    // }),
    logOut: flow(function* () {
      try {
        yield self.transport.post<boolean>(apiUrls.auth.logout);
        self.loggedIn = false;
        self.outsourcer = null;
        self.agent = null;
        return true;
      } catch (er) {
        self.notify.error((er as Error).message);
        return false;
      }
    }),
    saveAgent: flow(function* (model: any, isLegal: boolean, selfEmployed: boolean, categories: string[]) {
      try {
        const body: any = model;
        body["categories"] = categories;
        body["documentIds"] = model.documents ? model.documents.map((d: any) => d.fileId) : [];
        delete body["documents"];
        body["type"] = "agent";
        body["isAgent"] = true;
        body["isLegal"] = isLegal;

        body["selfEmployed"] = selfEmployed;
        body["supplierDocumentIds"] = model.supplierDocuments ? model.supplierDocuments.map((d: any) => d.fileId) : [];
        delete body["supplierDocuments"];

        body["materialDocumentIds"] = model.materialDocuments ? model.materialDocuments.map((d: any) => d.fileId) : [];
        delete body["materialDocuments"];

        body["bankDetails"] = {
          account: model["account"],
          address: model["address"],
          bankIdentificator: model["bankIdentificator"],
          bankName: model["bankName"],
          corrAccount: model["corrAccount"],
          directorName: model["directorName"],
          directorNameGenitive: model["directorNameGenitive"],
          email: model["email"],
          guid: model["guid"],
          inn: model["inn"],
          kpp: model["kpp"],
          ogrn: model["ogrn"],
          okpo: model["okpo"],
        };
        if (model["issueDate"] instanceof Date) {
          model["issueDate"] = formatDate(model["issueDate"]);
        }

        body["passport"] = {
          issueDate: model["issueDate"],
          issuer: model["issuer"],
          issuerCode: model["issuerCode"],
          number: model["number"],
          series: model["series"],
          regAddress: model["regAddress"],
        };
        if (body["birthday"] instanceof Date) {
          body["birthday"] = formatDate(body["birthday"]);
        }
        if (body["selfEmployedAccountDate"] instanceof Date) {
          body["selfEmployedAccountDate"] = formatDate(body["selfEmployedAccountDate"]);
        }
        const snapshot: any = yield self.transport.put<OutsourcerSnapshotType>(apiUrls.noAuth.create(), body);
        if (snapshot) {
          self.notify.success("Заявка на одобрение успешно отправлена!");
        }
        return true;
      } catch (er) {
        self.notify.error((er as Error).message);
        return false;
      }
    }),
    uploadFile: flow(function* (file: File) {
      try {
        const model = new FormData();

        model.append("file", file);
        model.append("accept", ACCEPT);

        const result: UploadFileResult = yield self.transport.post<any>(apiUrls.noAuth.upload, model);
        const { id, previewMimeType, mimeType } = result;

        const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
        return fileBase;
      } catch (er) {
        self.notify.error(er);
        return null;
      }
    }),
    updateFile: flow(function* () {
      try {
        const meta: AppMetadata = yield self.transport.get<AppMetadata>(apiUrls.application.metadata);
        self.manualFile = meta.manualFile;
        self.setAccess(meta.access);
        return true;
      } catch (er) {
        self.notify.error((er as Error).message);
        return false;
      }
    }),
    updateUser(data: string[]) {
      if (data.length > 1) {
        self.userAvatar = data[0];
        self.userName = data[1];
        self.userLogin = data[2];
      }
    },
    updateRequestAgentsCount(num: number) {
      if (num > 0) {
        if (num >= 100) {
          self.agentRequestMenuCount = "99+";
        } else {
          self.agentRequestMenuCount = String(num);
        }
      } else {
        self.agentRequestMenuCount = "";
      }
    },
    setPopupStaste(visible: boolean) {
      self.authPopupVisible = visible;
    },

    setLoggedInState(value: boolean) {
      self.loggedIn = value;
    },

    tgRegister: flow(function* (token: string) {
      try {
        const [chatId, userId] = token ? token.split(":") : ["", ""];
        if (!chatId) {
          return "Неверно переданы параметры регистрации";
        }

        const chat = parseInt(chatId, 10);

        yield self.transport.post(apiUrls.employee.bot.register, {
          chatId: chat,
          userId,
        });

        self.setTgBotLink("");

        return "";
      } catch (er) {
        const text = typeof er === "string" ? er : (er as any).message;
        self.notify.error(er);
        return text;
      }
    }),
  }))
  .named("Token");

export type SessionSnapshotType = typeof Session.SnapshotType;
export type SessionType = typeof Session.Type;

export const initialState = (meta: AppMetadata): SessionSnapshotType => {
  var loggedIn = !!meta.userName && !meta.waitConfirmation;

  return {
    userId: meta.userId,
    userName: meta.userName,
    userLogin: meta.userLogin,
    userAvatar: meta.userAvatar,
    access: meta.access,
    loggedIn,
    authPopupVisible: false,
    premiums: emptyPremiums(),
    manualFile: meta.manualFile,
    tgBotLink: meta.tgBotLink,
    agentRequestMenuCount: meta.agentRequestMenuCount,
    agent:
      loggedIn && meta.isAgent === true
        ? {
            self: emptyOutsourcer(meta.isLegalEntity ? meta.isLegalEntity : false, meta.userId),
            workTypes: emptyWorkTypes(),
            categories: emptyCategories(),
            allCountryRegions: [],
          }
        : null,
    outsourcer:
      loggedIn && meta.isLegalEntity !== null
        ? {
            self: emptyOutsourcer(meta.isLegalEntity, meta.userId),
            workTypes: emptyWorkTypes(),
            categories: emptyCategories(),
            allCountryRegions: [],
          }
        : null,
    credentials: meta.credentials,
  };
};
