import React from "react";
import { observer } from "mobx-react";
import OrderList from "./OrderList";
import { getSelectedOrderId } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { UnregisterCallback } from "history";
import styles from "./OrderListWrapper.module.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { OrderListType, OrderListSnapshotType } from "../models/order-list";
import { IDisposer, onSnapshot } from "mobx-state-tree";
import { canReadOrder } from "../functionalities";
import { can } from "modules/session/auth/access";
import { routes } from "modules/common/routes";
import { ClientFunctionalities } from "modules/agents/clients/models/client";

class Wrapper extends React.Component<OrderListWrapperProps & RouteComponentProps, OrderListState> {
  private list = React.createRef<HTMLDivElement>();
  private disposer: IDisposer;
  private unregister: UnregisterCallback | null = null;

  constructor(props: OrderListWrapperProps & RouteComponentProps) {
    super(props);

    this.state = {
      orderId: getSelectedOrderId(props.location.pathname),
    };

    this.disposer = onSnapshot(props.store, this.hadleVisibility);
  }

  componentDidMount() {
    const { history, store } = this.props;

    this.unregister = history.listen((location) => {
      const orderId = getSelectedOrderId(location.pathname);
      this.setState({ orderId });
      store.toggleVisibility(false);
    });
  }

  componentWillUnmount() {
    this.unregister && this.unregister();
    this.disposer();
  }

  hadleVisibility = (snapshot: OrderListSnapshotType) => {
    if (snapshot.visible && this.list.current) {
      const element: HTMLInputElement | null = this.list.current.querySelector(".order-list-filter input");
      element && element.focus();
    }
  };

  render() {
    const { store, access } = this.props;

    const style: React.CSSProperties = {
      width: store.visible ? "320px" : "0px",
      // opacity: store.visible ? 1 : 0,
    };

    return (
      <div className={`order-list-wrapper ${styles.wrapper}`} ref={this.list}>
        <OrderList
          canRead={canReadOrder(access)}
          orderRoute={
            can(ClientFunctionalities.CLIENTS_USER_ACCESS, access) ? routes.orders.workResult : routes.orders.details
          }
          onOrderSelected={store.setLastestProjectId}
          selectedOrder={this.state.orderId}
          store={store}
          style={style}
        />
      </div>
    );
  }
}

export const OrderListWrapper = withRouter(observer(Wrapper));

interface OrderListState {
  orderId: string;
}

interface OrderListWrapperProps {
  store: OrderListType;
  access: string[];
}
