import React from "react";
import styles from "./Dashboard.module.scss";
import { Page } from "modules/common/components/page/Page";
import { MainChart } from "./main/MainChart";
import { SimpleChart, CollapsedChart } from "./SimpleChart";
import { EngineersChart } from "./engineers/EngineersChart";
import { ValueAxis, CircleBullet } from "@amcharts/amcharts4/charts";
import { ObjectCountCases, StatusesBlock } from "./blocks/Statuses";
import { Caret } from "modules/common/components/collapse/Caret";
import { DetailedOrderListType } from "modules/main/models/detailed-order";
import { MoneyAccountStoreType } from "modules/main/models/money-accounts";
import { AccountsBlock } from "./blocks/Accounts";
import { ComposedChart } from "./composed/ComposedChart";
import {
    DashboardStoreType,
    Charts,
    ComposedMoney,
    ComposedWorkingOut,
    defaultCollapse,
} from "modules/main/models/dashboard-store";
import { routes } from "modules/common/routes";
import { openInNewTab } from "modules/common/services/communication/http";
import { parsePeriod } from "modules/common/services/strings";
import { formatDate } from "modules/common/services/formatting/date";
import { YEAR_SECTION } from "modules/expenses/overhead/components/OverheadSpendings";
import { Constants } from "modules/root/models/constants";
import { Classes, Collapse, Dialog, Spinner } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { prettyRound } from "modules/common/services/formatting/numbers";
import { Money, formatMoney } from "modules/common/components/money/Money";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import {
    END_SECTION,
    INCOMINTG_SECTION,
    PRODUCTION_SECTION,
    QUERY_SECTION,
    START_SECTION,
} from "modules/expenses/summary/components/filter-params";
import { ChartPeriodSwitcher } from "./ChartPeriodSwitcher";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { htmlToElement, getCleanPageLayout } from "modules/common/services/app";
import RGL, { Layout, WidthProvider } from "react-grid-layout";
import { OrderAgentsListPage } from "../order-agents-list/OrderAgentsList";

const defaultLayout = [
    {
        w: 7,
        h: 1,
        x: 0,
        y: 0,
        maxH: 1,
        minH: 1,
        maxW: 7,
        minW: 7,
        i: "1",
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 8,
        x: 0,
        y: 2,
        maxH: 8,
        minH: 8,
        maxW: 7,
        minW: 7,
        i: "2",
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 4,
        x: 0,
        y: 11,
        maxH: 4,
        minH: 4,
        maxW: 7,
        minW: 7,
        i: "3",
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 1,
        x: 0,
        y: 13,
        maxW: 7,
        minW: 7,
        maxH: 1,
        minH: 1,
        i: "4",
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 1,
        x: 0,
        y: 14,
        maxW: 7,
        maxH: 1,
        minH: 1,
        minW: 7,
        i: "5",
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 1,
        x: 0,
        y: 15,
        maxW: 7,
        minW: 7,
        maxH: 1,
        minH: 1,
        i: "6",
        moved: false,
        static: false,
    },
    {
        w: 11,
        h: 16,
        x: 7,
        y: 0,
        i: "7",
        maxH: 16,
        minH: 16,
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 1,
        x: 18,
        y: 0,
        maxH: 1,
        minH: 1,
        maxW: 7,
        minW: 7,
        i: "8",
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 8,
        x: 18,
        y: 2,
        maxH: 8,
        minH: 8,
        maxW: 7,
        minW: 7,
        i: "9",
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 1,
        x: 18,
        y: 10,
        maxH: 1,
        minH: 1,
        maxW: 7,
        minW: 7,
        i: "10",
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 1,
        x: 18,
        y: 16,
        maxH: 1,
        minH: 1,
        maxW: 7,
        minW: 7,
        i: "11",
        moved: false,
        static: false,
    },
    {
        w: 7,
        h: 1,
        x: 18,
        y: 17,
        maxH: 1,
        minH: 1,
        maxW: 7,
        minW: 7,
        i: "12",
        moved: false,
        static: false,
    },
];
const GridLayout = WidthProvider(RGL);
const MAXWIDTH = 396;

function getFromLS(key: any) {
    let ls: any = {};
    let local = localStorage.getItem("rgl");
    try {
        if (local) {
            ls = JSON.parse(local);
        } else {
            ls = {};
        }
    } catch (e) {
        /*Ignore*/
    }

    return ls[key];
}

function getToggleDashboard() {
    let ls: any = {};
    let local = localStorage.getItem("toggleDashboard");
    try {
        if (local) {
            ls = JSON.parse(local);
        } else {
            ls = {};
        }
    } catch (e) {
        /*Ignore*/
    }
    return ls;
}

function saveToLS(key: any, value: any) {
    localStorage.setItem(
        "rgl",
        JSON.stringify({
            [key]: value,
        })
    );
}

function setChartWidth(html: Element | null, width: number) {
    if (!html) {
        return "";
    }

    const copy = htmlToElement(html.outerHTML);
    if (!copy) {
        return "";
    }

    copy.style.width = "auto";
    copy.style.maxWidth = `${width}px`;
    copy.style.minWidth = `${width}px`;

    return copy.outerHTML;
}

function setChartHeight(html: Element | null, height: string) {
    if (!html) {
        return "";
    }

    const copy = htmlToElement(html.outerHTML);
    if (!copy) {
        return "";
    }

    copy.style.height = height;
    copy.style.maxHeight = "";

    return copy.outerHTML;
}

class DashboardBase extends React.Component<DashboardProps, DashboardState> {
    private overheadSummary: string = "";
    private statusesSumary: string = "";
    private profitabilitySummary: string = "";
    private accountsSummary: string = "";
    private employeeSummary: string = "";
    private companyAgeSummary: string = "";
    private salarySummary: string = "";

    constructor(props: DashboardProps) {
        super(props);

        this.state = { printing: false, layout: defaultLayout, showAgents: false, loadingAgents: false };
    }
    componentWillMount() {
        const newLayout = getFromLS("layout");
        newLayout && this.setState({ layout: newLayout });
    }

    componentDidMount() {
        this.props.table.load();
        const toggles = getToggleDashboard();
        for (let key in toggles) {
            this.props.dashboard.collapser.set(key, toggles[key]);
        }
    }

    componentDidUpdate() {
        saveToLS("layout", this.state.layout);
    }

    printChart = async () => {
        this.setState({ printing: true });

        try {
            const layout = await getCleanPageLayout();

            const charts = [
                {
                    svg: {
                        svg: document.querySelector("#composedMoneyChart .collapse svg")?.outerHTML,
                        width: 380,
                    },
                    name: "Финансы",
                    summary: "",
                    sortOrder: 1,
                },
                {
                    svg: {
                        svg: document.querySelector("#overheadSpendingsChart .collapse svg")?.outerHTML,
                        width: 380,
                    },
                    name: "Накладные расходы",
                    summary: this.overheadSummary,
                    sortOrder: 2,
                },
                {
                    svg: {
                        svg: document.querySelector("#composedWorkingOutChart .collapse svg")?.outerHTML,
                        width: 380,
                    },
                    name: "Выработка",
                    summary: "",
                    sortOrder: 3,
                },
                {
                    svg: {
                        svg: document.querySelector("#profitabilityChart .collapse svg")?.outerHTML,
                        width: 380,
                    },
                    name: "Рентабельность",
                    summary: this.profitabilitySummary,
                    sortOrder: 4,
                },
                {
                    html: {
                        html: setChartWidth(document.querySelector("#accountsChart .accounts-value"), MAXWIDTH),
                        width: MAXWIDTH,
                        layout,
                    },
                    name: "Счета",
                    summary: this.accountsSummary,
                    sortOrder: 5,
                },
                {
                    svg: {
                        svg: document.querySelector("#employeeChart .collapse-wide svg")?.outerHTML,
                        width: MAXWIDTH,
                    },
                    name: "Сотрудники",
                    summary: this.employeeSummary,
                    sortOrder: 6,
                },
                {
                    svg: {
                        svg: document.querySelector("#companyAgeChart .collapse-wide svg")?.outerHTML,
                        width: MAXWIDTH,
                    },
                    name: "Возраст организации",
                    summary: this.companyAgeSummary,
                    sortOrder: 7,
                },
                {
                    html: {
                        html: document.querySelector(".orderStatuses .statuses-widget")?.outerHTML,
                        width: MAXWIDTH,
                        layout,
                    },
                    name: "Статусы",
                    summary: this.statusesSumary,
                    sortOrder: 8,
                },
                {
                    svg: {
                        svg: document.querySelector("#salaryChart .collapse-wide svg")?.outerHTML,
                        width: MAXWIDTH,
                    },
                    name: "Средняя заработная плата",
                    summary: this.salarySummary,
                    sortOrder: 9,
                },
                {
                    html: {
                        html: setChartHeight(document.querySelector(".engineersChart .engineersStripes"), "auto"),
                        width: MAXWIDTH,
                        layout,
                    },
                    name: "Загруженность ГИПов",
                    summary: "",
                    sortOrder: 10,
                },
                {
                    svg: {
                        svg: document.querySelector("#ordersChart svg")?.outerHTML,
                        width: 723,
                        fit: true,
                    },
                    name: "Flow",
                    summary: "",
                    sortOrder: 11,
                },
            ].filter((c) => c.html?.html || c.svg?.svg);

            if (charts.length) {
                await this.props.dashboard.print(charts);
            } else {
                this.props.dashboard.notify.error("Нет данных для печати");
            }
        } finally {
            this.setState({ printing: false });
        }
    };

    onLayoutChange = (newLayout: Layout[] | undefined) => {
        this.setState({ layout: newLayout });
    };

    render() {
        const { dashboard, accounts, table, onRefresh } = this.props;
        let sum = 0;
        let accountsSum = 0; //kredit
        let receivablesSum = 0; // debit
        table.orders.forEach((order) => {
            if (order.todayProfitability && order.todayProfitability < 0) {
                sum += order.todayProfitability;
            }
            if (order.paymentSum > order.completions) {
                accountsSum += order.paymentSum - order.completions;
            } else {
                receivablesSum += order.completions - order.paymentSum;
            }
        });
        sum = Math.abs(sum);

        const objectCount = dashboard.ordersStateChart.sum;
        const orderCaseType = objectCount.toString().slice(-1);
        const orderCaseName = ObjectCountCases[orderCaseType];

        this.statusesSumary = `Всего: ${objectCount} ${orderCaseName}`;
        this.accountsSummary = `Долг: ${formatMoney(table.totalCredit)}`;
        this.employeeSummary = `Всего: ${dashboard.employeeChart.last}`;
        this.companyAgeSummary = `В среднем: ${Math.round(dashboard.companyAgeChart.last)} лет`;
        this.salarySummary = formatMoney(dashboard.salaryChart.last);

        return (
            <Page className={`${styles.page}`}>
                <Dialog
                    title="Агенты"
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    isOpen={this.state.showAgents}
                    onClose={this.toggleShowAgents}
                    style={{
                        width: `1750px`,
                        height: "860px",
                    }}
                    backdropClassName="standard"
                    className={`figma-dialog`}
                >
                    <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                        <OrderAgentsListPage store={dashboard.orderAgents} />
                    </div>
                </Dialog>
                <GridLayout
                    className="layout"
                    style={{ width: "1555px" }}
                    isResizable={true}
                    cols={25}
                    rowHeight={46}
                    layout={this.state.layout}
                    onLayoutChange={this.onLayoutChange}
                >
                    <div key="1">
                        <div style={{ marginBottom: "10px", display: "flex" }}>
                            <PrintButton loading={this.state.printing} onClick={this.printChart} />
                            {onRefresh && (
                                <PlanrButton type="lightblueish" size="small" onClick={onRefresh}>
                                    Обновить
                                </PlanrButton>
                            )}
                            <PlanrButton type="secondary" onClick={this.resetLayout} size="small">
                                Сброс
                            </PlanrButton>
                            <PlanrButton
                                type="secondary"
                                onClick={this.toggleShowAgents}
                                size="small"
                                style={{ marginLeft: "48px" }}
                            >
                                Агенты
                            </PlanrButton>
                        </div>
                    </div>
                    <div key="2">
                        <div className={`${styles.chartBlockCollapse} orderStatuses`}>
                            <h1
                                className="planr-block-header collapser"
                                onClick={this.onToggleCollapseStatuses}
                                style={{ marginTop: "-0px" }}
                            >
                                Статусы
                                <Caret
                                    collapsed={!dashboard.collapser.opened.get(Charts.orders)}
                                    style={{ zIndex: 1 }}
                                />
                                <PlanrTag uppercase={true}>{this.statusesSumary}</PlanrTag>
                            </h1>
                            <div className={!dashboard.collapser.opened.get(Charts.orders) ? "fakeCollapse" : ""}>
                                <div className="collapse">
                                    <StatusesBlock
                                        width={MAXWIDTH}
                                        store={dashboard.ordersStateChart}
                                        statuses={table.statuses}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div key="3">
                        <div className={`${styles.chartBlockCollapse} engineersChart`}>
                            <h1
                                className="planr-block-header collapser"
                                onClick={this.onToggleCollapseEngineers}
                                style={{ marginTop: "0px" }}
                            >
                                Загруженность ГИПов
                                <Caret collapsed={!dashboard.collapser.opened.get(Charts.engineers)} />
                            </h1>
                            <div className={!dashboard.collapser.opened.get(Charts.engineers) ? "fakeCollapse" : ""}>
                                <div className="collapse">
                                    <EngineersChart
                                        store={dashboard.engineersChart}
                                        title=""
                                        width={MAXWIDTH}
                                        height={145}
                                        color="#000"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div key="4">
                        <div className={styles.chartBlockCollapse} id="employeeChart">
                            <CollapsedChart
                                store={dashboard.employeeChart}
                                title="Сотрудники"
                                collapseToggle={this.onToggleCollapseEmployee}
                                color="#369CD7"
                                width={MAXWIDTH}
                                height={202}
                                maxData={7}
                                collapser={dashboard.collapser}
                                name={Charts.employee}
                                bulleter={standardBulleter}
                                summary={<span>{this.employeeSummary}</span>}
                                renderChild={(p) => <SimpleChart {...p} />}
                            />
                        </div>
                    </div>
                    <div key="5">
                        <div className={styles.chartBlockCollapse} id="companyAgeChart">
                            <CollapsedChart
                                store={dashboard.companyAgeChart}
                                collapseToggle={this.onToggleCollapseCompanyAge}
                                title="Возраст организации"
                                color="#369CD7"
                                maxData={5}
                                width={MAXWIDTH}
                                height={202}
                                graduator={ageGraduator}
                                collapser={dashboard.collapser}
                                name={Charts.companyAge}
                                formatter="#,###."
                                bulleter={standardBulleter}
                                legendRotation={0}
                                summary={<span>{this.companyAgeSummary}</span>}
                                renderChild={(p) => <SimpleChart {...p} />}
                            />
                        </div>
                    </div>
                    <div key="6">
                        <div className={styles.chartBlockCollapse} id="salaryChart">
                            <CollapsedChart
                                collapseToggle={this.onToggleSalaryChart}
                                store={dashboard.salaryChart}
                                title="Средняя заработная плата"
                                color="#369CD7"
                                maxData={7}
                                formatter="#,###. ₽"
                                width={MAXWIDTH}
                                height={257}
                                collapser={dashboard.collapser}
                                name={Charts.salary}
                                bulleter={standardBulleter}
                                graduator={salaryGraduator}
                                summary={<Money amount={dashboard.salaryChart.last} />}
                                renderChild={(p) => <SimpleChart {...p} />}
                            />
                        </div>
                    </div>
                    <div key="7">
                        <div className={styles.column}>
                            <div className={styles.row} id="ordersChart">
                                <div className={styles.mainChartBlock}>
                                    <MainChart
                                        store={dashboard.ordersChart}
                                        graduator={mainChartGraduator}
                                        height={860}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div key="8">
                        <div className={styles.chartBlockCollapse} id="accountsChart">
                            <h1
                                className="planr-block-header collapser"
                                onClick={this.onToggleCollapseAccounts}
                                style={{ marginTop: "0px" }}
                            >
                                Счета
                                <Caret collapsed={!dashboard.collapser.opened.get(Charts.accounts)} />
                                <PlanrTag uppercase={true}>
                                    Долг: <Money amount={table.totalCredit} className={styles.credit} />
                                </PlanrTag>
                            </h1>

                            <Collapse
                                isOpen={!!dashboard.collapser.opened.get(Charts.accounts)}
                                keepChildrenMounted={true}
                            >
                                <div className="collapse">
                                    <AccountsBlock
                                        width={MAXWIDTH}
                                        store={accounts}
                                        sum={sum}
                                        accountsSum={accountsSum}
                                        receivablesSum={receivablesSum}
                                    />
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <div key="9">{this.financeChart()}</div>
                    <div key="10"> {this.overheadChart()}</div>
                    <div key="11">{this.profitabilityChart()}</div>
                    <div key="12">{this.workingOutChart()}</div>
                </GridLayout>
            </Page>
        );
    }

    financeChart = () => {
        const { composedMoneyChart, collapser } = this.props.dashboard;
        const collapsed = !collapser.opened.get(Charts.composed);

        return (
            <div className={styles.chartBlockCollapse} id="composedMoneyChart">
                <h1
                    className="planr-block-header collapser"
                    onClick={this.onToggleCollapseComposed}
                    style={{ marginTop: "0px" }}
                >
                    Финансы
                    {!collapsed && (
                        <ChartPeriodSwitcher
                            period={composedMoneyChart.period}
                            onChange={composedMoneyChart.setPeriod}
                        />
                    )}
                    <Caret collapsed={collapsed} />
                </h1>

                <div className={collapsed ? "fakeCollapse" : ""}>
                    <div className="collapse">
                        <ComposedChart
                            store={composedMoneyChart}
                            colors={[
                                ComposedMoney.overhead.color,
                                ComposedMoney.income.color,
                                ComposedMoney.dividends.color,
                                ComposedMoney.production.color,
                            ]}
                            title=""
                            width={MAXWIDTH}
                            height={370}
                            maxData={8}
                            formatter="#,###. ₽"
                            graduator={bigMoneyGraduator}
                            navigator={this.navigator}
                            bulleter={standardBulleter}
                        />
                    </div>
                </div>
            </div>
        );
    };

    overheadChart = () => {
        const { overheadSpendingsRelativeChart, collapser } = this.props.dashboard;
        const collapsed = !collapser.opened.get(Charts.overheadSpendings);

        this.overheadSummary = `${prettyRound(overheadSpendingsRelativeChart.summary)}%`;
        const currentValue = `${prettyRound(overheadSpendingsRelativeChart.current)}%`;

        return (
            <div className={styles.chartBlockCollapse} id="overheadSpendingsChart">
                <h1
                    className="planr-block-header collapser"
                    onClick={this.onToggleCollapseOverhead}
                    style={{ marginTop: "0px" }}
                >
                    Накладные расходы
                    {collapsed && (
                        <div className={styles.overheadSummary}>
                            <div title="Прошлый квартал">{this.overheadSummary}</div>
                            <div title="Текущий квартал" className={styles.active}>
                                {currentValue}
                            </div>
                        </div>
                    )}
                    {!collapsed && (
                        <ChartPeriodSwitcher
                            period={overheadSpendingsRelativeChart.period}
                            onChange={overheadSpendingsRelativeChart.setPeriod}
                        />
                    )}
                    <Caret collapsed={collapsed} />
                </h1>

                <div className={collapsed ? "fakeCollapse" : ""}>
                    <div className="collapse">
                        <SimpleChart
                            store={overheadSpendingsRelativeChart}
                            color="#EA561E"
                            title=""
                            width={MAXWIDTH}
                            height={257}
                            maxData={10}
                            legendRotation={270}
                            postfix="%"
                            bulleter={standardBulleter}
                            graduator={overheadRelativeGraduator}
                        />
                    </div>
                </div>
            </div>
        );
    };

    workingOutChart = () => {
        const { composedWorkingOutChart, collapser } = this.props.dashboard;
        const collapsed = !collapser.opened.get(Charts.workingOut);

        return (
            <div className={styles.chartBlockCollapse} id="composedWorkingOutChart">
                <h1
                    className="planr-block-header collapser"
                    onClick={this.onToggleCollapseWorkingOut}
                    style={{ marginTop: "0px" }}
                >
                    Выработка
                    {!collapsed && (
                        <ChartPeriodSwitcher
                            period={composedWorkingOutChart.period}
                            onChange={composedWorkingOutChart.setPeriod}
                        />
                    )}
                    <Caret collapsed={collapsed} />
                </h1>

                <div className={collapsed ? "fakeCollapse" : ""}>
                    <div className="collapse">
                        <ComposedChart
                            store={composedWorkingOutChart}
                            colors={[ComposedWorkingOut.workingOut.color, ComposedWorkingOut.income.color]}
                            title=""
                            width={MAXWIDTH}
                            height={370}
                            maxData={8}
                            formatter="#,###. ₽"
                            graduator={mediumMoneyGraduator}
                            bulleter={standardBulleter}
                        />
                    </div>
                </div>
            </div>
        );
    };

    profitabilityChart = () => {
        const { profitabilityChart, collapser } = this.props.dashboard;
        const collapsed = !collapser.opened.get(Charts.profitability);

        this.profitabilitySummary = `${prettyRound(profitabilityChart.summary)}%`;

        return (
            <div className={styles.chartBlockCollapse} id="profitabilityChart">
                <h1
                    className="planr-block-header collapser"
                    onClick={this.onToggleCollapseProfitability}
                    style={{ marginTop: "0px" }}
                >
                    Рентабельность
                    {collapsed && (
                        <PlanrTag>
                            <span>{this.profitabilitySummary}</span>
                        </PlanrTag>
                    )}
                    {!collapsed && (
                        <ChartPeriodSwitcher
                            period={profitabilityChart.period}
                            onChange={profitabilityChart.setPeriod}
                        />
                    )}
                    <Caret collapsed={collapsed} />
                </h1>

                <div className={collapsed ? "fakeCollapse" : ""}>
                    <div className="collapse">
                        <SimpleChart
                            store={profitabilityChart}
                            color="#1DD278"
                            title=""
                            width={MAXWIDTH}
                            height={258}
                            maxData={10}
                            legendRotation={270}
                            postfix="%"
                            bulleter={standardBulleter}
                            graduator={profitabilityGraduator}
                        />
                    </div>
                </div>
            </div>
        );
    };

    navigator = (chart: string, category: string) => {
        if (chart === ComposedMoney.income.title) {
            const period = parsePeriod(category);
            if (period) {
                let url = routes.expenses.path + `#${INCOMINTG_SECTION}=true`;
                url += `&${PRODUCTION_SECTION}=`;
                url += `&${QUERY_SECTION}=`;
                url += `&${START_SECTION}=${formatDate(period[0])}&${END_SECTION}=${formatDate(period[1])}`;
                openInNewTab(url);
            }
        }

        if (chart === ComposedMoney.production.title) {
            const period = parsePeriod(category);
            if (period) {
                let url = routes.expenses.path + `#${INCOMINTG_SECTION}=false`;
                url += `&${PRODUCTION_SECTION}=true`;
                url += `&${QUERY_SECTION}=`;
                url += `&${START_SECTION}=${formatDate(period[0])}&${END_SECTION}=${formatDate(period[1])}`;
                openInNewTab(url);
            }
        }

        if (chart === ComposedMoney.dividends.title) {
            const period = parsePeriod(category);
            if (period) {
                let url = routes.expenses.path + `#${INCOMINTG_SECTION}=false`;
                url += `&${PRODUCTION_SECTION}=false`;
                url += `&${QUERY_SECTION}=${Constants.bankInvoiceRowTypeDividends}`;
                url += `&${START_SECTION}=${formatDate(period[0])}&${END_SECTION}=${formatDate(period[1])}`;
                openInNewTab(url);
            }
        }

        if (chart === ComposedMoney.overhead.title) {
            const period = parsePeriod(category);
            if (period) {
                const url = routes.expenses.overhead.path + `#${YEAR_SECTION}=${period[0].getFullYear()}`;
                openInNewTab(url);
            }
        }
    };

    toggleShowAgents = () => {
        this.setState({ showAgents: !this.state.showAgents });
    };
    toggleLoadingAgents = () => {
        this.setState({ loadingAgents: !this.state.loadingAgents });
    };

    onToggleCollapseComposed = () => {
        this.props.dashboard.collapser.toggle(Charts.composed);
        this.changeHeightDrag(8, 8);
    };

    onToggleCollapseWorkingOut = () => {
        this.props.dashboard.collapser.toggle(Charts.workingOut);
        this.changeHeightDrag(11, 8);
    };

    onToggleCollapseOverhead = () => {
        this.props.dashboard.collapser.toggle(Charts.overheadSpendings);
        this.changeHeightDrag(9, 6);
    };

    onToggleCollapseProfitability = () => {
        this.props.dashboard.collapser.toggle(Charts.profitability);
        this.changeHeightDrag(10, 6);
    };

    onToggleCollapseEngineers = () => {
        this.props.dashboard.collapser.toggle(Charts.engineers);
        this.changeHeightDrag(2, 4);
    };

    onToggleCollapseEmployee = () => {
        this.props.dashboard.collapser.toggle(Charts.employee);
        this.changeHeightDrag(3, 5);
    };

    onToggleCollapseCompanyAge = () => {
        this.props.dashboard.collapser.toggle(Charts.companyAge);
        this.changeHeightDrag(4, 5);
    };

    onToggleSalaryChart = () => {
        this.props.dashboard.collapser.toggle(Charts.salary);
        this.changeHeightDrag(5, 6);
    };

    onToggleCollapseStatuses = () => {
        this.props.dashboard.collapser.toggle(Charts.orders);
        this.changeHeightDrag(1, 8);
    };

    onToggleCollapseAccounts = () => {
        this.props.dashboard.collapser.toggle(Charts.accounts);
        this.changeHeightDrag(7, 4);
    };

    resetLayout = () => {
        this.setState({ layout: defaultLayout });
        for (var key in defaultCollapse) {
            this.props.dashboard.collapser.set(key, defaultCollapse[key]);
        }
        localStorage.removeItem("toggleDashboard");
        localStorage.removeItem("rgl");
    };

    changeHeightDrag = (index: number, height: number) => {
        let items = this.state.layout?.map((item) => ({ ...item }));
        if (items) {
            if (items[index].h === 1) {
                items[index].h = height;
                items[index].maxH = height;
                items[index].minH = height;
            } else {
                items[index].h = 1;
                items[index].maxH = 1;
                items[index].minH = 1;
            }
        }

        this.onLayoutChange(items);
        localStorage.setItem("toggleDashboard", JSON.stringify(this.props.dashboard.collapser.plain));
    };
}

export const Dashboard = observer(DashboardBase);

interface DashboardProps {
    table: DetailedOrderListType;
    dashboard: DashboardStoreType;
    accounts: MoneyAccountStoreType;
    onRefresh?: () => void;
}

interface DashboardState {
    printing: boolean;
    layout: Layout[] | undefined;
    showAgents: boolean;
    loadingAgents: boolean;
}

const steppedGraduator = (step: number, customMax?: number) => (axis: ValueAxis, max: number) => {
    const limit = typeof customMax === "undefined" ? max : customMax;

    if (limit <= step) {
        // value is too small to be stepped
        return;
    }

    axis.axisRanges.clear();

    const createGrid = (value: number) => {
        const range = axis.axisRanges.create();
        range.value = value;
        range.label.text = "{value}";
    };

    let start = 0;
    while (start <= limit) {
        createGrid(start);
        start += step;
    }

    createGrid(start);

    axis.max = start;
};

const dynamicGraduator = (steps: number, round: number) => (axis: ValueAxis, max: number) => {
    if (steps % 2 === 0) {
        // должно быть нечетное число, чтобы график рисовался красиво
        steps++;
    }

    let step = max / steps;
    if (step < round) {
        step = round;
    } else {
        const diff = step % round;
        if (diff > 0) {
            step += round - diff;
        }
    }

    if (max <= step) {
        // value is too small to be stepped
        return;
    }

    axis.axisRanges.clear();

    const createRange = (value: number) => {
        const range = axis.axisRanges.create();
        range.value = value;
        range.label.text = "{value}";
    };

    let start = 0;
    while (start <= max) {
        createRange(start);
        start += step;
    }

    createRange(start);

    axis.max = start;
};

const overheadRelativeGraduator = (axis: ValueAxis) => {
    const createGrid = (value: number) => {
        const range = axis.axisRanges.create();
        range.value = value;
        range.label.text = "{value}%";
    };

    axis.axisRanges.clear();

    createGrid(0);
    createGrid(10);
    createGrid(20);
    createGrid(40);
    createGrid(60);
    createGrid(80);
    createGrid(100);

    axis.max = 100;
};

const profitabilityGraduator = (axis: ValueAxis) => {
    const createGrid = (value: number) => {
        const range = axis.axisRanges.create();
        range.value = value;
        range.label.text = "{value}%";
    };

    axis.axisRanges.clear();

    createGrid(0);
    createGrid(10);
    createGrid(20);
    createGrid(30);
    createGrid(40);
    createGrid(50);

    axis.max = 50;
};

const mainChartGraduator = steppedGraduator(1000000);
const bigMoneyGraduator = dynamicGraduator(5, 1000000);
const mediumMoneyGraduator = steppedGraduator(100000);
const ageGraduator = steppedGraduator(20, 40);
const salaryGraduator = dynamicGraduator(5, 5000);

const standardBulleter = (bullet: CircleBullet) => {
    bullet.circle.strokeWidth = 1;
    bullet.circle.radius = 3;
};

const PrintButton = (props: { loading: boolean; onClick: () => void }) => {
    return (
        <PlanrButton
            type="secondary"
            size="small"
            icon={props.loading ? undefined : "general-print"}
            onClick={props.onClick}
            disabled={props.loading}
        >
            {props.loading && <Spinner intent="primary" size={16} />}
        </PlanrButton>
    );
};
