import "./wdr";
import React from "react";
import ReactDOM from "react-dom";
import "modules/root/services/locale";

import "normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "modules/root/styles/theme/blueprint/blueprint.scss";
import "react-month-picker/css/month-picker.css";
import "rc-steps/assets/index.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";

import "modules/root/styles/planr/index.scss";
import "lightbox-react/style.css";
import "./modules/root/styles/index.scss";
import "./modules/root/styles/theme/index.scss";
import "react-image-lightbox/style.css";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-balham.css";

import { applySnapshot } from "mobx-state-tree";

import moment from "moment";
import "moment/locale/ru";

import App from "modules/root/components/app/App";
import { Unavailable } from "modules/root/components/app/Unavailable";

import { nameof } from "modules/common/services/typescript";
import {
    buildHttpTransport,
    HttpError,
    NotAuthorized,
    ConfirmationWaiting,
    Locked,
} from "modules/common/services/communication/http";

import { Constants } from "modules/root/models/constants";
import { ApplicationStore, initialState, StoreWrapper } from "modules/root/models/store";
import { Notifier } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";

// https://github.com/palantir/blueprint/issues/394
import { Popover as Popover1 } from "@blueprintjs/core";
import { Popover as Popover2 } from "@blueprintjs/core/lib/esnext";
import { RootCache } from "modules/common/services/cache";
import { NotificationType } from "modules/common/services/notifications/types";
import { AppVersion } from "modules/common/services/app";

Popover1.defaultProps.modifiers = {
    computeStyle: {
        gpuAcceleration: false,
    },
};
Popover2.defaultProps.modifiers = {
    computeStyle: {
        gpuAcceleration: false,
    },
};

moment.locale("ru");

const apiUrl = process.env.REACT_APP_API_URL || "";
fetch(apiUrl + apiUrls.application.metadata, {
    credentials: "include",
})
    .then((r) => r.json())
    .then((metadata: AppMetadata) => {
        // restore latestOrderId from local cache
        const latestOrderId = RootCache.get(
            nameof((a: AppMetadata) => a.latestOrderId),
            ""
        );

        if (latestOrderId) {
            metadata.latestOrderId = latestOrderId;
        }

        run({
            url: apiUrl,
            ...metadata,
        });
    })
    .catch(() => {
        ReactDOM.render(<Unavailable />, document.getElementById("root"));
    });

async function run(config: AppConfig) {
    const version = AppVersion();

    const wrapper: StoreWrapper = {
        store: null,
    };
    const notificator = buildNotificatior(wrapper);

    const http = buildHttpTransport(config, (status, message) => {
        if (wrapper.store && status === NotAuthorized) {
            wrapper.store.session.loggedIn && wrapper.store.session.setPopupStaste(true);
        }

        if (wrapper.store && status === ConfirmationWaiting) {
            wrapper.store.session.setLoggedInState(false);
            notificator.warning(message);
        }

        if (wrapper.store && status === Locked) {
            wrapper.store.session.setLoggedInState(false);
        }
    });

    const appStore = ApplicationStore.create(initialState(config, version), {
        http,
        notificator,
    });
    wrapper.store = appStore;

    // очищаем состояние после выхода
    const onLogout = () =>
        applySnapshot(
            appStore,
            initialState(
                {
                    ...config,
                    access: [],
                    latestOrderId: "",
                    userId: "",
                    userLogin: "",
                    userName: "",
                },
                version
            )
        );

    wrapper.store = appStore;

    config.constants && applySnapshot(Constants, config.constants);

    await wrapper.store.setupConnection(config.url);
    if (wrapper.store.session.loggedIn) {
        await wrapper.store.loadChangelog();
    }

    ReactDOM.render(<App store={wrapper.store} onLogout={onLogout} />, document.getElementById("root"));

    if (config.waitConfirmation) {
        wrapper.store.session.showConfirmationWarning();
    }
}

const buildNotificatior = (wrapper: StoreWrapper): Notifier => {
    const showMessage = (type: NotificationType, message: string) => {
        if (wrapper.store) {
            wrapper.store.notifications.addNotification(type, message);
        }
    };

    return {
        error(message: any) {
            const text = typeof message === "string" ? message : message.message;

            const suppress =
                message instanceof HttpError && [NotAuthorized, ConfirmationWaiting].includes(message.code)
                    ? true
                    : false;

            !suppress && showMessage("error", text);
        },
        info(message: string) {
            showMessage("info", message);
        },
        success(message: string) {
            showMessage("success", message);
        },
        warning(message: string) {
            showMessage("warning", message);
        },
    };
};
