import React from "react";
import { FinanceRequestsStoreType, FinanceRequestsTabs } from "../models/finance-requests-store";
import { observer } from "mobx-react";
import { Tab, Tabs } from "@blueprintjs/core";
import styles from "./FinanceRequestsPage.module.scss";
import { RouteChildrenProps } from "react-router";
import { Page } from "modules/common/components/page/Page";
import { RequestsTable } from "./RequestsTable";
import { routes } from "modules/common/routes";
import { SessionType } from "modules/session/auth/models/session";
import func from "../../functionalities";
import agents from "modules/agents/functionalities";
import { can, canAny } from "modules/session/auth/access";
import { formatMoney } from "modules/common/components/money/Money";

export const FinanceRequestsPage = observer(
    class extends React.Component<FinanceRequestsPageProps & RouteChildrenProps> {
        componentDidMount() {
            const { store, session } = this.props;

            document.title = "Финансовые запросы";
            const canLoad = canAny([func.FINANCE_REQUEST_PAY, func.FINANCE_REQUEST_ACCEPT], session.access);
            canLoad && store.load();
        }

        render() {
            const { store, location, session } = this.props;
            const canPay = can(func.FINANCE_REQUEST_PAY, session.access);
            const canAccept = can(func.FINANCE_REQUEST_ACCEPT, session.access);

            const canExport = can(func.FINANCE_REQUEST_EXPORT, session.access);
            const canOutsourcer = can(agents.OUTSOURCER_READ, session.access);

            return (
                <Page>
                    <Tabs
                        className={`${styles.tabs} planr-tabs-navigation`}
                        id="FinanceRequestsPage"
                        onChange={this.changeTabId}
                        selectedTabId={this.getTabId(location.hash, canPay, canAccept)}
                        renderActiveTabPanelOnly={true}
                    >
                        {canAccept && (
                            <Tab
                                id={routes.financeRequests.tab(FinanceRequestsTabs.new)}
                                title={`Новые запросы: ${formatMoney(store.new.totalSum)}`}
                                panel={
                                    <RequestsTable
                                        toggleStatus={store.togglePaymentStatus}
                                        cancelRequest={store.cancelPaymentRequest}
                                        changeImmediate={store.changeImmediate}
                                        canExport={canExport}
                                        canViewOutsourser={canOutsourcer}
                                        store={store.new}
                                        baseUrl={store.baseUrl}
                                    />
                                }
                            />
                        )}
                        {canPay && (
                            <Tab
                                id={routes.financeRequests.tab(FinanceRequestsTabs.accepted)}
                                title={`К оплате: ${formatMoney(store.accepted.totalSum)}`}
                                panel={
                                    <RequestsTable
                                        toggleStatus={store.togglePaymentStatus}
                                        cancelRequest={store.cancelPaymentRequest}
                                        canExport={canExport}
                                        canViewOutsourser={canOutsourcer}
                                        store={store.accepted}
                                        baseUrl={store.baseUrl}
                                    />
                                }
                            />
                        )}
                    </Tabs>
                </Page>
            );
        }

        getTabId = (pathname: string, canPay: boolean, canAccept: boolean) => {
            const canAll = canPay && canAccept;
            if (canAll) {
                if (pathname.includes(FinanceRequestsTabs.accepted)) {
                    return routes.financeRequests.tab(FinanceRequestsTabs.accepted);
                }

                return routes.financeRequests.tab(FinanceRequestsTabs.new);
            } else if (canAccept) {
                return routes.financeRequests.tab(FinanceRequestsTabs.new);
            } else if (canPay) {
                return routes.financeRequests.tab(FinanceRequestsTabs.accepted);
            }

            return "wrong";
        };

        changeTabId = (tabId: string) => {
            const { history } = this.props;
            history.push(tabId);
        };
    }
);

interface FinanceRequestsPageProps {
    session: SessionType;
    store: FinanceRequestsStoreType;
}
