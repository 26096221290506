import React from "react";
import styles from "./IpdRow.module.scss";
import { SaveOrderIpd } from "modules/orders-manage/models/order-ipd";
import { texts } from "modules/common/texts";
import { Classes, Popover } from "@blueprintjs/core";

export const IpdHeader = ({ active }: IpdHeaderProps) => {
    return (
        <div className={`${styles.row} ${styles.headerRow} flat-mode`}>
            <div className={styles.iconCell}>&nbsp;</div>
            <div className={`${styles.name} ${styles.headerMg}`}>{texts.name}</div>
            <div className={`${styles.number} ${styles.headerMg}`}>Реквизиты</div>
            <div className={`${styles.mentor} ${styles.headerMg}`}>
                <Popover
                    position="top"
                    enforceFocus={false}
                    popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                    usePortal={true}
                    interactionKind="hover"
                    boundary="viewport"
                >
                    <div className={Classes.TOOLTIP_INDICATOR}>Заказчик</div>

                    <div>
                        Ответственным является заказчик. Документация является необходимыми исходными данными для начала
                        работы над проектом.
                    </div>
                </Popover>
            </div>
            <div className={styles.stage}>Этап</div>
            <div className={`${styles.description} ${styles.headerMg}`}>{texts.description}</div>
            <div className={styles.file}>Запрос</div>
            <div className={styles.version}></div>
            <div className={styles.file}>Докумет</div>
            <div className={styles.version}></div>
            {!active && <div className={styles.status}>Статус</div>}
            <div className={styles.iconCell}>&nbsp;</div>
        </div>
    );
};

interface IpdHeaderProps {
    active: SaveOrderIpd | null;
}
