import React, { useState } from "react";
import { FormikProps } from "formik";
import { fields, OrderSummarySnapshotType } from "modules/orders-manage/models/order";
import { OrderStatusDictionaryType } from "modules/dictionaries/order-statuses/models/order-status-dictionary";
import { StandardFormInput, ReadOnlyFormElement } from "modules/common/components/form/StandardFormInput";
import { Schema } from "yup";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { InputGroup, Tooltip, Classes } from "@blueprintjs/core";
import { Money } from "modules/common/components/money/Money";
import styles from "./FinanceDashboard.module.scss";
import { prettyRound } from "modules/common/services/formatting/numbers";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { eat } from "modules/common/services/typescript";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { FinanceValuesDetails } from "modules/main/components/table/FinanceValuesDetails";

const style = {
    color: "rgba(0, 39, 61, 0.4)",
};
const digits = onlyDigitsInputKeyPress(true);

export const FinanceDashboard = (props: FinanceDashboardProps) => {
    const { formProps, statuses, readOnly, schema, price } = props;
    const [planVisible, setplanVisible] = useState(false);
    const [actVisible, setactVisible] = useState(false);

    const togglePlanVisible = () => {
        setplanVisible(!planVisible);
    };
    const toggleActVisible = () => {
        setactVisible(!actVisible);
    };
    const orderStatusId = (formProps.values as any)[fields.orderStatusId];
    const canEditOrderFinanceFields = statuses.canEditOrderFinanceFields(orderStatusId);

    const { overhead, totalProduction, premiums } = formProps.values.financeValues.actual.percents;
    const waitingForFinishQuarter = formProps.values.financeValues.actual.waitingQuarter;

    const finValuesActualOverheadPercents = +(formProps.values.finValuesActualOverheadPercents as any);
    const finValuesPlanOverheadPercents = +(formProps.values.finValuesPlanOverheadPercents as any);
    const planPremiumPercents = +formProps.values.premiumPercents;
    const planProductionSpendingsPercents = +formProps.values.planProductionSpendingsPercents;
    const planProfitabilityPercents = 100 - (planProductionSpendingsPercents + finValuesPlanOverheadPercents);
    const actualProfitabilityPercents = 100 - (finValuesActualOverheadPercents + totalProduction);

    const overSpending = prettyRound(
        formProps.values.financeValues.actual.production - formProps.values.financeValues.actual.fixedProduction
    );

    let PlanFields = null;
    if (canEditOrderFinanceFields === true && !readOnly) {
        PlanFields = (
            <div className={styles.fieldsInputs}>
                <div className={styles.column}>
                    <div>
                        <div className={styles.fieldsLabel}>Накл. расходы %</div>
                        <StandardFormInput
                            name={fields.finValuesPlanOverheadPercents}
                            schema={schema}
                            small={true}
                            inline={false}
                            label=""
                        >
                            {({ field }) => {
                                return (
                                    <>
                                        <InputGroup
                                            id={field.name}
                                            {...field}
                                            className="planr-default-input"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            max={100}
                                            min={0}
                                            small={true}
                                        />
                                        <Money amount={calcMoney(+field.value, price)} style={style} />
                                    </>
                                );
                            }}
                        </StandardFormInput>
                    </div>
                    <div>
                        <div className={styles.fieldsLabel}>Произв. затраты %</div>
                        <StandardFormInput
                            name={fields.planProductionSpendingsPercents}
                            schema={schema}
                            small={true}
                            inline={false}
                            label=""
                        >
                            {({ field }) => {
                                return (
                                    <>
                                        <InputGroup
                                            id={field.name}
                                            {...field}
                                            className="planr-default-input"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            max={100}
                                            min={planPremiumPercents}
                                            small={true}
                                        />
                                        <Money
                                            amount={calcMoney(planProductionSpendingsPercents, price)}
                                            style={style}
                                        />
                                    </>
                                );
                            }}
                        </StandardFormInput>
                    </div>
                </div>
                <div className={styles.column}>
                    <div>
                        <div className={styles.fieldsLabel}>
                            <Tooltip
                                className={Classes.TOOLTIP_INDICATOR}
                                content={`Премии входят в состав производственных затрат`}
                            >
                                <span>
                                    Премии %{" "}
                                    <GeneralIcon type="general-exclamation" size={12} style={{ color: "#EA561E" }} />
                                </span>
                            </Tooltip>
                        </div>
                        <StandardFormInput
                            name={fields.premiumPercents}
                            schema={schema}
                            small={true}
                            inline={false}
                            label=""
                        >
                            {({ field }) => {
                                return (
                                    <>
                                        <InputGroup
                                            id={field.name}
                                            {...field}
                                            className="planr-default-input"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            max={100}
                                            small={true}
                                        />
                                        <Money amount={calcMoney(planPremiumPercents, price)} style={style} />
                                    </>
                                );
                            }}
                        </StandardFormInput>
                    </div>
                    <div>
                        <div className={styles.fieldsLabel}>Рентабельность %</div>
                        <div>
                            <ReadOnlyFormElement
                                label={""}
                                value={prettyRound(planProfitabilityPercents)}
                                inline={false}
                                small={true}
                            >
                                <Money amount={calcMoney(planProfitabilityPercents, price)} style={style} />
                            </ReadOnlyFormElement>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        PlanFields = (
            <div className={styles.fieldsInputs}>
                <div className={styles.column}>
                    <div>
                        <div className={styles.fieldsLabel}>Накл. расходы %</div>
                        <StandardFormInput
                            name={fields.finValuesPlanOverheadPercents}
                            schema={schema}
                            small={true}
                            inline={false}
                            label=""
                        >
                            {({ field }) => {
                                return (
                                    <>
                                        <InputGroup
                                            id={field.name}
                                            {...field}
                                            className="planr-default-input"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            max={100}
                                            min={0}
                                            readOnly={true}
                                            small={true}
                                        />
                                        <Money amount={calcMoney(+field.value, price)} style={style} />
                                    </>
                                );
                            }}
                        </StandardFormInput>
                    </div>
                    <div>
                        <div className={styles.fieldsLabel}>Произв. затраты %</div>
                        <StandardFormInput
                            name={fields.planProductionSpendingsPercents}
                            schema={schema}
                            small={true}
                            inline={false}
                            label=""
                        >
                            {({ field }) => {
                                return (
                                    <>
                                        <InputGroup
                                            id={field.name}
                                            {...field}
                                            className="planr-default-input"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            max={100}
                                            min={planPremiumPercents}
                                            readOnly={true}
                                            small={true}
                                        />
                                        <Money
                                            amount={calcMoney(planProductionSpendingsPercents, price)}
                                            style={style}
                                        />
                                    </>
                                );
                            }}
                        </StandardFormInput>
                    </div>
                </div>
                <div className={styles.column}>
                    <div>
                        <div className={styles.fieldsLabel}>
                            <Tooltip
                                className={Classes.TOOLTIP_INDICATOR}
                                content={`Премии входят в состав производственных затрат`}
                            >
                                <span>
                                    Премии %{" "}
                                    <GeneralIcon type="general-exclamation" size={12} style={{ color: "#EA561E" }} />
                                </span>
                            </Tooltip>
                        </div>
                        <StandardFormInput
                            name={fields.premiumPercents}
                            schema={schema}
                            small={true}
                            inline={false}
                            label=""
                        >
                            {({ field }) => {
                                return (
                                    <>
                                        <InputGroup
                                            id={field.name}
                                            {...field}
                                            className="planr-default-input"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            onKeyPress={digits}
                                            max={100}
                                            readOnly={true}
                                            small={true}
                                        />
                                        <Money amount={calcMoney(planPremiumPercents, price)} style={style} />
                                    </>
                                );
                            }}
                        </StandardFormInput>
                    </div>
                    <div>
                        <div className={styles.fieldsLabel}>Рентабельность %</div>
                        <div>
                            <ReadOnlyFormElement
                                label={""}
                                value={prettyRound(planProfitabilityPercents)}
                                inline={false}
                                small={true}
                            >
                                <Money amount={calcMoney(planProfitabilityPercents, price)} style={style} />
                            </ReadOnlyFormElement>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <h3 className={styles.financeHeader}>Экономические показатели</h3>
            <div className={styles.financeParameters}>
                <div className={styles.dashboardRow}>
                    <div className={styles.dashboardLabel}>
                        План
                        <GeneralIcon
                            type={planVisible ? "general-cross-big" : "general-edit"}
                            size={14}
                            className={styles.dashboardIcon}
                            onClick={togglePlanVisible}
                        />
                    </div>

                    <div className={planVisible ? styles.openedFields : styles.closedFields}>{PlanFields}</div>

                    <div className={styles.dashboard}>
                        <Slice
                            title="Накл. расходы"
                            className={styles.overheads}
                            value={finValuesPlanOverheadPercents}
                        />
                        <Slice
                            title="Произв. затраты"
                            className={styles.productions}
                            value={planProductionSpendingsPercents - planPremiumPercents}
                        />
                        <Slice title="Премии" className={styles.premiums} value={planPremiumPercents} />
                        <Slice
                            title="Рентабельность"
                            className={styles.profitability}
                            value={planProfitabilityPercents}
                        />
                    </div>
                </div>
                <div className={styles.dashboardRow}>
                    <div className={styles.dashboardLabel}>
                        {waitingForFinishQuarter && (
                            <Tooltip
                                className={Classes.TOOLTIP_INDICATOR}
                                content={`Ожидаем завершения квартала «${waitingForFinishQuarter}»`}
                            >
                                <span>
                                    Факт <GeneralIcon type="general-exclamation" style={{ color: "#EA561E" }} />
                                </span>
                            </Tooltip>
                        )}
                        {!waitingForFinishQuarter && <span>Факт</span>}

                        <GeneralIcon
                            type={actVisible ? "general-cross-big" : "general-edit"}
                            size={14}
                            className={styles.dashboardIcon}
                            onClick={toggleActVisible}
                        />
                    </div>

                    <div className={actVisible ? styles.openedFieldsAct : styles.closedFields}>
                        <div className={styles.fieldsInputs}>
                            <div className={styles.column}>
                                <div>
                                    <div className={styles.fieldsLabel}>Накл. расходы %</div>
                                    {canEditOrderFinanceFields && (
                                        <StandardFormInput
                                            name={fields.finValuesActualOverheadPercents}
                                            schema={schema}
                                            small={true}
                                            inline={false}
                                            label=""
                                        >
                                            {({ field }) => {
                                                return (
                                                    <>
                                                        <InputGroup
                                                            id={field.name}
                                                            {...field}
                                                            className="planr-default-input"
                                                            autoComplete="off"
                                                            data-lpignore="true"
                                                            onKeyPress={digits}
                                                            max={100}
                                                            min={planPremiumPercents}
                                                            small={true}
                                                            disabled={readOnly}
                                                        />
                                                        <Money amount={calcMoney(field.value, price)} style={style} />
                                                    </>
                                                );
                                            }}
                                        </StandardFormInput>
                                    )}

                                    {!canEditOrderFinanceFields && (
                                        <div>
                                            <ReadOnlyFormElement
                                                label={""}
                                                value={prettyRound(overhead)}
                                                inline={false}
                                                small={true}
                                            >
                                                <Money amount={calcMoney(overhead, price)} style={style} />
                                            </ReadOnlyFormElement>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div className={styles.fieldsLabel}>Произв. затраты %</div>
                                    <div>
                                        <ReadOnlyFormElement
                                            label={""}
                                            value={prettyRound(totalProduction)}
                                            inline={false}
                                            small={true}
                                        >
                                            <Money amount={calcMoney(totalProduction, price)} style={style} />
                                        </ReadOnlyFormElement>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column}>
                                <div>
                                    <div className={styles.fieldsLabel}>
                                        <Tooltip
                                            className={Classes.TOOLTIP_INDICATOR}
                                            content={`Премии входят в состав производственных затрат`}
                                        >
                                            <span>
                                                Премии %
                                                <GeneralIcon
                                                    type="general-exclamation"
                                                    size={12}
                                                    style={{ color: "#EA561E" }}
                                                />
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <ReadOnlyFormElement
                                            label={""}
                                            value={prettyRound(premiums)}
                                            inline={false}
                                            small={true}
                                        >
                                            <Money amount={calcMoney(premiums, price)} style={style} />
                                        </ReadOnlyFormElement>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.fieldsLabel}>Рентабельность %</div>
                                    <div>
                                        <ReadOnlyFormElement
                                            label={""}
                                            value={prettyRound(actualProfitabilityPercents)}
                                            inline={false}
                                            small={true}
                                        >
                                            <Money
                                                amount={calcMoney(actualProfitabilityPercents, price)}
                                                style={style}
                                            />
                                        </ReadOnlyFormElement>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className={styles.fieldsInputs}>
                            <div className={styles.column}>
                                <div>
                                    <div className={styles.fieldsLabel}>
                                        {" "}
                                        {FinanceValuesDetails(
                                            formProps.values.financeValues.today,
                                            <span className={Classes.TOOLTIP_INDICATOR}>Текущ. рентаб. %</span>
                                        )}
                                    </div>
                                    <div>
                                        <ReadOnlyFormElement
                                            label={""}
                                            value={prettyRound(
                                                formProps.values.financeValues.today.percents.profitability
                                            )}
                                            inline={false}
                                            small={true}
                                        >
                                            <Money
                                                amount={formProps.values.financeValues.today.profitability}
                                                style={style}
                                            />
                                        </ReadOnlyFormElement>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column}>
                                <div>
                                    <div className={styles.fieldsLabel}>После завершения</div>
                                    <div>
                                        <MoneyInput
                                            readOnly={true}
                                            value={overSpending}
                                            onMoneyChange={eat}
                                            small={true}
                                            className={`${
                                                overSpending > 0 ? styles.overspending : ""
                                            } planr-default-input`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.dashboard}>
                        <Slice title="Накл. расходы" className={styles.overheads} value={overhead} />
                        <Slice
                            title="Произв. затраты"
                            className={styles.productions}
                            value={totalProduction - premiums}
                        />
                        <Slice title="Премии" className={styles.premiums} value={premiums} />
                        <Slice
                            title="Рентабельность"
                            className={styles.profitability}
                            value={actualProfitabilityPercents}
                        />
                    </div>
                </div>
                <div className={styles.legend}>
                    <div className={styles.legendColumn}>
                        <div className={styles.legendRow}>
                            <div className={`${styles.overheads} ${styles.round} ${styles.premium}`}></div>
                            <div>Накл. расходы</div>
                        </div>
                        <div className={styles.legendRow}>
                            <div className={` ${styles.productions} ${styles.round} `}></div>
                            <div>Произв. затраты</div>
                        </div>
                    </div>
                    <div className={styles.legendColumn}>
                        <div className={styles.legendRow}>
                            <div className={`${styles.round} ${styles.premiums}`}></div>
                            <div>Премии</div>
                        </div>
                        <div className={styles.legendRow}>
                            <div className={`${styles.round} ${styles.profitability}`}></div>
                            <div>Рентабельность</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function calcMoney(percents: number, sum: number) {
    return prettyRound((percents / 100) * sum);
}

interface FinanceDashboardProps {
    formProps: FormikProps<OrderSummarySnapshotType>;
    statuses: OrderStatusDictionaryType;
    readOnly: boolean | undefined;
    schema: Schema<any>;
    price: number;
}

export const MINIMAL_VISIBLE = 7;

export const SectionPersents: React.FC<{ value: number; title: string; context?: string }> = (props) => {
    if (props.value < MINIMAL_VISIBLE) {
        return null;
    }

    const text = <span>{`${props.title} ${props.context ? props.context : ""}`}</span>;
    const content: any = props.children ? (
        <>
            {text}
            {props.children}
        </>
    ) : (
        text
    );

    return (
        <span className={styles.percentsValue}>
            <Tooltip content={content}>
                <span style={{ cursor: "help" }}>{Math.round(props.value)}%</span>
            </Tooltip>
        </span>
    );
};

export const Slice: React.FC<{ value: number; title: string; className: string; context?: string }> = (props) => {
    const text = `${props.title}: ${prettyRound(props.value)}% ${props.context ? props.context : ""}`;
    const content: any = props.children ? (
        <>
            {text}
            {props.children}
        </>
    ) : (
        text
    );

    if (props.value < MINIMAL_VISIBLE) {
        return (
            <div className={props.className} style={{ width: `${props.value}%`, cursor: "help" }}>
                <Tooltip content={content} className={styles.sliceHelper}>
                    <span />
                </Tooltip>
            </div>
        );
    }

    return (
        <div className={props.className} style={{ width: `${props.value}%` }}>
            <SectionPersents {...props}>{props.children}</SectionPersents>
        </div>
    );
};
