import React from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ColDef } from "@ag-grid-community/core";
import { FinanceRequestSnapshotType, getRowNodeId } from "../models/request";
import { observer } from "mobx-react";
import { DefaultAgGridOptions, defaultModules, FIGMA_HEADER_HEIGHT } from "modules/common/services/table/helpers";
import styles from "./FinanceRequestsPage.module.scss";
import { agGridLocale } from "modules/root/services/locale";
import { columnsBuilder, RequestActions } from "./columns";
import { Search } from "modules/common/components/form/Search";
import { Money } from "modules/common/components/money/Money";
import { Collapse } from "@blueprintjs/core";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { IconedRadioGroup, IconedRadio } from "modules/common/components/form/IconedRadioGroup";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { sumBy } from "lodash";
import { Loader } from "modules/common/components/loader/Loader";
import { routes } from "modules/common/routes";
import { prevent } from "modules/common/services/form/select";
import { Caret } from "modules/common/components/collapse/Caret";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { TabStoreType } from "../models/finance-requests-store";

export const RequestsTable = observer(
    class extends React.Component<NewRequestsProps, RequestsTableState> implements RequestActions {
        private options = DefaultAgGridOptions(true);
        private columns: ColDef[];

        constructor(props: NewRequestsProps) {
            super(props);

            const { type, data } = this.props.store;

            this.columns = columnsBuilder(type, this.props.canViewOutsourser);
            this.state = {
                collapser: {
                    [Object.keys(data)[0]]: true,
                },
            };
        }

        componentDidUpdate(prev: NewRequestsProps) {
            if (prev.canViewOutsourser !== this.props.canViewOutsourser) {
                const { type } = this.props.store;
                this.columns = columnsBuilder(type, this.props.canViewOutsourser);
            }
        }

        render() {
            const { store, canExport } = this.props;
            const { collapser } = this.state;
            const { data, grouping, query, isLoading } = store;

            return (
                <div className={styles.table}>
                    <Loader active={isLoading} />

                    <div className={styles.actions}>
                        <div className={styles.right}>
                            {canExport && (
                                <>
                                    <PlanrButton
                                        type="graybtn"
                                        icon="general-export"
                                        onClick={store.export}
                                        round={true}
                                    />
                                    <PlanrButton
                                        type="graybtn"
                                        icon="general-print"
                                        onClick={store.print}
                                        round={true}
                                    />
                                </>
                            )}
                            <Search query={query} onSearch={this.onSearch} />
                        </div>{" "}
                        <div className="sorting">
                            <CollapseExpandAll onChange={this.collapseAll} />
                        </div>
                    </div>

                    <div className="tools">
                        <IconedRadioGroup
                            className="grouping"
                            onChange={this.onGroupingChange}
                            selectedValue={grouping}
                            inline={true}
                            name="grouping"
                        >
                            <IconedRadio label="По проектам" value="project">
                                <GeneralIcon type={"general-home"} />
                            </IconedRadio>
                            <IconedRadio label="По датам" value="period">
                                <GeneralIcon type={"general-calendar"} />
                            </IconedRadio>
                            <IconedRadio label="По контрагентам" value="outsourcer">
                                <GeneralIcon type={"general-ppl"} />
                            </IconedRadio>
                        </IconedRadioGroup>
                    </div>

                    {Object.keys(data).map((title) => {
                        const collapsed = !collapser[title];
                        const rows = data[title];
                        const total = sumBy(rows, (r) => r.sum);
                        const toggle = () => this.onToggleCollapse(title);

                        return (
                            <GridTheme bordered={true} key={title} className="designed collapse-header-finance">
                                <h1 className="planr-block-header-wide collapser" onClick={toggle}>
                                    {this.renderTitle(rows, title)}
                                    <Money amount={total} />
                                    <Caret collapsed={collapsed} />
                                </h1>

                                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                                    <span className="collapse-wide">
                                        <AgGridReact
                                            rowStyle={{
                                                borderBottom: "1 px solid #EDF0F2",
                                                fontFamily: "GothamPro, Arial, sans-serif",
                                                fontWeight: "300",
                                                color: "#00273D",
                                                height: "28px",
                                                fontSize: "12px",
                                            }}
                                            suppressRowClickSelection={true}
                                            suppressCellSelection={true}
                                            enableCellTextSelection={true}
                                            columnDefs={this.columns}
                                            rowData={rows}
                                            headerHeight={FIGMA_HEADER_HEIGHT}
                                            minColWidth={5}
                                            getRowNodeId={getRowNodeId}
                                            domLayout="autoHeight"
                                            localeText={agGridLocale}
                                            context={this}
                                            gridOptions={this.options}
                                            immutableData={true}
                                            modules={defaultModules}
                                            animateRows={true}
                                        />
                                    </span>
                                </Collapse>
                            </GridTheme>
                        );
                    })}
                </div>
            );
        }

        renderTitle = (rows: FinanceRequestSnapshotType[], title: string) => {
            const { store, canViewOutsourser } = this.props;
            const outsourcer = rows[0] ? rows[0].outsourcer : null;

            if (outsourcer && store.grouping === "outsourcer" && canViewOutsourser) {
                const url = routes.agents.outsourcers.details.outsourcers;

                return (
                    <a href={url(outsourcer.id)} target="_blank" rel="noopener noreferrer" onClick={prevent}>
                        {title}
                    </a>
                );
            }

            return title;
        };

        onSearch = this.props.store.setQuery;

        collapseAll = (collapsed: boolean) => {
            const { data } = this.props.store;

            const appendo: TStringMap<boolean> = {};
            Object.keys(data).forEach((key) => (appendo[key] = !collapsed));
            const collapser = { ...this.state.collapser, ...appendo };

            this.setState({ collapser });
        };

        onToggleCollapse = (name: string) => {
            const old = !!this.state.collapser[name];
            const collapser = { ...this.state.collapser, [name]: !old };

            this.setState({ collapser });
        };

        accept = (request: FinanceRequestSnapshotType) => {
            this.props.toggleStatus(request.id);
        };

        cancel = (request: FinanceRequestSnapshotType) => {
            const { cancelRequest } = this.props;
            cancelRequest && cancelRequest(request.id);
        };

        onImmediateCange = (request: FinanceRequestSnapshotType, value: boolean) => {
            const { changeImmediate } = this.props;
            changeImmediate && changeImmediate(request.id, value);
        };

        onGroupingChange = (value: string) => {
            const { store } = this.props;
            store.setGrouping(value as any);
        };

        baseUrl = () => this.props.baseUrl;
    }
);

interface RequestsTableState {
    collapser: TStringMap<boolean>;
}

interface NewRequestsProps {
    toggleStatus: (id: string) => void;
    cancelRequest: (id: string) => void;
    changeImmediate?: (id: string, value: boolean) => void;
    canExport: boolean;
    canViewOutsourser: boolean;
    store: TabStoreType;
    baseUrl: string;
}
