import React, { useState } from "react";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { observer } from "mobx-react";
import { DetailedOrderListType, DetailedOrderList, DetailedOrderRowSnapshotType } from "../../models/detailed-order";
import { can, canAny } from "modules/session/auth/access";
import func, { canReadOrder } from "modules/orders-manage/functionalities";
import { columns } from "./columns";
import styles from "./Table.module.scss";
import { Page } from "modules/common/components/page/Page";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { routes } from "modules/common/routes";
import { AgGridReact } from "@ag-grid-community/react";
import {
  CellContextMenuEvent,
  ColumnApi,
  ColumnMovedEvent,
  ColumnResizedEvent,
  GetContextMenuItemsParams,
  GridReadyEvent,
  RowEvent,
} from "@ag-grid-community/core";
import { agGridLocale } from "modules/root/services/locale";
import { DefaultAgGridOptions, defaultModules, FIGMA_HEADER_HEIGHT } from "modules/common/services/table/helpers";
import { SessionType } from "modules/session/auth/models/session";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { Search } from "modules/common/components/form/Search";
import { texts } from "modules/common/texts";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import {
  renderSingleOption,
  filterItemPredicate,
  SimpleSelect,
  DefaultSelectedOption,
} from "modules/common/services/form/select";
import { Classes, Dialog, InputGroup, MenuItem, Popover } from "@blueprintjs/core";
import { trimStart } from "modules/common/services/strings";
import { ColumnsSelector } from "modules/common/components/table/ColumnsSelector";
import { MultiSelect } from "@blueprintjs/select";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { OrderStatusDictionaryItemType } from "modules/dictionaries/order-statuses/models/order-status";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { Buttons } from "modules/common/components/form";
import { OrderIndicatorsListType } from "modules/dictionaries/order-indicators/models/order-indicator-list";

const menu = menuItems();
const TABLE_NAME = DetailedOrderList.name;
const modules = [...defaultModules, MenuModule];
const StatusMultiSelect = MultiSelect.ofType<OrderStatusDictionaryItemType>();

export function OrdersExternalParams(locationHash: string) {
  const hash = trimStart("#", locationHash);

  if (hash) {
    const key = hash.slice(19);
    return key;
  }
  return null;
}

interface TableState {
  isColumnsMoved: boolean;
  confirmOpened: boolean;
  row: DetailedOrderRowSnapshotType | null;
  indicatorsVisible: boolean;
}

export const MainTable = withRouter(
  observer(
    class extends React.Component<TableProps & RouteComponentProps, TableState> {
      private options = DefaultAgGridOptions(true);
      private gridColumnApi: ColumnApi | null = null;
      private tableGrid: any | null = null;
      private cols: any[] = [];
      private confirmRemoving?: (e: DetailedOrderRowSnapshotType) => void;

      private canChange = can(func.ORDERS_SUMMARY_EDIT, this.props.session.access);
      constructor(props: TableProps & RouteComponentProps) {
        super(props);

        this.state = {
          isColumnsMoved: false,
          confirmOpened: false,
          row: null,
          indicatorsVisible: false,
        };

        const columnsConfig = props.store.columner.moveColumns(this.buildColumnsDefinition(), props.store.sorter);
        const columnsConf = props.store.columner.sizeColumns(columnsConfig);

        props.store.columner.hideColumns(columnsConf);

        this.cols = columnsConf;
      }

      componentDidMount() {
        const { location, store } = this.props;
        document.title = menu.main.label;

        const filter = OrdersExternalParams(location.hash);
        if (filter) {
          store.setStatuses(filter);
        }
      }
      filterIndicatorsAdd = () => {
        this.props.store.addIndicators();
      };

      changeIndicator = (id: string, value: string, field: string) => {
        this.props.store.changeIndicators(id, value, field);
      };

      showInternal = () => {
        this.props.store.toggleShowInternal();
      };

      onItemDelete = (id: string) => {
        this.props.store.deleteIndicators(id);
      };

      render() {
        const { session, store } = this.props;
        const moneyVisible = can(func.FLOW_ORDERS_TABLE, session.access);
        const statuses = Object.values(store.statuses.asMap);
        const canOrderIndicatorsRead = canAny(
          [func.ORDERS_TECHINDICATORS_READ, func.ORDERS_TECHINDICATORS_EDIT],
          session.access
        );
        let selectedStatuses: any[] = [];
        store.selectedStatuses.forEach((item) =>
          store.statuses.statuses.forEach((status) => {
            if (status.id === item) {
              selectedStatuses.push(status);
            }
          })
        );
        return (
          <Page className={styles.page}>
            <Dialog
              icon="help"
              title={texts.confirmation}
              isOpen={this.state.confirmOpened}
              backdropClassName="standard"
              className={`${styles.smallDialog} figma-dialog`}
            >
              <div className={`${Classes.DIALOG_BODY}`}>
                <p
                  className={styles.clearInfo}
                >{`Повторное подтверждение удаления договора "${this.state.row?.name}".`}</p>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <Buttons
                  left={
                    <div style={{ display: "flex" }}>
                      <PlanrButton
                        type="greenish"
                        size="small"
                        onClick={() => {
                          this.toggleConfirm();
                          if (this.state.row) {
                            this.onRemove(this.state.row);
                          }
                        }}
                      >
                        {texts.remove}
                      </PlanrButton>
                      <PlanrButton
                        type="graybtn"
                        onClick={this.toggleConfirm}
                        size="small"
                        style={{ marginLeft: "10px" }}
                      >
                        Отмена
                      </PlanrButton>
                    </div>
                  }
                />
              </div>
            </Dialog>
            <div className={styles.filters}>
              <div className={styles.filterSelect}>
                <StatusMultiSelect
                  activeItem={null}
                  className={`full-width-select ${Classes.FILL}`}
                  itemRenderer={renderSingleOption}
                  itemsEqual={this.areStatusesEquals}
                  selectedItems={selectedStatuses}
                  items={statuses}
                  onItemSelect={this.selectStatus}
                  placeholder="Все договоры"
                  itemPredicate={filterItemPredicate}
                  tagRenderer={this.renderTag}
                  noResults={<MenuItem disabled={true} text={texts.noData} />}
                  popoverProps={{ minimal: true }}
                  resetOnSelect={true}
                  onRemove={store.removeStatuses}
                />
                <GeneralIcon
                  type={selectedStatuses.length > 0 ? "general-cross-small" : "general-chevron-down"}
                  onClick={store.clearStatuses}
                  style={{
                    marginTop: "5px",
                    marginLeft: "5px",
                    color: selectedStatuses.length > 0 ? "#E31818" : "#00273D",
                    position: "absolute",
                    left: "365px",
                    top: "5px",
                    cursor: "pointer",
                  }}
                />
              </div>

              <div>
                {canOrderIndicatorsRead && (
                  <Popover position="auto" isOpen={this.state.indicatorsVisible} interactionKind="click">
                    <PlanrButton
                      type="graybtn"
                      round={true}
                      onClick={() => this.setState({ indicatorsVisible: !this.state.indicatorsVisible })}
                      title={"Поиск по ТЭП"}
                    >
                      ТЭП
                    </PlanrButton>

                    <FilterIndicators
                      indicators={store.orderIndicators}
                      stateIndicators={store.selectedIndicators}
                      addNewIndicator={this.filterIndicatorsAdd}
                      onItemChange={(id, value, field) => {
                        this.changeIndicator(id, value, field);
                      }}
                      onItemDelete={(id) => this.onItemDelete(id)}
                    />
                  </Popover>
                )}
              </div>
              <PlanrButton
                type={store.showInternal ? "blueish" : "graybtn"}
                onClick={this.showInternal}
                size="small"
                style={{
                  width: "120px",
                  fontSize: "12px",
                  marginLeft: "14px",
                }}
              >
                Внутренние проекты
              </PlanrButton>
              <div className={styles.tools}>
                <ColumnsSelector
                  store={store.columner}
                  round={true}
                  columns={this.cols}
                  resetColumns={this.resetColumns}
                  isColumnsMoved={this.state.isColumnsMoved}
                  onVisibleChanged={this.visibleColsChange}
                />
                <PlanrButton
                  type="graybtn"
                  icon="general-compare"
                  round={true}
                  onClick={() => store.print(!moneyVisible)}
                  title={texts.compare}
                />

                <PlanrButton
                  type="graybtn"
                  icon="general-print"
                  round={true}
                  onClick={() => store.print(!moneyVisible)}
                />

                <Search query={store.query} onSearch={this.onSearch} />
              </div>
            </div>
            <RemoveConfirmation<DetailedOrderRowSnapshotType>
              observation={store.observation}
              what={(e) => `договор "${e.name}"`}
              onConfirmed={this.onRemoveConfirm}
              render={({ confirmRemoving }) => {
                this.confirmRemoving = confirmRemoving;

                return (
                  <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                    <LocalLoader active={store.isEmpty && store.loading} />

                    <AgGridReact
                      rowStyle={{
                        borderBottom: "1 px solid #EDF0F2",
                        fontFamily: "GothamPro, Arial, sans-serif",
                        color: "#00273D",
                        height: "28px",
                        fontSize: "12px",
                      }}
                      rowSelection="single"
                      suppressCellSelection={true}
                      enableCellTextSelection={true}
                      columnDefs={this.cols}
                      rowData={store.data}
                      onGridReady={this.onGridReady}
                      localeText={agGridLocale}
                      headerHeight={FIGMA_HEADER_HEIGHT}
                      minColWidth={5}
                      context={this}
                      onRowDoubleClicked={this.goToOrder}
                      onRowClicked={this.onRowSelect}
                      getRowNodeId={getRowNodeId}
                      getContextMenuItems={this.getContextMenuItems}
                      onCellContextMenu={this.onCellContextMenu}
                      gridOptions={this.options}
                      modules={modules}
                      onSortChanged={store.sorter.resorted}
                      immutableData={true}
                      onColumnMoved={this.onColMoved}
                      onColumnResized={this.onColumnResized}
                      suppressDragLeaveHidesColumns={true}
                    />
                  </GridTheme>
                );
              }}
            />
          </Page>
        );
      }

      onColumnResized = (params: ColumnResizedEvent) => {
        const { store } = this.props;

        store.columner.saveColumnsSize(params.columnApi.getColumnState());
      };
      onCellContextMenu = ({ node }: CellContextMenuEvent) => {
        node && node.setSelected(true, true);
      };

      getContextMenuItems = ({ node }: GetContextMenuItemsParams) => {
        return agGridEntityContextMenu({
          onRemove: this.canChange ? () => this.confirmRemoving && this.confirmRemoving(node.data) : undefined,
        });
      };
      onRemoveConfirm = (e: DetailedOrderRowSnapshotType) => {
        this.setState({ confirmOpened: true, row: e });
      };

      onRemove = async (e: DetailedOrderRowSnapshotType) => {
        await this.props.store.sendToRemove(e.id);
        this.setState({ confirmOpened: false, row: null });
      };
      areStatusesEquals = (status1: OrderStatusDictionaryItemType, status2: OrderStatusDictionaryItemType) => {
        return status1.id === status2.id;
      };

      selectStatus = (status: OrderStatusDictionaryItemType) => {
        const { store } = this.props;
        store.setStatuses(status);
      };

      renderTag = (status: OrderStatusDictionaryItemType) => status.label;

      buildColumnsDefinition = () => {
        const { store, session } = this.props;

        const moneyVisible = can(func.FLOW_ORDERS_TABLE, session.access);
        const colsDef = columns(store.statuses, TABLE_NAME, moneyVisible);

        return colsDef;
      };

      onSearch = this.props.store.setQuery;

      visibleColsChange = (id: string, visibility: boolean) => {
        this.gridColumnApi &&
          this.props.store.columner.setColumnVisibility(this.gridColumnApi, this.cols, id, visibility);
      };

      cleanStatus = () => this.props.store.setStatus("");

      onRowSelect = (e: RowEvent) => {
        const { store } = this.props;

        store.selectRow(getRowNodeId(e.data));
      };

      onColMoved = (params: ColumnMovedEvent) => {
        const { store } = this.props;

        store.columner.saveColumnsOrder(params.columnApi.getColumnState());
        const columnsConf = store.columner.sizeColumns(
          store.columner.moveColumns(this.buildColumnsDefinition(), store.sorter)
        );
        this.cols = columnsConf;

        this.setState({ isColumnsMoved: true });
      };
      toggleConfirm = () => {
        this.setState({ confirmOpened: !this.state.confirmOpened });
      };
      goToOrder = (e: RowEvent) => {
        const { history, session } = this.props;
        const hasAccess = canReadOrder(session.access);

        this.onRowSelect(e);

        if (hasAccess) {
          const id = getRowNodeId(e.data);
          const url = routes.orders.details(id);

          history.push(url);
        }
      };

      onGridReady = (table: GridReadyEvent) => {
        const { store } = this.props;

        this.gridColumnApi = table.columnApi;
        this.tableGrid = table;

        store.columner.applyVisibility(table.columnApi, this.cols);

        if (!store.isEmpty && store.row) {
          const node = table.api.getRowNode(store.row);
          node && node.setSelected(true, true);
        }
        const canOrderIndicatorsRead = canAny(
          [func.ORDERS_TECHINDICATORS_READ, func.ORDERS_TECHINDICATORS_EDIT],
          this.props.session.access
        );

        store.load();
        if (canOrderIndicatorsRead) {
          store.loadIndicators();
        }
      };

      resetColumns = () => {
        const { store } = this.props;

        const colsDef = this.buildColumnsDefinition();
        const newState = store.columner.buildColumnsState(colsDef, store.sorter);

        this.gridColumnApi?.applyColumnState({
          state: newState,
          applyOrder: false,
        });

        this.onColMoved(this.tableGrid);
        store.columner.reset();
      };
    }
  )
);

const getRowNodeId = (row: DetailedOrderRowSnapshotType) => row.id;

interface TableProps {
  store: DetailedOrderListType;
  session: SessionType;
}

interface FilterIndicatorsProps {
  indicators: OrderIndicatorsListType;
  stateIndicators: FilterIndicatorsState[];
  addNewIndicator: () => void;
  onItemChange: (id: string, value: string, field: string) => void;
  onItemDelete: (id: string) => void;
}

interface FilterIndicatorsState {
  id: string;
  idIndicator: string;
  from: string;
  to: string;
}

const FilterIndicators = ({
  indicators,
  stateIndicators,
  addNewIndicator,
  onItemChange,
  onItemDelete,
}: FilterIndicatorsProps) => {
  const items = indicators.orderIndicators.map((i) => ({ id: i.id, label: `${i.label} (${i.description})` }));

  return (
    <div className={styles.indicatorsDiv}>
      <div className={styles.indicatorsHeader}>
        <div className={styles.indicatorsHeaderText}>Поиск по ТЭП</div>
        <PlanrButton type="secondary" size="small" round={true} icon="general-plus" onClick={addNewIndicator} />
      </div>
      <div className={styles.indicatorsBody}>
        {stateIndicators.length > 0 && (
          <div className={styles.indicatorRow}>
            <div className={styles.indicatorld}>Показатель</div>
            <div className={styles.indicatorFrom}>От</div>
            <div className={styles.indicatorTo}>До</div>
          </div>
        )}
        {stateIndicators.map((ind, index) => {
          return (
            <FilterIndicatorRow
              indicator={ind}
              key={index}
              items={items}
              onItemChange={onItemChange}
              onItemDelete={onItemDelete}
            />
          );
        })}
      </div>
    </div>
  );
};
interface FilterIndicatorRowProps {
  indicator: FilterIndicatorsState;
  items: { id: string; label: string }[];
  onItemChange: (id: string, value: string, field: string) => void;
  onItemDelete: (id: string) => void;
}
const FilterIndicatorRow = ({ indicator, items, onItemChange, onItemDelete }: FilterIndicatorRowProps) => {
  const [indicatorId, setindicatorId] = useState<string>(indicator.idIndicator);
  const [indicatorFrom, setindicatorFrom] = useState<string>(indicator.from);
  const [indicatorTo, setindicatorTo] = useState<string>(indicator.to);
  const selectedIndicator = items && items.find((i) => i.id === indicatorId);
  return (
    <div className={styles.indicatorRow}>
      <div className={styles.indicatorld}>
        {" "}
        <SimpleSelect
          className={`full-width-select ${Classes.FILL}`}
          activeItem={selectedIndicator}
          items={items}
          itemRenderer={renderSingleOption}
          onItemSelect={(i: SelectItem) => {
            setindicatorId(i.id);
            onItemChange(indicator.id, i.id, "indicatorId");
          }}
          filterable={true}
          itemPredicate={filterItemPredicate}
        >
          <DefaultSelectedOption option={selectedIndicator} />
        </SimpleSelect>
      </div>
      <div className={styles.indicatorFrom}>
        <InputGroup
          type="number"
          value={indicatorFrom}
          className="planr-default-input"
          autoComplete="off"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setindicatorFrom(e.currentTarget.value);
            onItemChange(indicator.id, e.currentTarget.value, "from");
          }}
        />
      </div>
      <div className={styles.indicatorTo}>
        {" "}
        <InputGroup
          type="number"
          value={indicatorTo}
          className="planr-default-input"
          autoComplete="off"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setindicatorTo(e.currentTarget.value);
            onItemChange(indicator.id, e.currentTarget.value, "to");
          }}
        />
      </div>

      <PlanrButton
        type={"neutral"}
        icon={"general-trash"}
        onClick={() => {
          onItemDelete(indicator.id);
        }}
      />
    </div>
  );
};
