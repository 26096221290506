import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { MainMenuWithRouter } from "modules/root/components/menu/MainMenu";
import { AppLogo } from "modules/root/components/logo/AppLogo";
import { Routes } from "modules/root/routes";
import { ApplicationStoreType } from "modules/root/models/store";
import { Notifications } from "./Notifications";
import { AuthPopup } from "modules/session/auth/components/AuthPopup";
import styles from "./App.module.scss";
import { observer } from "mobx-react";
import { User } from "./User";
import { OrderListWrapper } from "modules/orders-manage/list/OrderListWrapper";
import { RootStoreProvider } from "modules/root/models/hooks";
import { InvalidVersion } from "./InvalidVersion";
import { ReactComponent as GeneralIcons } from "modules/root/styles/planr/general.svg";
import { AppVersion } from "../version/AppVersion";
import StartPopup from "modules/root/components/StartPage/StartPopup";

const App: React.FC<AppProps> = (props) => {
  const { store, onLogout } = props;
  const [hideMenu, setHideMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  function handleResize() {
    if (window.innerWidth < 885) {
      setHideMenu(true);
      setIsMobile(true);
    } else {
      setHideMenu(false);
      setIsMobile(false);
    }
  }

  const useComponentWillMount = (cb: any) => {
    const willMount = useRef(true);

    if (willMount.current) cb();

    willMount.current = false;
  };

  useComponentWillMount(handleResize);
  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { loggedIn, authPopupVisible } = store.session;
  const versionMismatch = store.apiVersion && store.apiVersion !== store.uiVerson;
  const startInfoFilled = store.startInfoFilled;
  const resetState = (successfull: boolean) => {
    const { store } = props;
    store.setStartupInfoState(successfull);
  };

  const style: React.CSSProperties = {
    width: !hideMenu ? "308px" : "0px",
  };

  const logoStyle: React.CSSProperties = {
    width: !hideMenu ? "250px" : "0px",
  };

  const navStyle: React.CSSProperties = {
    width: !hideMenu ? "308px" : "0px",
  };

  const invalidVersionVisible = !authPopupVisible && versionMismatch;
  const startSettingsVisible = !authPopupVisible && !invalidVersionVisible && loggedIn && !startInfoFilled;

  const mainContentVisible = !authPopupVisible && !invalidVersionVisible && !startSettingsVisible;
  return (
    <RootStoreProvider value={store}>
      <GeneralIcons className="hidden" />
      <div className={styles.mainlayout}>
        {authPopupVisible && <AuthPopup store={store.session} />}
        {startSettingsVisible && (
          <>
            <StartPopup
              store={store.startSettings}
              onLogout={onLogout}
              access={store.session.access}
              onSaved={resetState}
              session={store.session}
            />
            <Notifications />
          </>
        )}
        {invalidVersionVisible && <InvalidVersion />}
        {mainContentVisible && (
          <BrowserRouter>
            {loggedIn && <User store={store.session} onLogout={onLogout} />}
            {loggedIn && (
              <div className={styles.nav_container} style={navStyle}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  name=""
                  id=""
                  onChange={() => {
                    setHideMenu(!hideMenu);
                  }}
                  checked={!hideMenu}
                />
                <div className={styles.hamburger_lines}>
                  <span className={`${styles.line} ${styles.line1}`}></span>
                  <span className={`${styles.line} ${styles.line2}`}></span>
                  <span className={`${styles.line} ${styles.line3}`}></span>
                </div>
                <div className={styles.mainLogo} style={logoStyle}>
                  <AppLogo />
                  <AppVersion version={store.apiVersion} changelog={store.changelog} prefix="1." />

                  <i className={`fa fa-pencil-square-o ${styles.preload}`} aria-hidden="true" />
                </div>
              </div>
            )}
            {loggedIn && (
              <div className={styles.leftColumn} style={style}>
                <MainMenuWithRouter
                  store={store}
                  hide={hideMenu}
                  setHideMenu={() => {
                    setHideMenu(!hideMenu);
                  }}
                  isMobile={isMobile}
                />
                <OrderListWrapper store={store.orders.list} access={store.session.access} />
              </div>
            )}
            <div className={styles.mainContainer}>
              <Notifications />
              <Switch>
                <Routes store={store} />
              </Switch>
            </div>
          </BrowserRouter>
        )}
      </div>
    </RootStoreProvider>
  );
};

export default observer(App);

export const ArrowEvents = {
  arrow: "rost.event.arrow",
};

interface AppState {
  hideMenu: boolean;
  width: number;
}

interface AppProps {
  store: ApplicationStoreType;
  onLogout: () => void;
}
