import React from "react";
import { am4core } from "../am";
import { SimpleChartProps, SimpleChart } from "../SimpleChart";
import { SimpleChartDatum } from "modules/main/models/simple-chart";
import { EngineersLoadingDatasetType, EngineerDatasetOrderItemType } from "modules/main/models/engineers-loading-chart";
import { OrdersList } from "./OrdersList";
import { prettyRound } from "modules/common/services/formatting/numbers";
import styles from "./EngineersChart.module.scss";
import { max } from "lodash";
import { LocalLoader } from "modules/common/components/loader/Loader";

export class EngineersChart extends SimpleChart<EngineersChartProps, EngineersChartState> {
    colorSet = new am4core.ColorSet();
    baseRender: (child?: any) => JSX.Element;

    constructor(props: any) {
        super(props);
        this.state = { orders: null, data: [], maximum: 0 };

        this.baseRender = this.renderContainer;
        this.renderContainer = (child?: any) => {
            const { data } = this.state;

            // reversre чтобы получать цвета в нужном порядке
            const length = data.length - 1;
            const stripes = [...data]
                .reverse()
                .map((e, index) => this.stripe(e, length - index))
                .reverse();
            if (!this.props.store.loaded && this.props.store.loading) {
                return <LocalLoader active={true} />;
            }
            return this.baseRender(
                <div className={`${styles.employee} engineersStripes`} style={{ height: `${this.props.height}px` }}>
                    {stripes}
                </div>
            );
        };
    }

    renderChilds = () => {
        this.colorSet.reset();
        const { orders } = this.state;
        const modal = orders ? (
            <OrdersList orders={orders} title={`Все договоры (${orders.length} шт)`} onClose={this.closeList} />
        ) : null;

        return modal;
    };

    setupChart = (data: SimpleChartDatum[]) => {
        const maximum = max(data.map((d) => d.data)) ?? 0;
        this.setState({ data, maximum });
    };

    closeList = () => this.setState({ orders: null });

    openList = (orders: EngineerDatasetOrderItemType[]) => this.setState({ orders });

    stripe = (datum: SimpleChartDatum, index: number) => {
        const maximum = this.state.maximum;

        const persents = maximum > 0 ? prettyRound((datum.data / maximum) * 100) : 0;

        return (
            <div className={styles.stripe} key={datum.period} data-index={index} onClick={this.onRowClick}>
                <div className={styles.name}>
                    <div className={styles.nameValue}>{datum.period}</div>
                    <div className={styles.value}>{datum.data}</div>
                </div>
                <div className={styles.percents}>
                    <div style={{ width: `${persents}%`, backgroundColor: this.colorSet.next().hex }}></div>
                </div>
            </div>
        );
    };

    onRowClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const index = +(e.currentTarget.dataset.index ?? "");

        const orders = this.props.store.orders[index] || null;
        this.openList(orders);
    };
}

interface EngineersChartProps extends Omit<SimpleChartProps, "store"> {
    store: EngineersLoadingDatasetType;
}

interface EngineersChartState {
    orders: EngineerDatasetOrderItemType[] | null;
    data: SimpleChartDatum[];
    maximum: number;
}
