import React from "react";
import { Formik, Form } from "formik";
import { SessionType } from "../models/session";
import { InputGroup, Classes } from "@blueprintjs/core";
import AuthSchema from "./validation";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Buttons } from "modules/common/components/form";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const schema = AuthSchema();

export const AuthForm = ({ store }: AuthFormProps) => {
    const prefilled = !!store.credentials.login;
    const ignoreManager = prefilled
        ? {
              autoComplete: "off",
              "data-lpignore": "true",
          }
        : {};

    return (
        <Formik
            initialValues={getFormValues(store)}
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={store.logIn}
        >
            {() => {
                return (
                    <Form>
                        <div className={Classes.DIALOG_BODY}>
                            <StandardFormInput name="login" schema={schema} small={true} className="planr-form-input">
                                {({ field }) => (
                                    <InputGroup
                                        id="login"
                                        {...field}
                                        className="planr-default-input"
                                        {...ignoreManager}
                                    />
                                )}
                            </StandardFormInput>

                            <StandardFormInput
                                name="password"
                                schema={schema}
                                small={true}
                                className="planr-form-input"
                            >
                                {({ field }) => (
                                    <InputGroup
                                        id="password"
                                        {...field}
                                        className="planr-default-input"
                                        type="password"
                                        {...ignoreManager}
                                    />
                                )}
                            </StandardFormInput>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <Buttons>
                                <PlanrButton
                                    type="blueish"
                                    nativeType="submit"
                                    icon="general-sign-in"
                                    size="small"
                                    style={{ marginLeft: "80px", width: "170px" }}
                                >
                                    Вход
                                </PlanrButton>
                            </Buttons>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export interface AuthFormProps {
    store: SessionType;
}

function getFormValues(store: SessionType) {
    return {
        login: store.credentials.login,
        password: store.credentials.password,
    };
}
